<template>
	<!--	<div v-if="label" class="col-12 align-items-center text-center">
        {{ label }}
    </div>-->
	<div class="col-12 ">
		<div class="each-validation-wrapper col-12" :class="modelValue?.validation">
			<div v-if="label" class="col-12 each-validation-title">
				<h5 class="fw-bold ">
					{{ label }}
					<template v-if="modelValue?.validation !== null">
						<div class="badge badge-pill each-validation-wrapper-badge">
							{{ modelValue?.validation }}
						</div>
					</template>
				</h5>
			</div>

			<span v-if="errorToShow" class="text-danger font-weight-500 d-block pt-4">
				<i class="text-danger mdi mdi-alert-circle-outline" />
				{{ errorToShow }}
			</span>
			<label class="col-12" @click="$refs.pictureModalInput.click()">
				<div class="col-12 ">
					<div v-if="secondImage" class="row">
						<div class="col-12 col-md-6 d-flex  align-items-center justify-content-center  ">
							<div class="validate-image-backoffice-box col-12">
								<a :href="getImageToShow" target="_blank">
									<div class="position-relative d-flex justify-content-center pt-2 pb-2">
										<div class="col-auto">

											<VueMagnifier
												alt="Imagem"
												:src="getImageToShow"
												class=" profile-image"
												style="width:auto; max-width: 500px; height: 278px;"
												@error="onImgError"
											/>
										</div>
									</div>
								</a>
							</div>
						</div>
						<div class="col-12 col-md-6 d-flex  align-items-center justify-content-center  ">
							<div class="validate-image-backoffice-box col-12">
								<a :href="getSecondImageToShow" target="_blank">
									<div class="position-relative d-flex justify-content-center pt-2 pb-2">
										<div class="col-auto">
											<VueMagnifier
												alt="Imagem"
												:src="getSecondImageToShow"
												class=" profile-image"
												style="width: auto; max-width: 500px; height: 278px;"
												@error="onImgError"
											/>

										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
					<div v-else class="row">
						<div class="col-12 col-md-7 d-flex  align-items-center justify-content-center  ">
							<div class="validate-image-backoffice-box col-12">
								<a :href="getImageToShow" target="_blank">
									<div class="position-relative d-flex justify-content-center pt-2 pb-2">
										<div class="col-auto">
											<VueMagnifier
												alt="Imagem"
												:src="getImageToShow"
												class=" profile-image"
												style="width: auto; height: 278px; max-width: 500px;"
												@error="onImgError"
											/>
										</div>
									</div>
								</a>
							</div>
						</div>
						<div
							class="col-12 col-md-5 d-flex align-items-center justify-content-center validate-image-backoffice-box"
						>
							<div class="col-12  text-center">
								<div class="row">
									<div class="col-12">
										<div class="col-12 label-for-data">
											{{ labelTwo }}
										</div>
										<div class="col-12 data-with-label">
											{{ matchingValue }}
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</label>
			<div class="col-12 pt-2">
				<div class="row justify-content-end">
					<div class="col-auto">
						<label class="input-radio">
							<input
								v-model="fakeValitation"
								type="radio"
								value="Validado"
								@change="validateImage('Validado')"
							> Validar
						</label>
						<label class="input-radio">
							<input
								v-model="fakeValitation"
								type="radio"
								value="Recusado"
								@change="validateImage('Recusado')"
							> Recusar
						</label>
					</div>
				</div>
			</div>
			<!--                       -->
		</div>
	</div>
</template>

<script>
import VueMagnifier from '@websitebeaver/vue-magnifier'
import '@websitebeaver/vue-magnifier/styles.css'
export default {
    name: "InsertImage",
    components: {
        VueMagnifier,
    },
    props: {
        modelValue: undefined,
        /*
        Quando estamos a usar esta data estamos a usar 2 imagens no bloco de validação
        Exemplo:o Doc de indentificação em que mostramos o frente e o verso
        */
        secondImage: undefined,

        matchingValue: {
            type: String,
            required: false,
            default: null,
        },
        labelTwo: {
            type: String,
            required: false,
            default: null,
        },
        alreadyValidated: {
            type: String,
            required: false,
            default: null,
        },
        fileName: {
            type: String,
            required: false,
            default: null,
        },
        validation: {
            type: String,
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        defaultIcon: {
            type: String,
            required: false,
            default: null,
        },

        secondDefaultIcon: {
            type: String,
            required: false,
            default: null,
        },

        topLine: {
            type: String,
            required: false,
            default: null,
        },
        showTopLine: {
            type: Boolean,
            default: true,
            required: false,
        },
        bottomLineLabel: {
            type: String,
            required: false,
            default: null,
        },
        bottomLineValue: {
            type: String,
            required: false,
            default: null,
        },
        showBottomLine: {
            type: Boolean,
            default: true,
            required: false,
        },
        fileOnError: {
            type: String,
            default: 'images/no-image.png',
            required: false,
        },
    },

    emits: ['imageUploaded', 'update:modelValue'],

    data() {
        return {
            fakeValitation: this.modelValue?.validation,
            errorToShow: null,
        }

    },

    computed: {
        getImageToShow() {
            if (this.modelValue?.imageChanged) {
                return URL.createObjectURL(this.modelValue.image);
            } else if (this.modelValue?.image) {

                if (this.modelValue.image.slice(-4).toLowerCase() === '.pdf') {
                    // If the last four letters are '.pdf', return the PDF image
                    return this.asset('images/card-pdf.jpg');
                } else {
                    // Otherwise, return the original image URL
                    return this.modelValue.image;
                }
            }

            if (this.defaultIcon) {
                return this.asset(this.defaultIcon);
            }

            return this.asset('images/userPlaceholder.jpg');
        },
        getSecondImageToShow() {
            if (this.secondImage?.imageChanged) {
                return URL.createObjectURL(this.secondImage.image);
            } else if (this.secondImage?.image) {

                if (this.secondImage.image.slice(-4).toLowerCase() === '.pdf') {
                    // If the last four letters are '.pdf', return the PDF image
                    return this.asset('images/card-pdf.jpg');
                } else {
                    // Otherwise, return the original image URL
                    return this.secondImage.image;
                }
            }

            if (this.secondDefaultIcon) {
                return this.asset(this.secondDefaultIcon);
            }

            if (this.defaultIcon) {
                return this.asset(this.defaultIcon);
            }

            return this.asset('images/userPlaceholder.jpg');
        }
    },

    methods: {
        validateImage(answer) {
            /*const blob = new Blob([file], { type: file.type });*/
            this.$emit('update:modelValue', {
                'image': this.modelValue?.image,
                'validation': answer,
            })
        },
        onImgError(ev) {
            ev.target.src = this.asset(this.fileOnError);
        }

    }
}
</script>

<style scoped>
.profilePicEditButton {
    position: absolute;
    bottom: 5px;
    right: 0;
    font-size: 18px;
    background-color: #333;
    height: 30px;
    width: 30px;
    text-align: center;
    border-radius: 50%;
    color: white;
}

.profile-image {
    object-fit: cover;
    object-position: center;
    transition: filter 0.3s;
    max-width: 100%;
}

.profile-image:hover {
    cursor: pointer;
    filter: brightness(0.8);
}
</style>
