<template>
	<div
		class="col-12  blank"

		style="height: 100%; overflow-x: hidden"
	>
		<div class="row ">
			<div class="main-container">
				<div class="container">
					<div class="col-12 default-bland-back">
						<div class="row align-items-center justify-content-center min-vh-100">
							<div style=" max-width: 100%" class="col-auto col-md-12">
								<div class="">
									<div class=" col-12  d-flex pb-2  justify-content-end">
										<div class="col-12 align-content-center justify-content-center d-flex pb-3">
											<img
												src="images/logo-black.svg"
												width="101"
												class="logo-class-logged"
												alt="logo"
											>
										</div>
									</div>
								</div>
								<div class="col-12   br-10 rounded-2">
									<slot />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Modal from "../Components/Modal.vue";

export default {
    name: "BlankLayout",


}
</script>

<style scoped>
.main-container {
    background-color: #f5f5f5;
}

.myNavbar {
    grid-column: 1;
    grid-row: 2;
    padding: 20px;
    overflow: hidden;


}

@media only screen and (min-width: 992px) {
    .main-container.main-containerOpen {
        padding-left: 300px;
    }
}

.myNavbar.contestentNavbarOpen {
    position: absolute;
    min-height: calc(100vh - 60px);
    min-width: 300px;
    z-index: 999;
    /* background-color: #00a65a;*/
}

.myNavbar .eachLink {
    display: block;
    padding: 10px;
    transition: all 0.2s;
    border-radius: 10px;
    font-weight: 600;
}

.myNavbar .eachLink .eachLinkInside {
    display: flex;
    align-items: center;
}

.myNavbar .eachLink .eachLinkInside .mdi {
    line-height: 0;
    font-size: 1.3rem;
    margin-right: 10px;
}

</style>
