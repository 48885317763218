<template>
	<div>
		<form @submit.prevent="submit">
			<div class="d-flex flex-column w-100 gap-4">
				<div class="col-12">
					<div class="row">
						<div class="col-12 col-md-6 col-lg-4 mb-3">
							<DefaultInput
								v-model="form.name"
								label="Nome"
								name="name"
								:error="pErrors.name"
								custom-input-class="my-input-form"
							/>
						</div>
						<div class="col-12 col-md-6 col-lg-4 mb-3">
							<DefaultInput
								v-model="form.description"
								label="Descrição para impressão"
								name="description"
								:error="pErrors.description"
								custom-input-class="my-input-form"
							/>
						</div>

						<div class="col-12 col-md-6 col-lg-4 mb-3">
							<div class="form-group">
								<label class="form-label">Regra</label>
								<multiselect
									v-model="form.rule"
									:options="$page.props.rules"
								/>
							</div>
						</div>

						<div class="col-12 col-md-6 mb-3">
							<DefaultInput
								v-model="form.start_date"
								label="Data inicial"
								name="start_date"
								type="date"
								:error="pErrors.start_date"
								custom-input-class="my-input-form"
							/>
						</div>

						<div class="col-12 col-md-6 mb-3">
							<DefaultInput
								v-model="form.end_date"
								label="Data final"
								name="end_date"
								type="date"
								:error="pErrors.end_date"
								custom-input-class="my-input-form"
							/>
						</div>

						<div class="col-12 col-md-6 col-lg-4 mb-3">
							<DefaultInput
								v-model="form.possible_winners"
								label="Número vencedores"
								name="possible_winners"
								type="number"
								:error="pErrors.possible_winners"
								custom-input-class="my-input-form"
							/>
						</div>

						<div class="col-12 col-md-6 col-lg-4 mb-3">
							<DefaultInput
								v-model="form.possible_replacements"
								label="Número substitutos"
								name="possible_replacements"
								type="number"
								:error="pErrors.possible_replacements"
								custom-input-class="my-input-form"
							/>
						</div>

						<div class="col-12 col-md-6 col-lg-4 mb-3">
							<DefaultInput
								v-model="form.is_active"
								label="Ativo"
								name="is_active"
								type="checkbox"
								:error="pErrors.is_active"
								custom-input-class="my-input-form"
							/>
						</div>
						<div class="col-12 col-md-6 col-lg-4 mb-3">
							<DefaultInput
								v-model="form.limit_date"
								label="Data limite de envio"
								type="date"
								custom-input-class="my-input-form"
								placeholder="Clique para selecionar a data limite de envio"
							/>
						</div>
					</div>
				</div>
				<div class="d-flex justify-content-center mt-3">
					<Link
						:href="defaultForm?.id ? route('admin.contest.show', defaultForm.id) : route('admin.contest.index')"
						class="btn btn-light me-3"
					>
						Cancelar
					</Link>
					<button
						ref="submitButton"
						class="btn btn-primary"
						type="submit"
					>
						Submeter
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import DefaultInput from "../../Components/DefaultInput.vue";
import {useForm} from "@inertiajs/vue3";
import Multiselect from "vue-multiselect";

export default {
    name: "ContestForm",
    components: {
        Multiselect,
        DefaultInput,
    },

    props: {
        defaultForm: {
            type: Object,
            required: true,
            default: () => ({
                name: null,
                description: null,
                rule: null,
                start_date: null,
                end_date: null,
                is_active: null,
                possible_winners: null,
                possible_replacements: null,
                limit_date: null,
                status: null,
            })
        },
        submitRoute: {
            type: String,
            required: true,
        },
        errors: {
            type: Object,
            required: false,
            default: undefined
        },
        type: {
            type: String,
            required: true,
            default: 'create'
        }
    },
    data() {
        return {
            form: useForm({
                ...{
                    name: null,
                    description: null,
                    rule: null,
                    start_date: null,
                    end_date: null,
                    is_active: null,
                    possible_winners: null,
                    possible_replacements: null,
                    limit_date: null,
                    status: null,
                }, ...this.defaultForm
            }),
            permissions: [],
            locales: [
                {name: 'Português', id: 'pt'},
                {name: 'Inglês', id: 'en'},
            ],
            pErrors: {}
        }
    },

    methods: {
        submit() {
            if (this.form.locale) {
                localStorage.setItem('locale', this.form.locale.id)
                localStorage.setItem('full-locale', JSON.stringify(this.form.locale))
            }
            this.form.post(this.submitRoute, {
                preserveState: true,
                onSuccess: () => {
                    //window.location.reload()
                    this.$refs.submitButton?.toggleLoading(false)
                },
                onError: (e) => {
                    this.pErrors = e
                    this.$refs.submitButton?.toggleLoading(false)
                },
                onFinish: () => {
                    this.$refs.submitButton?.toggleLoading(false)
                }
            })
        },
    }
}
</script>

<style>
.form-label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
}

.btn-light {
    font-size: 1.2375rem !important;
    line-height: 2.78438rem !important;
}
</style>
