<template>
    <div class="container">
        <div class="row">
            <div
                class=" col-12  d-flex pb-2  justify-content-end">


            </div>
        </div>
        <div class="col-12  p-1 py-md-4 px-md-4 ">
            <div class="row">
                <div class="col-12 pt-4">
                    <h4 class="fw-bold text-center">
                        Home temporário
                    </h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FakeModalLayout from "../Shared/FakeModalLayout.vue";
import axios from "axios";

import {ref} from "vue";
export default {
    name: "ParticipationStatusFiles",

    layout: FakeModalLayout,

    components: {

    },

    props: {

    },
    methods: {
        printPage() {
            window.print();
        },
        // ... (other methods)
    },
}
</script>
<script setup>


//define layout
</script>
<style>


</style>
