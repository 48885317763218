<template>
	<form @submit.prevent="submit">
		<modal

			:model-value="isOpen"
			title="Importar"
			@update:model-value="isOpen = $event"
			@save="submit"
		>
			<div class="row mb-3">
				<div class="col-12">
					<p class="mb-1">
						Ficheiro de exemplo
					</p>
					<a
						class="btn btn-primary"
						href="../../images/exemplo_importacao_participacoes.csv"
						target="_blank"
					>Download</a>
				</div>
			</div>
			<div class="row mb-3">
				<span class="text-danger font-16 font-weight-bold">Atenção!</span><br>
				<span>Não altere os nomes das colunas</span>
			</div>
			<hr>
			<div class="row">
				<div class="col-xs-12">
					<FreeInput
						label="Sorteio"
					>
						<SearchMultiselect
							v-model="sorteio"
							label="contest_type"
							track="id"
							:url="route('professionalparticipations.options')"
						/>
					</FreeInput>
				</div>
				<div class="col-12">
					<div class="mb-3">
						<label for="file" class="form-label">Ficheiro</label>
						<input
							id="file"
							type="file"
							class="form-control"
							accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
							@change="fileChanged"
						>
						<span v-if="fileError" class="text-danger font-12">{{ fileError }}</span>
					</div>
				</div>
			</div>
		</modal>
	</form>
</template>

<script>
import Modal from "./Modal.vue";
import FreeInput from "@/Components/FreeInput.vue";
import SearchMultiselect from "@/Components/SearchMultiselect.vue";

export default {
    name: "UploadFileModal",
    components: {SearchMultiselect, FreeInput, Modal},
    props: {
        isOpen: Boolean,
        sorteios: {
            type: Object,
            default: () => []
        },
    },
    data() {
        return {
            sorteio:null,
            file: null,
            fileError: null,
        }
    },
    methods: {
        submit() {
            this.fileError = null;

            if(! this.file){
                this.fileError = 'Ficheiro obrigatório';
                return;
            }

            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('sorteio', this.sorteio.id);

            axios.post(route('professionalparticipations.import'), formData).then(response => {
                window.open(response.data, '_blank');
            }).catch(error => {
                console.log(error);
            });
        },
        fileChanged(e) {
            this.file = e.target.files[0];
        }
    }

}
</script>

<style scoped>

</style>
