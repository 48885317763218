<template>
	<div
		class="col-12  contestentLayout"
		:class="{'mainContainerOpen': navOpen}"
		style="height: 100%; overflow-x: hidden"
	>
		<div class="default-background-prio-overlay2">
			<div class="topbar col-12">
				<div class="container">
					<div class="col-12 d-flex align-items-center justify-content-between h-100 ">
						<!--                <div style="padding-left: 10px;">
                                        <i
                                            class="cursor-pointer mdi mdi-menu"
                                            style="font-size: 30px;"
                                            @click="toggleNavOpen"
                                        />
                                    </div>-->
						<div class="col-auto p-2">
							<!--                        <a :href="route('home-temporario')">-->
							<a href="https://ganhaecodiesel.pt/">

								<img
									:src="asset('images/logo-blue.png')"
									width="101"
									class="logo-class-logged"
									alt="logo"
								>
							</a>
						</div>
						<div class="">
							<div class="d-flex align-items-center justify-content-end gap-4 px-2">
								<div class="position-relative" @click="notificationsModalOpen = true">
									<span class="mdi mdi-bell-outline" style="font-size: 1.3rem;" />
									<span
										v-if="notifications.length > 0"
										style="left: 0.6rem"
										class="badge bg-danger rounded-pill position-absolute"
									>{{ notifications.length }}</span>
								</div>

								<div @click="logout">
									<i class="mdi mdi-logout" />
								</div>
							</div>
							<div class="col-12 header-greeting px-2">
								Olá, {{ onlyShowFirstName($page.props.auth.name) }}
							</div>
						</div>
					</div>
					<div
						class=" col-12 d-flex align-items-center justify-content-between justify-content-md-start px-0"
					>
						<!--:class="{'mt-2': index != 0, 'active': $page.url.startsWith('/' + link.name)}"-->
						<template v-for="(link, index) in links">
							<Link
								v-if="link.show"
								:key="index"
								as="button"
								:href="link.url"
								class="eachLink"
								:disabled="link.disabled"
								:class="{ 'active': $page.url.startsWith('/' + link.name)}"
							>
								<span class="eachLinkInside p-2">
									<span>{{ link.label }}</span>
								</span>
							</Link>
						</template>
					</div>
				</div>
			</div>
			<div class="row ">
				<!--            <div class="myNavbar contestentNavbarOpen col-2"
                             v-if="navOpen"
                        >

                        </div>-->
				<!--            :class="{'main-containerOpen': navOpen}"-->
				<div class="main-container">
					<div v-if="$page.props.auth && !$page.props.auth.phone">
						<div class="text-auto-scroll-wrap">
							<p class="text-auto-scroll fw-bold">
								Alerta! Guarda a fatura e o talão com o código (são estes que te habilitam à entrega do
								prémio) - Adiciona o teu telefone para te notificarmos por SMS.
							</p>
						</div>
					</div>
					<div v-else>
						<div class="text-auto-scroll-wrap">
							<p class="text-auto-scroll fw-bold">
								Alerta! Guardar a fatura e talão com código (são estes que habilitam à entrega do
								prémio)
							</p>
						</div>
					</div>

					<div class="container">
						<div class="row  justify-content-center min-vh-100">
							<div style=" max-width: 100%" class="col-12 col-md-12">
								<div class="col-12 px-0 pb-4 pt-0 text-center">
									<div class="banner-title">
										Ganha uma <br> volta ao mundo
										<span class="col-12 d-flex align-items-center justify-content-center">
											em ECO Diesel.
										</span>
									</div>
								</div>

								<div class="col-12   br-10 rounded-2">
									<slot />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 px-0 login-footer text-center mt-5">
			<div class="section-wrapper section-wrapper-footer">
				<div class="container-wrapper py-5">
					<div class="container ">
						<div class="row align-content-center justify-content-center d-md-none">
							<div
								class="col-12 col-md-4 d-flex pb-4 section-footer align-content-center justify-content-center"
							>
								<a href="https://ganhaecodiesel.pt/">
									<img
										:src="asset('images/logo.svg')"
										width="101"
										class="logo-class-logged"
										alt="logo"
									>
								</a>
							</div>
							<div
								class="col-12 align-content-center section-socials-footer justify-content-center d-flex pb-3"
							>
								<a href="https://ecodiesel.pt/" class="col-auto">
									ecodiesel.pt
								</a>
							</div>
							<div
								class="col-12  d-flex section-footer section-socials-footer align-content-center justify-content-center"
							>
								<div class="row">
									<a
										href="https://www.facebook.com/PrioEnergy"
										target="_blank"
										class="col-auto"
									>
										<img
											:src="asset('images/facebook.svg')"
											width="24"
											alt="facebook"
										>
									</a>
									<a
										href="https://www.instagram.com/prioenergy/"
										target="_blank"
										class="col-auto"
									>
										<img
											:src="asset('images/instagram.svg')"
											width="24"
											alt="instagram"
										>
									</a>
									<a
										href="https://www.linkedin.com/company/prio-sgps"
										target="_blank"
										class="col-auto"
									>
										<img
											:src="asset('images/linkedin.svg')"
											width="24"
											alt="linkedin"
										>
									</a>
									<a
										href="https://www.youtube.com/@PRIOPT"
										target="_blank"
										class="col-auto"
									>
										<img
											:src="asset('images/youtube.svg')"
											width="24"
											alt="youtube"
										>
									</a>
									<a
										href="https://www.tiktok.com/@prio.pt"
										target="_blank"
										class="col-auto"
									>
										<img
											:src="asset('images/tiktok.svg')"
											width="20"
											alt="tiktok"
										>
									</a>
								</div>
							</div>
							<div
								class="col-12 col-md-4 d-flex section-footer section-text-footer align-content-center justify-content-center pt-4 pb-4 me-auto"
							>
								Concurso publicitário nº 308/2023 autorizado pela Câmara Municipal de Ílhavo.
							</div>
							<div
								class="col-12 col-md-4 d-flex section-footer section-links-footer a align-content-center justify-content-center"
							>
								<div class="row">
									<div class="col-12">
										<a :href="route('regulamento')" class="col-12">
											Regulamento
										</a>
									</div>
									<div class="col-12">
										<a :href="route('politica-privacidade')" class="col-12">
											Politica de Privacidade
										</a>
									</div>
									<div class="col-12" style="height:50px;">
										<a :href="route('politica-cookies')" class="col-12">
											Politica de Cookies
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="row align-content-center justify-content-center d-md-flex d-none">
							<div
								class="col-12 col-md-4 d-flex section-footer section-text-footer align-content-center justify-content-center pb-4 pt-md-5"
							>
								Concurso publicitário nº 308/2023 autorizado pela Câmara Municipal de Ílhavo.
							</div>
							<div
								class="col-12 col-md-4 d-flex pb-4 section-footer align-content-center justify-content-center"
							>
								<div class="row">
									<div class="col-12 align-content-center justify-content-center d-flex pb-3">
										<img
											:src="asset('images/logo.svg')"
											width="101"
											class="logo-class-logged"
											alt="logo"
										>
									</div>
									<div
										class="col-12 align-content-center section-socials-footer justify-content-center d-flex pb-3"
									>
										<a href="https://ecodiesel.pt/" class="col-auto">
											ecodiesel.pt
										</a>
									</div>
									<div
										class="col-12  d-flex section-footer section-socials-footer align-content-center justify-content-center"
									>
										<div class="row">
											<a
												href="https://www.facebook.com/PrioEnergy"
												target="_blank"
												class="col-auto"
											>
												<img
													:src="asset('images/facebook.svg')"
													width="24"
													alt="facebook"
												>
											</a>
											<a
												href="https://www.instagram.com/prioenergy/"
												target="_blank"
												class="col-auto"
											>
												<img
													:src="asset('images/instagram.svg')"
													width="24"
													alt="instagram"
												>
											</a>
											<a
												href="https://www.linkedin.com/company/prio-sgps"
												target="_blank"
												class="col-auto"
											>
												<img
													:src="asset('images/linkedin.svg')"
													width="24"
													alt="linkedin"
												>
											</a>
											<a
												href="https://www.youtube.com/@PRIOPT"
												target="_blank"
												class="col-auto"
											>
												<img
													:src="asset('images/youtube.svg')"
													width="24"
													alt="youtube"
												>
											</a>
											<a
												href="https://www.tiktok.com/@prio.pt"
												target="_blank"
												class="col-auto"
											>
												<img
													:src="asset('images/tiktok.svg')"
													width="20"
													alt="tiktok"
												>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div
								class="col-12 col-md-4 d-flex section-footer section-links-footer align-content-center justify-content-center"
							>
								<div class="row align-content-center">
									<div class="col-12" style="height:50px;">
										<a :href="route('regulamento')" class="col-12">
											Regulamento
										</a>
									</div>
									<div class="col-12" style="height:50px;">
										<a :href="route('politica-privacidade')" class="col-12">
											Politica de Privacidade
										</a>
									</div>
									<div class="col-12" style="height:50px;">
										<a :href="route('politica-cookies')" class="col-12">
											Politica de Cookies
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<modal
		:show="notificationsModalOpen"
		title="Notificações"
		:with-save="false"
		:show-close-button="false"
		@close="notificationsModalOpen = false"
	>
		<div class="overflow-hidden">
			<div
				v-for="notification in notifications"
				:key="'notification' + notification.id"
				:ref="'notification' + notification.id"
				class="card card-body shadow-sm border mb-4 v-slide-fade-out"
			>
				<div class="d-flex justify-content-between gap-3 ">
					<div>
						<i
							v-if="notification.data.icon"
							class="me-1"
							:class="[notification.data.icon, notification.data.icon_color]"
							style="font-size: 20px"
						/>
						<span style="font-size: 20px">
							<b>{{ notification.data.title }}</b>
						</span>
					</div>
					<i
						class="mdi mdi-trash-can-outline text-danger"
						style="font-size: 20px"
						@click="markAsRead(notification.id)"
					/>
				</div>
				<span>
					{{ notification.data.message }}
				</span>
			</div>
			<div v-if="notifications.length === 0" class="d-flex justify-content-center">
				<h3>Sem notificações!</h3>
			</div>
		</div>
	</modal>
</template>

<script>
import Modal from "../Components/Modal.vue";

export default {
    name: "ContestentsLayout",
    components: {Modal},
    data() {
        return {
            navOpen: true,
            notifications: [],
            notificationsModalOpen: false,
            links: [],
        }
    },
    watch: {
        '$page.props.toast_message': function (newVal) {

            if (newVal) {
                //With every request I send a _timestamp, so I know when to show the toast even if the message didn't change
                const toast_message = newVal.substring(0, newVal.lastIndexOf('_'))
                switch (this.$page.props.toast_type) {
                    case 'success':
                        toast.success(toast_message)
                        break;
                    case 'error':
                        toast.error(toast_message)
                        break;
                    case 'warning':
                        toast.warning(toast_message)
                        break;
                    default:
                        toast.info(toast_message)
                        break;
                }
            }
        },
        '$page.props.errors': function (newVal) {
            if (newVal) {
                try {
                    for (const [key, value] of Object.entries(newVal)) {
                        this.$refs[key].classList?.add('is-invalid')
                        toast.error(value)
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        },
    },
    beforeMount() {
        this.navOpen = localStorage.getItem('navOpen') === 'true';

        this.links = [
            {
                show: true,
                label: 'Participar',
                name: 'concursos/criar-participacao',
                url: this.route('contests.createParticipation'),
                icon: '<span class="mdi mdi-abacus"/>',
                disabled: this.$page.props.auth.name === '',
            },
            {
                show: true,
                label: 'Participações',
                name: 'concursos/minhas-participacoes',
                url: this.route('contests.myParticipations'),
                icon: '<span class="mdi mdi-abacus"/>',
                disabled: this.$page.props.auth.name === '',
            },
            {
                show: true,
                label: 'Sorteios',
                name: 'sorteios',
                url: this.route('codigosSorteadosIndex'),
                icon: '<span class="mdi mdi-abacus"/>',
                disabled: this.$page.props.auth.name === '',
            },
            {
                show: true,
                label: 'Conta',
                name: 'concursos/o-meu-perfil',
                url: this.route('contestent.perfil'),
                icon: '<span class="mdi mdi-abacus"/>',
                disabled: this.$page.props.auth.name === '',
            },
        ]
    },
    mounted() {

        if (this.$page.props.toast_message) {
            const toast_message = this.$page.props.toast_message.substring(0, this.$page.props.toast_message.lastIndexOf('_'));
            this.displayMessage(toast_message, this.$page.props.toast_type);
        }
        axios.get(route('user.notifications'))
            .then(({data}) => {
                if (data && Array.isArray(data)) {
                    this.notifications = data
                }
            })
            .catch(error => {
                console.log(error)
            })
    },

    methods: {
        onlyShowFirstName(fullName) {
            // This method extracts the first name from the full name
            // You can modify this method based on your actual data structure
            if (fullName) {
                const firstName = fullName.split(' ')[0];
                return firstName;
            } else {
                return 'concorrente';
            }
        },
        toggleNavOpen() {
            this.navOpen = !this.navOpen
            localStorage.setItem('navOpen', this.navOpen)
        },

        logout() {
            this.$inertia.post(route('logout'))
            setTimeout(() => {
                window.location.reload()
            }, 500)
        },

        markAsRead(notification) {
            axios.post(route('user.notifications.read', notification))
                .then(response => {
                    this.$refs['notification' + notification][0].classList.add('hide')

                    setTimeout(() => {
                        this.notifications = this.notifications.filter(item => item.id !== notification)
                    }, 150)
                })
                .catch(error => {
                    console.log(error)
                })
        },
    }
}
</script>

<style scoped>
.main-container {
    background-color: #f5f5f5;
}

.myNavbar {
    grid-column: 1;
    grid-row: 2;
    padding: 20px;
    overflow: hidden;


}

@media only screen and (min-width: 992px) {
    .main-container.main-containerOpen {
        padding-left: 300px;
    }
}

.myNavbar.contestentNavbarOpen {
    position: absolute;
    min-height: calc(100vh - 60px);
    min-width: 300px;
    z-index: 999;

}

.myNavbar .eachLink {
    display: block;
    padding: 10px;
    transition: all 0.2s;
    border-radius: 10px;
    font-weight: 600;
}

.myNavbar .eachLink .eachLinkInside {
    display: flex;
    align-items: center;
}

.myNavbar .eachLink .eachLinkInside .mdi {
    line-height: 0;
    font-size: 1.3rem;
    margin-right: 10px;
}

</style>
