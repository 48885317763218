<template>
    <PageTitle title="Documentos"/>

    <DefaultDataTable
        :columns="columns"
        :url="route('documents.search')"
    />
</template>

<script>
import Layout from "../../Shared/Layout.vue";

export default {
    layout: Layout,
    methods: {

    }
}
</script>
<script setup>
import DefaultDataTable from "../../Components/DefaultDataTable.vue";
import PageTitle from "../../Components/etc/PageTitle.vue";

const columns = [
    {data: 'ID', dataTitle: 'ID', name: 'id', searchable: true, orderable: true},
    {data: 'Número', dataTitle: 'Número', name: 'number', searchable: true, orderable: true},
    {data: 'Total', dataTitle: 'Total', name: 'total', searchable: true, orderable: true},
]
//define layout
</script>

<style scoped>

</style>
