<template>
	<PageTitle title="Editar sorteio" />

	<ContestForm
		:default-form="defaultForm"
		:submit-route="route('admin.contest.update', defaultForm.id)"
		:errors="errors"
		type="edit"
	/>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import ContestForm from "./Form.vue"
import PageTitle from "../../Components/etc/PageTitle.vue";
export default {
    name: "Edit",
    components: {
        PageTitle,
        ContestForm,
    },
    layout: Layout,
    props: {
        defaultForm: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
}
</script>

<style scoped>

</style>
