<template>
	<meta-head title="Dashboard" />
	<span style="font-weight: 700; font-size: 1.2rem;" class=""> Bem-vindo, {{ user.name }} </span><br>
	<div class="row mt-4">
		<div class="col-12 col-md-4">
			<div class="p-3 inventoryLeftCard">
				<div class="col-12 label-for-data">
					Participantes diários
				</div>
				<div class="col-12 fw-bold" style="font-size: 2.70rem;">
					{{ daily }}
				</div>
			</div>
		</div>

		<div class="col-12 col-md-4">
			<div class="p-3 inventoryLeftCard">
				<div class="col-12 label-for-data">
					Participantes semanais
				</div>
				<div class="col-12 fw-bold" style="font-size: 2.70rem;">
					{{ weekly }}
				</div>
			</div>
		</div>

		<div class="col-12 col-md-4">
			<div class="p-3 inventoryLeftCard">
				<div class="col-12 label-for-data">
					Participantes totais
				</div>
				<div class="col-12 fw-bold" style="font-size: 2.70rem;">
					{{ total }}
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12 mt-4">
			<div v-if="mountMyApex" class="p-3 inventoryLeftCard">
				<div class="col-12" style="min-height: 350px">
					<div
						class="h-100"
						:class="{'loadingCard': loading}"
						style="min-height: 350px"
					>
						<dashboard-chart
							ref="auctionGraph"
							type="line"
							height="100%"
							:options="auctionGraph"
							:series="[]"
							:xaxis="xaxis"
							:participations="participations"
							:contestents="contestents"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Layout from "../Shared/Layout.vue";

export default {
    layout: Layout,
    data() {
        return {
            loading: false,
            mergedArray: [],
            highestValue: null,
            mountMyApex: false,
        };
    },
    mounted() {
        this.mountMyApex = true;
    },
}
</script>

<script setup>
import {Head as MetaHead} from "@inertiajs/vue3";
import { defineAsyncComponent } from "vue";

const DashboardChart = defineAsyncComponent(() =>
    import("../Components/DashboardChart.vue")
);

defineProps({
    user: Object,
    daily: {
        type: Number,
        default: 0,
    },
    weekly: {
        type: Number,
        default: 0,
    },
    total: {
        type: Number,
        default: 0,
    },
    xaxis: {
        type: Array,
        default: () => [],
    },
    participations: {
        type: Array,
        default: () => [],
    },
    contestents: {
        type: Array,
        default: () => [],
    },
})

</script>
