export default {
    methods: {
        displayMessage(message, type = 'success') {

            toast[type](message);

        },
        asset(path) {
            let basePath = '';

            if (typeof window !== 'undefined') {
                basePath = window._asset;
            } else {
                basePath = import.meta.env.VITE_ASSET_URL;
            }

            if (!basePath.endsWith('/')) {
                basePath += '/';
            }

            if (path.startsWith('/')) {
                // remove first slash
                path = path.slice(1);
            }

            return basePath + path;
        },
        hasPermission(permission, validateSuperAdmin = true) {
            if (this.$page.props.auth.isSuperAdmin && validateSuperAdmin) {
                return true;
            }

            return this.$page.props.auth.permissions.some(obj => obj.name === permission);
        },
    }
}
