<template>
	<meta-head>
		<title>{{ contest.name }}</title>
	</meta-head>
	<bread-cumbs :links="breadcumbs" />

	<div
		class="row"
	>
		<template v-if="loading">
			<div class="col-12">
				<div class="alert alert-warning">
					<p><b>Atenção!</b></p>
					<p>A carregar...</p>
				</div>
			</div>
		</template>
		<div class="col-auto col-md-6 mt-3">
			<div class="col-12 ">
				<div class="col-12 label-for-data">
					Designação do sorteio
				</div>
				<div class="col-12 title-contest">
					{{ contest.contest_type }}
				</div>
				<div class="col-12 mb-3">
					Concurso: Ganha uma volta ao mundo
				</div>
				<div class="col-12 ">
					<div class="row">
						<div class="col-12 col-md-6">
							<div class="col-12 label-for-data">
								Participações
							</div>
							<div class="col-12 data-with-label">
								{{ totalParticipations }}
							</div>
						</div>
						<div class="col-12 col-md-6">
							<div class="col-12 label-for-data">
								Data do sorteio
							</div>
							<div class="col-12 data-with-label">
								{{ contest.ran_date ? formattedDate(contest.ran_date) : '--/--/----' }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-auto col-md-6 mt-3">
			<div class="p-3 inventoryLeftCard" :class="contest.status">
				<div class="col-12 label-for-data">
					Âmbito do sorteio
				</div>
				<div class="col-12 ">
					{{ contest.contest_type }}
				</div>
				<div class="col-12 pt-2">
					<div class="row">
						<div class="col-12 col-md-6">
							<div class="col-12 label-for-data">
								Data hora inicial
							</div>
							<div class="col-12 data-with-label">
								{{ formattedDate(contest.start_date) }}h
							</div>
						</div>
						<div class="col-12 col-md-6">
							<div class="col-12 label-for-data">
								Data hora final
							</div>
							<div class="col-12 data-with-label">
								{{ formattedDate(contest.end_date) }}h
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 pt-2">
					<div class="row">
						<div class="col-12 col-md-4">
							<div class="col-12 label-for-data">
								Vencedores
							</div>
							<div class="col-12 data-with-label">
								{{ contest.possible_winners }}
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="col-12 label-for-data">
								Suplentes
							</div>
							<div class="col-12 data-with-label">
								{{ contest.possible_replacements }}
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="col-12 label-for-data">
								Estado do sorteio
							</div>
							<div class="col-12 data-with-label">
								{{ contest.status }}
							</div>
						</div>
					</div>
				</div>
				<!--
                <p><b> Nome: </b> {{ contest.name }} </p>

                <p v-if="contest.rule === 'Diário'"><b> {{contest.rule}}: </b> {{ contest.start_date }}</p>
                <p v-else><b> {{contest.rule}}: </b> {{ contest.start_date }} - {{ contest.end_date }} </p>
                <p v-if="contest.status === 'Concluído'"><b> {{contest.status}} com </b>  {{participationsUsedInContestCount}} participações</p>
                <p v-if="contest.status === 'Em Curso'"><b> {{contest.status}} com </b>  {{participationsUsedInContestCount}} participações</p>
                <p v-if="contest.status === 'Por Iniciar'"><b> {{contest.status}} com </b>  {{participationsUsedInContestCount}} participações possiveis</p>
-->
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-lg">
			<DefaultDataTable
				ref="main-dt"
				:url="route('contest.professionalparticipation.search')"
				:columns="dtColumns"
				:filters="filters"
				:search="search"
				:show-pagination="true"
				input-search-placehold="Escreva o que procura..."
				:params="{contest: contest.id}"
			>
				<template #after-filters>
					<div
						class="col-auto align-self-center justify-content-end mt-2"
						style="margin-left: auto;"
					>
						<template v-if="contest.status !== 'Concluído'">
							<button
								v-if="hasPermission('Correr Sorteios') && contest.status !== 'Em Curso'"
								type="button"
								class="btn btn-warning btn-big"
								data-bs-toggle="modal"
								data-bs-target="#runSorteioModal"
							>
								<i class="mdi mdi-dice-multiple" />
								Correr Sorteio
							</button>


							<!--							<template v-if="hasPermission('Editar sorteio')">
								<Link
									class="btn btn-warning btn-big ms-2"
									:href="route('admin.contest.edit', contest.id)"
								>
									<i class="mdi mdi-pencil" />
									Editar
								</Link>
							</template>-->
						</template>
						<template v-if="contest.status === 'Concluído'">
							<a
								:href="route('sorteioProfessionalParticipants', contest.id)"
								class="btn btn-warning btn-big "
								style="margin-right: 10px;"
							>
								<i class="mdi mdi-bullhorn-outline" />
								Participações excel
							</a>
						</template>
					</div>
				</template>
			</DefaultDataTable>
		</div>
	</div>
	<div
		v-if="hasPermission('Correr Sorteios')"
		id="runSorteioModal"
		class="modal fade"
		tabindex="-1"
		aria-labelledby="runSorteioLabel"
		aria-hidden="true"
	>
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						Correr Sorteio
					</h5>
					<div
						style="width: 20px; height: 20px"
						class="btn-close cursor-pointer"
						data-bs-dismiss="modal"
						aria-label="Close"
					/>
				</div>


				<div class="modal-body">
					<template v-if="! contestEndDateCheck">
						<div class="col-12">
							<div class="alert alert-warning">
								<p><b>Atenção!</b></p>
								<p>A data do sorteio ainda não foi atingida.</p>
							</div>
						</div>
					</template>
					<template
						v-if="participationsInDateRangeCount < (contest.possible_winners + contest.possible_replacements)"
					>
						<div class="col-12">
							<div class="alert alert-warning">
								<p><b>Atenção!</b></p>
								Existe um total de {{ participationsInDateRangeCount }} participações, mas apenas
								{{ contest.possible_winners }} possíveis vencedores e {{
									contest.possible_replacements
								}} possíveis suplentes.
							</div>
						</div>
					</template>

					<div class="col-12">
						Tem a certeza que quer correr o sorteio?
						Esta ação não pode ser desfeita.
					</div>
				</div>

				<div v-if="loading===false" class="modal-footer">
					<a
						v-if="hasPermission('Correr Sorteios') && contestEndDateCheck"
						href="javascript:void(0)"
						class="btn btn-warning"
						data-bs-dismiss="modal"
						@click="runSorteio"
					>
						<i class="mdi mdi-dice-multiple" />
						Correr Sorteio
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import BreadCumbs from "../../Components/etc/BreadCumbs.vue";
import DefaultDataTable from "../../Components/DefaultDataTable.vue";
import {DateTime, Settings} from "luxon";
import {Head} from '@inertiajs/vue3'

Settings.defaultLocale = "pt";
Settings.defaultZone = "Europe/Lisbon";

export default {
    name: "Show",
    components: {
        DefaultDataTable,
        BreadCumbs,
        MetaHead: Head,
    },
    layout: Layout,

    props: {
        contest: {
            type: Object,
            required: true,
            default: () => {
                return {
                    id: null,
                    name: null,
                }
            }
        },
        participationsUsedInContestCount: {
            type: Number,
            required: true
        },
        participationsInDateRangeCount: {
            type: Number,
            required: true
        },
        search: {
            type: Boolean,
            default: true
        },
        hiddenColumns: {
            type: Array,
            default: () => []
        },
        statuses: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            isEditing: false,
            saving: false,
            loading: false,
            breadcumbs: [
                {
                    name: 'Concursos',
                    link: this.route('professionalcontests.index')
                },
                {
                    name: '01 Ganha uma volta ao mundo',
                    link: this.route('professionalcontests.index')
                },
                {
                    name: 'Sorteios',
                    link: this.route('professionalcontests.index')
                },
                {
                    name: this.contest.name,
                    link: this.route('professionalcontests.show', this.contest.id)
                },
            ],
            dtColumns: [
                {data: 'ID', dataTitle: 'ID', name: 'id', searchable: true, orderable: true, minWidth: '50px'},
                {
                    data: 'Participante id',
                    dataTitle: 'Participante id',
                    name: 'client_id',
                    searchable: true,
                    orderable: true,
                    minWidth: '50px'
                },
                {
                    data: 'Participante nome',
                    dataTitle: 'Participante nome',
                    name: 'client_name',
                    searchable: true,
                    orderable: true,
                    minWidth: '50px'
                },
                {
                    data: 'Resultado',
                    dataTitle: 'Resultado',
                    name: 'contest_result',
                    searchable: true,
                    orderable: true,
                    center: true,
                    minWidth: '50px',
                },
                {
                    data: 'Email',
                    dataTitle: 'Email',
                    name: 'email',
                    searchable: true,
                    orderable: true,
                    center: true,
                    minWidth: '50px'
                },
                {
                    data: 'Telefone',
                    dataTitle: 'Telefone',
                    name: 'professional_contestent.phone',
                    searchable: true,
                    orderable: true,
                    center: true,
                    minWidth: '50px'
                },

            ],
            filters: [
                {
                    label: 'Estados',
                    name: 'tags',
                    options: [],
                    internalSearch: false,
                    isMultiple: true,
                    wrapperClass: 'col-12 col-md-3 col-lg-4',
                }
            ]
        }
    },
    computed: {
        DateTime() {
            return DateTime
        },
        contestEndDateCheck() {
            return DateTime.fromISO(this.contest.end_date) <= DateTime.now()
        },

        /**
         * Pode ser que teve uma nova participação enquanto estava neste ecrã
         * Para não precisar dar refresh, busco o valor do datatable
         * @returns {number}
         */
        totalParticipations() {
            if (this.$refs['main-dt']) {
                return this.$refs['main-dt'].recordsTotal;
            }

            return this.participationsInDateRangeCount
        },
        statusesFiltered(){
            if (this.contest.status === 'Concluído') {
                return this.statuses.filter(status => status !== 'Em concurso')
            }
            return this.statuses.filter(status => status !== 'Não premiado')
        }
    },
    created() {
        this.dtColumns = this.dtColumns.filter(column => !this.hiddenColumns.includes(column.data));
    },

    mounted(){
        this.filters[0].options = this.statusesFiltered;

        if (this.contest.status === 'Concluído') {
            this.filterStatusGood()
        }
    },

    methods: {
        runSorteio() {
            if(this.loading) return;
            this.loading = true;
            /*Fazer copy deste fred*/
            return axios.post(route('professionalcontest.run', this.contest.id))
                .then(() => {
                    this.filterStatusGood();
                    this.$refs['main-dt'].getResults()
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading=false;
                })
        },
        notifyWinners() {
            this.$inertia.post(route('admin.contest.notifyWinners', this.contest.id))
            this.$refs['main-dt'].getResults()
        },
        formattedDate(date) {
            let dateObj = DateTime.fromISO(date)

            if (!dateObj.isValid) {
                dateObj = DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss')
            }

            return dateObj.toFormat('dd/MM/yyyy HH:mm')
        },
        filterStatusGood(){
            this.$refs['main-dt'].filterValues = {
                tags: ['Premiado', 'Suplente', 'Vencedor']
            }
        }
    }
}
</script>

<style lang="scss">
.datatableMultiselect .multiselect__tags {
    min-height: 57px !important;
}

#searchInput {
    min-height: 57px;
    border-radius: 5px;
    border: 1px solid #B0B5B5;
    background: #FFF;
    padding: 10px;
    color: #B0B5B5;

    &::placeholder {
        color: #B0B5B5;
    }
}
</style>

<style scoped>
.btn-big {
    font-size: 1.2375rem;
    line-height: 2.78438rem;
}

.btn-warning {
    background-color: #F5AA05;
    color: #FFF;
}

.btn-warning:hover {
    color: #FFF;
}
</style>
