<template>
    <div class="row justify-content-start mt-3">
        <div class="col-lg-auto">
            <button type="submit" :disabled="disabled" class="btn btn-primary">
                <i class="mdi mdi-check"></i> Submeter
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormSubmitButton",
    props:{
        disabled: Boolean,
    }
}
</script>

<style scoped>

</style>
