<template>
	<div class="min-vh-100 d-flex flex-column">
		<div
			class="col-12 mainContainer flex-grow-1"
			:class="{'mainContainerOpen': navOpen}"
			style="height: 100%; overflow-x: hidden"
		>
			<div class="logo">
				<inertia-link :href="route('dashboard')">
					<img
						:src="navOpen ? asset('images/prio.svg') : asset('images/prio-small.svg')"
						:width="navOpen ? 175 : 20"
						alt="logo"
					>
				</inertia-link>
			</div>

			<div class="myNavbar">
				<template
					v-for="(link, index) in links"
					:key="`${index}-${link.name}`"
				>
					<template v-if="link.submenu == null">
						<inertia-link
							v-if="link.show"
							:href="link.url"
							class="eachLink"
							:class="{'mt-1': index != 0, 'active': route().current(link.name)}"
						>
							<span class="eachLinkInside">
								<span
									class="d-flex align-items-center justify-content-center"
									:class="{'my-2': !navOpen}"
									v-html="link.icon"
								/>
								<span v-if="navOpen" :data-link-name="link.name">{{ link.label }}</span>
							</span>
						</inertia-link>
					</template>
					<template v-else>
						<div
							v-if="link.show"
							class="eachLink-false"
							:class="{'mt-1': index != 0}"
						>
							<span
								class="d-flex"
								style="margin-left: 10px;"
								@click="link.submenu = !link.submenu"
							>
								<span
									class="d-flex align-items-center justify-content-center"
									:class="{'my-2': !navOpen}"
									v-html="link.icon"
								/>
								<span v-if="navOpen" :data-link-name="link.name">{{ link.label }}</span>
								<span v-if="link.submenu && navOpen" class="mdi mdi-chevron-up d-flex align-items-center justify-content-center" />
								<span v-else-if="navOpen" class="mdi mdi-chevron-down d-flex align-items-center justify-content-center" />
							</span>
							<ul
								v-if="link.submenu && navOpen"
								class="submenu mb-0"
								:class="{'submenuOpen': link.submenu}"
							>
								<template
									v-for="(sublink, subindex) in link.links"
									:key="`${subindex}-${sublink.name}`"
								>
									<inertia-link
										v-if="sublink.show"
										:href="sublink.url"
										class="eachLink mt-1"
										:class="{'active': route().current(sublink.name)}"
									>
										<span class="eachLinkInside">
											<span :data-link-name="sublink.name">{{ sublink.label }}</span>
										</span>
									</inertia-link>
								</template>
							</ul>
						</div>
					</template>
				</template>
			</div>

			<div class="topbar">
				<div class="col-12 d-flex align-items-center justify-content-between h-100">
					<div>
						<i
							class="cursor-pointer mdi mdi-menu"
							style="font-size: 30px;"
							@click="toggleNavOpen"
						/>
					</div>

					<div class="d-flex align-items-center gap-4">
						<div class="position-relative" @click="notificationsModalOpen = true">
							<span class="mdi mdi-bell-outline" style="font-size: 1.3rem;" />
							<span
								v-if="notifications.length > 0"
								style="left: 0.6rem"
								class="badge bg-danger rounded-pill position-absolute"
							>
								{{ notifications.length }}
							</span>
						</div>

						<img
							id="menu-dropdown"
							class="rounded-circle dropdown-toggle"
							data-bs-toggle="dropdown"
							:src="$page.props.auth.profilePicture"
							height="35"
							alt="Profile Picture"
						>
						<ul class="dropdown-menu custom-dropdown-menu font-gray-darkest" aria-labelledby="menu-dropdown">
							<a :href="route('users.edit', $page.props.auth.id)">
								<li>
									<i class="mdi mdi-account" />
									<span>Perfil</span>
								</li>
							</a>
							<li @click="logout">
								<i class="mdi mdi-logout" />
								<span>Logout</span>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="content" style="overflow-x: hidden">
				<slot />
			</div>
		</div>
	</div>
	<modal
		:show="notificationsModalOpen"
		title="Notificações"
		:with-save="false"
		@close="notificationsModalOpen = false"
	>
		<div class="overflow-hidden">
			<div
				v-for="notification in notifications"
				:key="'notification' + notification.id"
				:ref="'notification' + notification.id"
				class="card card-body shadow-sm border mb-4 v-slide-fade-out"
			>
				<div class="d-flex justify-content-between gap-3">
					<div>
						<i
							v-if="notification.data.icon"
							class="me-1"
							:class="[notification.data.icon, notification.data.icon_color]"
							style="font-size: 20px"
						/>
						<span style="font-size: 20px">
							<b>{{ notification.data.title }}</b>
						</span>
					</div>
					<i
						class="mdi mdi-trash-can-outline text-danger"
						style="font-size: 20px"
						@click="markAsRead(notification.id)"
					/>
				</div>
				<span>
					{{ notification.data.message }}
				</span>
			</div>
			<div v-if="notifications.length === 0" class="d-flex justify-content-center">
				<h3>Sem notificações!</h3>
			</div>
		</div>
	</modal>
</template>

<script>
import Modal from "../Components/Modal.vue";
import { Link as InertiaLink } from "@inertiajs/vue3";

export default {
    name: 'Layout',
    components: {
        Modal,
        InertiaLink
    },
    data() {
        return {
            buildings: [],
            showInventoryJustificationModal: false,
            inventory_break_data: [],
            navOpen: true,

            links: [],
            notifications: [],
            notificationsModalOpen: false,
        }
    },
    watch: {
        '$page.props.toast_message': function (newVal) {
            if (newVal) {
                //With every request I send a _timestamp, so I know when to show the toast even if the message didn't change
                const toast_message = newVal.substring(0, newVal.lastIndexOf('_'))
                switch (this.$page.props.toast_type) {
                    case 'success':
                        toast.success(toast_message)
                        break;
                    case 'error':
                        toast.error(toast_message)
                        break;
                    case 'warning':
                        toast.warning(toast_message)
                        break;
                    default:
                        toast.info(toast_message)
                        break;
                }
            }
        },
        '$page.props.errors': function (newVal) {
            if (newVal) {
                try {
                    for (const [key, value] of Object.entries(newVal)) {
                        this.$refs[key].classList?.add('is-invalid')
                        toast.error(value)
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        },
    },

    beforeMount() {
        this.navOpen = localStorage.getItem('navOpen') === 'true';
        this.links = [
            {
                show: true,
                label: 'Dashboard',
                name: 'dashboard',
                url: this.route('dashboard'),
                icon: '<span class="mdi mdi-home-outline"/>'
            },
            {
                show: this.hasPermission('Ver Sorteios'),
                label: 'Concursos particulares',
                icon: '<span class="mdi mdi-abacus"/>',
                submenu: false,
                links:[
                    {
                        show: this.hasPermission('Ver Sorteios'),
                        label: 'Sorteios',
                        name: 'admin.contest.index',
                        url: this.route('admin.contest.index'),
                        icon: '<span class="mdi mdi-abacus"/>'
                    },
                    {
                        show: this.hasPermission('Ver Participações'),
                        label: 'Participações',
                        name: 'admin.participation.index',
                        url: this.route('admin.participation.index'),
                        icon: '<span class="mdi mdi-abacus"/>'
                    },
                    {
                        show: this.hasPermission('Concorrentes'),
                        label: 'Concorrentes',
                        name: 'contestents.index',
                        url: this.route('contestents.index'),
                        icon: '<span class="mdi mdi-account-multiple-outline"/>'
                    },
                    {
                        show: this.$page.props.auth.isSuperAdmin,
                        label: 'Criar novo',
                        name: 'contests.create',
                        url: this.route('contests.create'),
                        icon: '<span class="mdi mdi-bell-alert"/>'
                    },
                ],
            },
            {
                show: this.hasPermission('Ver Sorteios'),
                label: 'Concursos profissionais',
                icon: '<span class="mdi mdi-abacus"/>',
                submenu: false,
                links:[
                    {
                        show: this.$page.props.auth.isSuperAdmin,
                        label: 'Criar novo',
                        name: 'profissionalcontests.create',
                        url: this.route('professionalcontests.create'),
                        icon: '<span class="mdi mdi-bell-alert"/>'
                    },
                    {
                        show: this.hasPermission('Ver Sorteios'),
                        label: 'Sorteios',
                        name: 'professionalcontests.index',
                        url: this.route('professionalcontests.index'),
                        icon: '<span class="mdi mdi-abacus"/>'
                    },
                    {
                        show: this.hasPermission('Ver Participações'),
                        label: 'Participações',
                        name: 'professionalparticipation.index',
                        url: this.route('professionalparticipation.index'),
                        icon: '<span class="mdi mdi-abacus"/>'
                    },
                    {
                        show: this.hasPermission('Concorrentes'),
                        label: 'Concorrentes',
                        name: 'professionalcontestents.index',
                        url: this.route('professionalcontestents.index'),
                        icon: '<span class="mdi mdi-account-multiple-outline"/>'
                    }
                ],
            },

            {
                show: this.hasPermission('Utilizadores'),
                label: 'Utilizadores',
                name: 'users.index',
                url: this.route('users.index'),
                icon: '<span class="mdi mdi-account-multiple-outline"/>'
            },
            /* {
                 show: this.hasPermission('Exportações'),
                 label: 'Exportações',
                 name: 'exports',
                 url: route('exports.index'),
                 icon: '<span class="mdi mdi-file-excel"/>'
             },*/

            {
                show: this.$page.props.auth.isSuperAdmin,
                label: 'Notificações',
                name: 'notifications.index',
                url: this.route('notifications.index'),
                icon: '<span class="mdi mdi-bell-alert"/>'
            },
            {
                show: this.$page.props.auth.isSuperAdmin,
                label: 'Horizon',
                name: 'horizon',
                url: '/manager/devtools/horizon',
                icon: '<span class="mdi mdi-view-dashboard"/>'
            },
            {
                show: this.$page.props.auth.isSuperAdmin,
                label: 'Telescope',
                name: 'telescope',
                url: this.route('telescope'),
                icon: '<span class="mdi mdi-bug"/>'
            },
            {
                show: this.$page.props.auth.isSuperAdmin,
                label: 'Pulse',
                name: 'pulse',
                url: this.route('pulse'),
                icon: '<span class="mdi mdi-pulse"/>'
            },
        ]
    },

    mounted() {
        if(this.$page.props.toast_message){
            const toast_message = this.$page.props.toast_message.substring(0, this.$page.props.toast_message.lastIndexOf('_'));
            this.displayMessage(toast_message, this.$page.props.toast_type);
        }
        axios.get(route('user.notifications'))
            .then(response => {
                this.notifications = response.data
            })
            .catch(error => {
                console.log(error)
            })

    },

    methods: {
        toggleNavOpen() {
            this.navOpen = !this.navOpen
            localStorage.setItem('navOpen', this.navOpen)
        },

        logout() {
            this.$inertia.post(route('logout'))
            setTimeout(() => {
                window.location.reload()
            }, 300)
        },

        markAsRead(notification) {
            axios.post(route('user.notifications.read', notification))
                .then(response => {
                    this.$refs['notification' + notification][0].classList.add('hide')

                    setTimeout(() => {
                        this.notifications = this.notifications.filter(item => item.id !== notification)
                    }, 150)
                })
                .catch(error => {
                    console.log(error)
                })
        },
    }
}
</script>

<style scoped>

</style>
