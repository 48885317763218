<template>
	<form @submit.prevent="form.post(route('password.update'))">
		<login-input
			v-model="form.password"
			label="Password"
			name="password"
			type="password"
			placeholder=""
			:error="errors.password"
		/>

		<login-input
			v-model="form.password_confirmation"
			label="Confirmar Password"
			name="password_confirmation"
			type="password"
			placeholder=""
			:error="errors.password_confirmation"
			class="mt-4"
		/>

		<div class="col-12 pt-3">
			<button
				type="submit"
				:disabled="form.processing"
				class="btn btn-primary w-100"
			>
                Submeter
			</button>
		</div>
	</form>
</template>

<script>
    import LoginLayout from "../../Shared/LoginLayout.vue";
    import LoginInput from "../../Components/LoginInput.vue";
    import { useForm } from '@inertiajs/vue3';

    export default {
        name: "ResetPassword",

        components: {LoginInput},
        layout: LoginLayout,

        props:{
            errors: Object,
            email: String,
            token: String,
        },

        data(){
            return{
                form: {},
            }
        },

        mounted() {
            this.form = useForm({
                token: this.token,
                email: this.email,
                password: '',
                password_confirmation: '',
            });
        },
    }
</script>
