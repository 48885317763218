<template>
	<meta-head title="Notificações" />
	<DefaultDataTable
		ref="main-datatable"
		:url="route('notifications.search')"
		:columns="dtColumns"
	>
		<template #button>
			<Link
				class="btn btn-outline-primary d-flex align-items-center"
				:href="route('notifications.create')"
			>
				<i style="font-size: 20px; line-height: 1px;" class="mdi mdi-plus pe-1" />
				Nova notificação
			</Link>
		</template>
	</DefaultDataTable>
</template>

<script setup>
import DefaultDataTable from "../../Components/DefaultDataTable.vue";
import { Head as MetaHead } from "@inertiajs/vue3";

const dtColumns = [
    {data: 'Titulo', dataTitle: 'Titulo', name: 'data.title', searchable: false, orderable: false, minWidth: '50px'},
    {data: 'Mensagem', dataTitle: 'Mensagem', name: 'data.message', searchable: false, orderable: false, minWidth: '50px'},
    {data: 'Enviado para', dataTitle: 'Enviado para', name: 'total', searchable: false, orderable: false, minWidth: '50px'},
    {data: 'Lido por', dataTitle: 'Lido por', name: 'amount_read', searchable: false, orderable: false, minWidth: '50px'},
    {data: 'Enviado a', dataTitle: 'Enviado a', name: 'created_at', searchable: false, orderable: false, minWidth: '50px'},
    // {data: 'Descrição', dataTitle: 'Descrição', name: 'description', searchable: true, orderable: true, minWidth: '50px'},
];
</script>

<script>
import Layout from "../../Shared/Layout.vue";
export default {
    layout: Layout,
}
</script>
<style scoped>

</style>
