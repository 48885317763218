<template>
	<div class="main-container">
		<div class="default-background-prio">
			<div class="default-background-prio-overlay">
				<div class="container-fluid">
					<div class="row align-items-center justify-content-center min-vh-100 ">
						<div style="width: 500px; max-width: 100%" class="col-auto">
							<div class="col-12 px-0 pb-4 text-center">
								<a href="https://ganhaecodiesel.pt/">
									<img
										:src="asset('images/logo.svg')"
										width="101"
										class="logo-class"
										alt="logo"
									>
								</a>
								<div class="banner-title " style="color:white">
									Ganha uma <br> volta ao mundo
									<span class="col-12 d-flex align-items-center justify-content-center">
										em ECO Diesel.
									</span>
								</div>
							</div>

							<div class="col-12 p-4  shadow-sm br-10 default-box-prio-radius default-box-prio-primary">
								<slot />
							</div>
						</div>
						<!--                        <div class="col-12 index-backwrapper text-center">
                            <a href="javascript:history.back()">
                                <i class="mdi mdi-chevron-left"></i>
                                voltar
                            </a>
                        </div>-->
						<div class="index-backwrapper-fake col-12" />
					</div>
					<div class="row">
						<div class="col-12 px-0 login-footer text-center">
							<div class="section-wrapper section-wrapper-footer">
								<div class="container-wrapper py-5">
									<div class="container ">
										<div class="row align-content-center justify-content-center d-md-none">
											<div class="col-12 col-md-4 d-flex pb-4 section-footer align-content-center justify-content-center">
												<a href="https://ganhaecodiesel.pt/">
													<img
														:src="asset('images/logo.svg')"
														width="101"
														class="logo-class-logged"
														alt="logo"
													>
												</a>
											</div>
											<div class="col-12 align-content-center section-socials-footer justify-content-center d-flex pb-3">
												<a href="https://ecodiesel.pt/" class="col-auto">
													ecodiesel.pt
												</a>
											</div>
											<div class="col-12  d-flex section-footer section-socials-footer align-content-center justify-content-center">
												<div class="row">
													<a
														href="https://www.facebook.com/PrioEnergy"
														target="_blank"
														class="col-auto"
													>
														<img
															:src="asset('images/facebook.svg')"
															width="24"
															alt="facebook"
														>
													</a>
													<a
														href="https://www.instagram.com/prioenergy/"
														target="_blank"
														class="col-auto"
													>
														<img
															:src="asset('images/instagram.svg')"
															width="24"
															alt="instagram"
														>
													</a>
													<a
														href="https://www.linkedin.com/company/prio-sgps"
														target="_blank"
														class="col-auto"
													>
														<img
															:src="asset('images/linkedin.svg')"
															width="24"
															alt="linkedin"
														>
													</a>
													<a
														href="https://www.youtube.com/@PRIOPT"
														target="_blank"
														class="col-auto"
													>
														<img
															:src="asset('images/youtube.svg')"
															width="24"
															alt="youtube"
														>
													</a>
													<a
														href="https://www.tiktok.com/@prio.pt"
														target="_blank"
														class="col-auto"
													>
														<img
															:src="asset('images/tiktok.svg')"
															width="20"
															alt="tiktok"
														>
													</a>
												</div>
											</div>
											<div class="col-12 col-md-4 d-flex section-footer section-text-footer align-content-center justify-content-center pt-4 pb-4 me-auto">
												Concurso publicitário nº 308/2023 autorizado pela Câmara Municipal de Ílhavo.
											</div>
											<div class="col-12 col-md-4 d-flex section-footer section-links-footer a align-content-center justify-content-center">
												<div class="row">
													<div class="col-12">
														<a :href="route('regulamento')" class="col-12">
															Regulamento
														</a>
													</div>
													<div class="col-12">
														<a :href="route('politica-privacidade')" class="col-12">
															Politica de Privacidade
														</a>
													</div>
													<div class="col-12">
														<a :href="route('politica-cookies')" class="col-12">
															Politica de Cookies
														</a>
													</div>
												</div>
											</div>
										</div>
										<div class="row align-content-center justify-content-center d-md-flex d-none">
											<div class="col-12 col-md-4 d-flex section-footer section-text-footer align-content-center justify-content-center pb-4 pt-md-5">
												Concurso publicitário nº 308/2023 autorizado pela Câmara Municipal de Ílhavo.
											</div>
											<div class="col-12 col-md-4 d-flex pb-4 section-footer align-content-center justify-content-center">
												<div class="row">
													<div class="col-12 align-content-center justify-content-center d-flex pb-3">
														<img
															:src="asset('images/logo.svg')"
															width="101"
															class="logo-class-logged"
															alt="logo"
														>
													</div>
													<div class="col-12 align-content-center section-socials-footer justify-content-center d-flex pb-3">
														<a href="https://ecodiesel.pt/" class="col-auto">
															ecodiesel.pt
														</a>
													</div>
													<div class="col-12  d-flex section-footer section-socials-footer align-content-center justify-content-center">
														<div class="row">
															<a
																href="https://www.facebook.com/PrioEnergy"
																target="_blank"
																class="col-auto"
															>
																<img
																	:src="asset('images/facebook.svg')"
																	width="24"
																	alt="facebook"
																>
															</a>
															<a
																href="https://www.instagram.com/prioenergy/"
																target="_blank"
																class="col-auto"
															>
																<img
																	:src="asset('images/instagram.svg')"
																	width="24"
																	alt="instagram"
																>
															</a>
															<a
																href="https://www.linkedin.com/company/prio-sgps"
																target="_blank"
																class="col-auto"
															>
																<img
																	:src="asset('images/linkedin.svg')"
																	width="24"
																	alt="linkedin"
																>
															</a>
															<a
																href="https://www.youtube.com/@PRIOPT"
																target="_blank"
																class="col-auto"
															>
																<img
																	:src="asset('images/youtube.svg')"
																	width="24"
																	alt="youtube"
																>
															</a>
															<a
																href="https://www.tiktok.com/@prio.pt"
																target="_blank"
																class="col-auto"
															>
																<img
																	:src="asset('images/tiktok.svg')"
																	width="20"
																	alt="tiktok"
																>
															</a>
														</div>
													</div>
												</div>
											</div>

											<div class="col-12 col-md-4 d-flex section-footer section-links-footer align-content-center justify-content-center">
												<div class="row align-content-center">
													<div class="col-12" style="height:50px;">
														<a :href="route('regulamento')" class="col-12">
															Regulamento
														</a>
													</div>
													<div class="col-12" style="height:50px;">
														<a :href="route('politica-privacidade')" class="col-12">
															Politica de Privacidade
														</a>
													</div>
													<div class="col-12" style="height:50px;">
														<a :href="route('politica-cookies')" class="col-12">
															Politica de Cookies
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: "LoginLayout",
}
</script>

<style scoped>
.main-container {
    background-color: #f5f5f5;
}
</style>
