<template>
	<Card
		v-if="search"
		:no-padding="true"
		:class="{ 'mb-4': topPadding }"
	>
		<div class="disappearInDesktop" @click="isFilterModalOpen = ! isFilterModalOpen">
			<span class="font-18">Filtros de pesquisa</span>
			<span> > </span>
		</div>

		<div class="disappearInMobile">
			<div class="row">
				<div v-if="removesearch !== 'true'" class="col-lg-3 pb-3">
					<datatable-input
						v-model="searchInput"
						label="Pesquisa"
						name="searchInput"
						:placeholder="inputSearchPlacehold"
					/>
				</div>

				<template v-for="(filter, index) in filters">
					<div
						v-if="! filter.hasOwnProperty('show') || filter.show"
						:key="`filter-${index}`"
						:class="filter.hasOwnProperty('wrapperClass') && filter.wrapperClass ? filter.wrapperClass : 'col-lg-3 pb-3'"
					>
						<template v-if="filter.type && filter.type !== 'multiselect'">
							<default-input
								v-model="filterValues[filter.name]"
								:label="filter.label"
								:name="filter.name"
								:type="filter.type"
								placeholder=""
							/>
						</template>
						<template v-else>
							<label class="form-label"> {{ filter.label }} </label>
							<search-multiselect
								v-if="filter.url"
								:ref="'searchMultiselect_' + filter.name"
								v-model="filterValues[filter.name]"
								:track="filter.track"
								:label="filter.multiselectLabel"
								:multiple="filter.isMultiple ?? false"
								:close-on-select="! filter.isMultiple ?? true"
								:show-labels="false"
								:url="filter.url"
								:async="! filter.internalSearch"
								:select="filter.select"
								:preselect-first="filter.preselectFirst"
								:params="filter.params ?? []"
							/>

							<multiselect
								v-else
								v-model="filterValues[filter.name]"
								:options="filter.options"
								:track-by="filter.track"
								:label="filter.multiselectLabel"
								:multiple="filter.isMultiple ?? false"
								:close-on-select="! filter.isMultiple ?? true"
								class="datatableMultiselect"
								placeholder=""
								:show-labels="false"
								:preselect-first="filter.preselectFirst"
							/>
						</template>
					</div>
				</template>
				<slot name="after-filters" />
			</div>
		</div>
	</Card>

	<Card :no-padding="noPadding">
		<div class="row justify-content-between mb-3">
			<h5 v-if="title" class="mb-0">
				{{ title }}
			</h5>
			<div class="col-auto pb-2 pb-lg-0 d-flex align-items-center">
				<div class="pe-3">
					<div v-if="button instanceof Array" class="d-flex dtMultipleButtonsDiv">
						<Link
							v-for="oneButton in button"
							v-if="oneButton && oneButton.show !== false"
							class="btn btn-outline-primary d-flex align-items-center"
							:href="oneButton.url ?? '#'"
						>
							<i style="font-size: 20px; line-height: 1px;" class="mdi mdi-plus pe-1" />
							{{ oneButton.label ?? 'Novo' }}
						</Link>
					</div>
					<Link
						v-else
						v-if="button && button.show === true"
						class="btn btn-outline-primary d-flex align-items-center"
						:href="button.url ?? '#'"
					>
						<i style="font-size: 20px; line-height: 1px;" class="mdi mdi-plus pe-1" />
						{{ button.label ?? 'Novo' }}
					</Link>
					<slot name="button" />
				</div>

				<div v-if="loading" class="d-flex align-items-center position-relative">
					<img
						class="position-absolute"
						style="width: 50px"
						:src="asset('images/loader.svg')"
					>
					<span class="" style="margin-left: 50px">Loading...</span>
				</div>
			</div>

			<div v-if="showRecordsPerPage" class="col-auto d-flex align-items-center">
				<span class="pe-3 font-weight-500"> Resultados por página </span>
				<CustomSelect
					v-model="length"
					style="z-index: 1"
					:options="[10, 25, 50, 100]"
				/>
			</div>
		</div>

		<div class="table-responsive">
			<table id="pageTable" class="table align-middle myDatatable">
				<thead>
					<tr style="white-space: nowrap">
						<template v-for="(column, index) in filteredColumns">
							<th
								class="text-uppercase"
								:class="{
									'cursor-pointer': column.orderable,
									'hide-on-mobile': column.hideOnMobile,
									'text-center': column.center
								}"
								:style="`min-width: ${ column.minWidth ?? 'auto' }; max-width: ${ column.maxWidth ?? 'auto' }; width: ${ column.maxWidth ? '10000px' : 'auto' };`"
								@click="orderTable(index)"
							>
								{{ column.data }}
								<span v-if="column.orderable">
									<img
										v-if="order.column == index && order.dir == 'asc'"
										class="ms-2"
										style="width: 8px; filter: brightness(1000%)"
										:src="asset('images/datatable/tableOrderAsc.svg')"
									>
									<img
										v-else-if="order.column == index && order.dir == 'desc'"
										class="ms-2"
										style="width: 8px; filter: brightness(1000%)"
										:src="asset('images/datatable/tableOrderDesc.svg')"
									>
									<img
										v-else
										class="ms-2"
										style="width: 8px; filter: brightness(1000%)"
										:src="asset('images/datatable/tableOrderDef.svg')"
									>
								</span>
							</th>
						</template>
					</tr>
				</thead>

				<tbody class="table-group-divider">
					<tr
						v-for="(row, rowIndex) in result"
						:class="{'opacity05': row.active !== undefined && row.active === 0, 'cursor-pointer': row.main_link}"
						@click="redirectTo(row.main_link)"
					>
						<template v-for="column in filteredColumns">
							<td
								class="position-relative"
								:data-title="column.dataTitle"
								style="font-weight: 500;"
								:class="{
									'hide-on-mobile': column.hideOnMobile,
									'text-center': column.center,
								}"
								@mouseenter="showHover[column.name][rowIndex] = true"
								@mouseleave="showHover[column.name][rowIndex] = false"
							>
								<slot
									:name="column.name"
									:row="row"
									:column="column"
									:row-index="rowIndex"
								>
									<span
										v-if="column.link"
										class="not-bs-text-primary"
										:class="{'text-nowrap': column.noWrap}"
									>
										<Link style="cursor: pointer;" :href="row[column.link] ?? '#1'">
											<span v-html="getShowValue(row, column)" />
										</Link>

										<i
											v-if="column.showCopyButton"
											class="mdi mdi-content-copy font-16 position-absolute pl-2"
											@click="copyToClipboard(column.copyData ?? getShowValue(row, column))"
											@mouseover="disableClickToRedirect = true"
											@mouseleave="disableClickToRedirect = false"
										/>

										<div
											v-show="showHover[column.name][rowIndex] && column.hover"
											class="position-absolute rounded shadow-border p-3 bg-white dtHoverCard"
											style="width: fit-content; z-index: 100; bottom: 0px; left: 75px;"
										>
											<p class="text-dark font-weight-500 font-16 text-nowrap">{{ column['hover_title'] }}</p>
											<div class="">
												<template v-for="hoverDetail in column['hover_data']">
													<span class="text-dark font-weight-500 pr-2">{{ hoverDetail['label'] }}:</span>
													<span class="text-dark font-weight-400">{{ getHoverData(row, hoverDetail['value']) }}</span>
													<br>
												</template>
											</div>
										</div>
									</span>

									<span v-else-if="column.name === 'deleteAction'">
										<i
											class="cursor-pointer text-danger mdi mdi-trash-can"
											@click="deleteAction(row[column.name])"
										/>
									</span>

									<div v-else>
										<div v-if="column.dataArray">
											<div v-if="column.expanded">
												<div v-if="getHoverData(row, column.dataArray).length <= 0">
													{{ getShowValue(row, column) }}
												</div>

												<div
													v-for="singleRow in getHoverData(row, column.dataArray)"
													v-else
													:class="{'text-nowrap': column.showCopyButton}"
												>
													<span>{{ singleRow }}</span>
													<i
														v-if="column.showCopyButton"
														class="mdi mdi-content-copy font-16 pl-2 text-primary-non-bs cursor-pointer"
														@click="copyToClipboard(row, column, singleRow)"
														@mouseover="disableClickToRedirect = true"
														@mouseleave="disableClickToRedirect = false"
													/>
												</div>

												<!--										<i-->
												<!--											v-if="column.showCopyButton"-->
												<!--											class="mdi mdi-content-copy font-16 pl-2 text-primary-non-bs cursor-pointer"-->
												<!--											@click="copyToClipboard(row, column, singleRow)"-->
												<!--											@mouseover="disableClickToRedirect = true"-->
												<!--											@mouseleave="disableClickToRedirect = false"-->
												<!--										/>-->
											</div>

											<div
												v-else
												class="d-flex"
												:class="{'text-nowrap': column.showCopyButton}"
											>
												<div v-if="getHoverData(row, column.dataArray).length <= 0">
													{{ getShowValue(row, column) }}
												</div>

												<div v-else>
													<div>
														<span> {{ getHoverData(row, column.dataArray)[0] }} </span>
														<span
															v-if="getHoverData(row, column.dataArray).length > 1"
															class="text-primary-non-bs cursor-pointer"
															@click="column.expanded = !column.expanded"
															@mouseover="disableClickToRedirect = true"
															@mouseleave="disableClickToRedirect = false"
														><br>+ {{ getHoverData(row, column.dataArray).length - 1 }} {{ column.data.toLowerCase() + (getHoverData(row, column.dataArray).length - 1 === 1 ? '' : 's') }}</span>
													</div>
												</div>
												<i
													v-if="column.showCopyButton"
													class="mdi mdi-content-copy font-16 pl-2 text-primary-non-bs cursor-pointer"
													@click="copyToClipboard(row, column)"
													@mouseover="disableClickToRedirect = true"
													@mouseleave="disableClickToRedirect = false"
												/>
											</div>
										</div>

										<div
											v-else
											class="d-flex"
											:class="{
												'text-nowrap': column.showCopyButton || column.noWrap,
												'justify-content-center': column.center,
											}"
										>
											<span v-html="getShowValue(row, column)" />
											<i
												v-if="column.showCopyButton"
												class="mdi mdi-content-copy font-16 pl-2 text-primary-non-bs cursor-pointer"
												@click="copyToClipboard(row, column)"
												@mouseover="disableClickToRedirect = true"
												@mouseleave="disableClickToRedirect = false"
											/>
										</div>
									</div>
								</slot>
							</td>
						</template>
					</tr>

					<tr v-if="! result || result.length <= 0">
						<td :colspan="columns.length">
							Sem resultados
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div v-if="totalPages >= 1 && showPagination" class="col-12 pt-3">
			<nav aria-label="Page navigation example">
				<ul v-if="screenWidth < 992" class="pagination justify-content-end">
					<li class="page-item" :class="{'disabled': page <= 0}">
						<a
							class="page-link"
							href="#"
							@click="page--"
						> <i
							style="font-size: 15px;"
							class="mdi mdi-chevron-left"
						/> </a>
					</li>

					<li class="page-item active">
						<a class="page-link" href="#"> {{ page + 1 }} </a>
					</li>

					<li class="page-item" :class="{'disabled': page >= totalPages}">
						<a
							class="page-link"
							href="#"
							@click="page++"
						> <i
							style="font-size: 15px;"
							class="mdi mdi-chevron-right"
						/> </a>
					</li>
				</ul>

				<ul v-else-if="totalPages <= 10" class="pagination justify-content-end">
					<li class="page-item" :class="{'disabled': page <= 0}">
						<a
							class="page-link"
							href="#"
							@click="page--"
						> <i
							style="font-size: 15px;"
							class="mdi mdi-chevron-left"
						/> </a>
					</li>

					<li
						v-for="(nothing, pageNumber) in Array.from({length: totalPages + 1})"
						class="page-item"
						:class="{'active': page === pageNumber}"
					>
						<a
							class="page-link"
							href="#"
							@click="page = pageNumber"
						> {{ pageNumber + 1 }} </a>
					</li>

					<li class="page-item" :class="{'disabled': page >= totalPages}">
						<a
							class="page-link"
							href="#"
							@click="page++"
						> <i
							style="font-size: 15px;"
							class="mdi mdi-chevron-right"
						/> </a>
					</li>
				</ul>

				<ul v-else-if="page <= 4" class="pagination justify-content-end">
					<li class="page-item" :class="{'disabled': page <= 0}">
						<a
							class="page-link"
							href="#"
							@click="page--"
						> <i
							style="font-size: 15px;"
							class="mdi mdi-chevron-left"
						/> </a>
					</li>

					<li
						v-for="(nothing, pageNumber) in Array.from({length: 5})"
						class="page-item"
						:class="{'active': page === pageNumber}"
					>
						<a
							class="page-link"
							href="#"
							@click="page = pageNumber"
						> {{ pageNumber + 1 }} </a>
					</li>

					<li class="page-item">
						<a class="page-link"> ... </a>
					</li>

					<li class="page-item" :class="{'active': page === totalPages}">
						<a
							class="page-link"
							href="#"
							@click="page = totalPages"
						> {{ totalPages + 1 }} </a>
					</li>

					<li class="page-item" :class="{'disabled': page >= totalPages}">
						<a
							class="page-link"
							href="#"
							@click="page++"
						> <i
							style="font-size: 15px;"
							class="mdi mdi-chevron-right"
						/> </a>
					</li>
				</ul>

				<ul v-else-if="page >= totalPages - 2" class="pagination justify-content-end">
					<li class="page-item" :class="{'disabled': page <= 0}">
						<a
							class="page-link"
							href="#"
							@click="page--"
						> <i
							style="font-size: 15px;"
							class="mdi mdi-chevron-left"
						/> </a>
					</li>

					<li class="page-item">
						<a
							class="page-link"
							href="#"
							@click="page = 0"
						> {{ 1 }} </a>
					</li>

					<li class="page-item">
						<a class="page-link"> ... </a>
					</li>

					<li class="page-item" :class="{'active': page === totalPages - 4}">
						<a
							class="page-link"
							href="#"
							@click="page = totalPages - 4"
						> {{ totalPages - 4 + 1 }} </a>
					</li>

					<li class="page-item" :class="{'active': page === totalPages - 3}">
						<a
							class="page-link"
							href="#"
							@click="page = totalPages - 3"
						> {{ totalPages - 3 + 1 }} </a>
					</li>

					<li class="page-item" :class="{'active': page === totalPages - 2}">
						<a
							class="page-link"
							href="#"
							@click="page = totalPages - 2"
						> {{ totalPages - 2 + 1 }} </a>
					</li>

					<li class="page-item" :class="{'active': page === totalPages - 1}">
						<a
							class="page-link"
							href="#"
							@click="page = totalPages - 1"
						> {{ totalPages - 1 + 1 }} </a>
					</li>

					<li class="page-item" :class="{'active': page === totalPages}">
						<a
							class="page-link"
							href="#"
							@click="page = totalPages"
						> {{ totalPages + 1 }} </a>
					</li>

					<li class="page-item" :class="{'disabled': page >= totalPages}">
						<a
							class="page-link"
							href="#"
							@click="page++"
						> <i
							style="font-size: 15px;"
							class="mdi mdi-chevron-right"
						/> </a>
					</li>
				</ul>

				<ul v-else class="pagination justify-content-end">
					<li class="page-item" :class="{'disabled': page <= 0}">
						<a
							class="page-link"
							href="#"
							@click="page--"
						> <i
							style="font-size: 15px;"
							class="mdi mdi-chevron-left"
						/> </a>
					</li>

					<li class="page-item">
						<a
							class="page-link"
							href="#"
							@click="page = 0"
						> {{ 1 }} </a>
					</li>

					<li class="page-item">
						<a class="page-link"> ... </a>
					</li>

					<li class="page-item">
						<a
							class="page-link"
							href="#"
							@click="page--"
						> {{ page }} </a>
					</li>

					<li class="page-item active">
						<a class="page-link" href="#"> {{ page + 1 }} </a>
					</li>

					<li class="page-item">
						<a
							class="page-link"
							href="#"
							@click="page++"
						> {{ page + 2 }} </a>
					</li>

					<li class="page-item">
						<a class="page-link"> ... </a>
					</li>

					<li class="page-item" :class="{'active': page === totalPages}">
						<a
							class="page-link"
							href="#"
							@click="page = totalPages"
						> {{ totalPages + 1 }} </a>
					</li>

					<li class="page-item" :class="{'disabled': page >= totalPages}">
						<a
							class="page-link"
							href="#"
							@click="page++"
						> <i
							style="font-size: 15px;"
							class="mdi mdi-chevron-right"
						/> </a>
					</li>
				</ul>
			</nav>
		</div>
	</Card>

	<div v-if="isFilterModalOpen" style="z-index: 100">
		<transition name="modal">
			<div class="modal-mask">
				<div class="row justify-content-center align-items-center">
					<div :class="`col-11 col-md-8 col-lg-6`">
						<div class="col-12 p-4 br-5 bg-white">
							<div class="modal-header pb-4">
								<div>
									<h5 class="modal-title" style="font-size: 16px;">
										Filtros de pesquisa
									</h5>
								</div>

								<a style="font-size: 20px;" class="close">
									<i class="mdi mdi-close" @click="isFilterModalOpen = ! isFilterModalOpen" />
								</a>
							</div>

							<div class="modal-body" style="max-height: 60vh; overflow-y: scroll; overflow-x: hidden;">
								<div>
									<div class="row">
										<div v-if="removesearch !== 'true'" class="col-lg-3 pb-3">
											<datatable-input
												v-model="searchInput"
												label="Pesquisa"
												name="searchInput"
												placeholder=""
											/>
										</div>

										<template v-for="filter in filters">
											<div v-if="! filter.hasOwnProperty('show') || filter.show" class="col-lg-3 pb-3">
												<template v-if="filter.type && filter.type !== 'multiselect'">
													<default-input
														v-model="filterValues[filter.name]"
														:label="filter.label"
														:name="filter.name"
														:type="filter.type"
														placeholder=""
													/>
												</template>
												<template v-else>
													<label class="form-label"> {{ filter.label }} </label>
													<search-multiselect
														v-if="filter.url"
														v-model="filterValues[filter.name]"
														:track="filter.track"
														:label="filter.multiselectLabel"
														:multiple="filter.isMultiple ?? false"
														:close-on-select="! filter.isMultiple ?? true"
														:show-labels="false"
														:url="filter.url"
														:async="! filter.internalSearch"
														:select="filter.select"
														:preselect-first="filter.preselectFirst"
														:params="filter.params ?? []"
													/>

													<multiselect
														v-else
														v-model="filterValues[filter.name]"
														:options="filter.options"
														:track-by="filter.track"
														:label="filter.multiselectLabel"
														:multiple="filter.isMultiple ?? false"
														:close-on-select="! filter.isMultiple ?? true"
														class="datatableMultiselect"
														placeholder=""
														:show-labels="false"
														:preselect-first="filter.preselectFirst"
													/>
												</template>
											</div>
										</template>
									</div>
								</div>
							</div>

							<div class="modal-footer pt-4">
								<a class="btn btn-secondary" @click="isFilterModalOpen = ! isFilterModalOpen"> Fechar </a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>

//DT COLUMNS DOCS

//Para fazer um link, tem de ser passar uma add column com o nome da coluna e "_link", o link será criado automáticamente :)
//Para fazer um delete na row, tem de ser passar uma add column com o nome de "deleteAction" e o url de delete, o link será criado automáticamente :)

//As colunas que trazem relações têm vários params que são utilizados para apresentar o conteúdo da coluna
//Deve ser passado o param "multiple" a true, para ser tratado como array

//Pode ser passado o param "onlyFirst" como true, para apresentar apenas o primeiro item da relação


import DatatableInput from "./DatatableInput.vue";
import DefaultInput from "./DefaultInput.vue";
import Multiselect from "vue-multiselect";
import Card from "./Card.vue";
import CustomSelect from "./CustomSelect.vue";
import SearchMultiselect from "./SearchMultiselect.vue";
import {floor} from "lodash";

export default {
    name: "DefaultDataTable",
    components: {CustomSelect, DatatableInput, Multiselect, Card, DefaultInput, SearchMultiselect},
    props: {
        datatype: {
            type: String,
            required: false,
            default: 'ajax',
        },

        obj: {
            type: Array,
            required: false,
            default: undefined,
        },

        url: String,

        filters: Array,

        params: {
            type: Object,
            required: false,
        },
        removesearch: {
            type: Boolean,
            required: false,
            default: false,
        },

        button: {
            type: [Object, Boolean],
            required: false,
            default: false,
        },

        search: {
            type: Boolean,
            required: false,
            default: true,
        },

        noPadding: {
            type: Boolean,
            required: false,
            default: true,
        },

        topPadding: {
            type: Boolean,
            required: false,
            default: true,
        },

        showPagination: {
            type: Boolean,
            required: false,
            default: true,
        },

        showRecordsPerPage: {
            type: Boolean,
            required: false,
            default: true,
        },

        columns: Array,

        title: {
            type: String,
            required: false,
            default: '',
        },

        forceUpdate: {
            type: Boolean,
            required: false,
            default: false,
        },

        lengthProp: {
            type: Number,
            required: false,
            default: 10,
        },
        inputSearchPlacehold: {
            type: String,
            required: false,
            default: '',
        }
    },

    emits: ['drawn'],

    data() {
        return {
            screenWidth: 100,
            isFilterModalOpen: false,

            draw: 1,
            recordsCount: 0,

            page: 0,
            searchInput: '',

            order: {
                column: 0,
                dir: 'desc',
            },

            length: this.lengthProp,

            loading: false,

            filterValues: {},

            result: [],

            showHover: [],

            disableClickToRedirect: false,
            recordsTotal: 0,
        }
    },

    computed: {
        totalPages() {
            return parseFloat(floor((this.recordsCount - 1) / this.length));
        },

        filteredColumns() {
            return this.columns.filter(column => !column.hidden);
        },
    },

    watch: {
        async searchInput() {
            await this.getResults();
            this.page = 0;
        },

        async length() {
            await this.getResults();
            this.page = 0;
        },

        filterValues: {
            async handler() {
                await this.getResults();
                this.page = 0;
            },
            deep: true
        },

        page() {
            this.getResults();
        },

        forceUpdate() {
            this.$forceUpdate();
        },
    },

    created() {
        this.columns.forEach((col, index) => {
            this.showHover[col.name] = [];

            if(col.defaultOrder) {
                this.order.column = index;
                this.order.dir = col.defaultOrder;
            }
        })

        //Se o filtro tiver um default value, preencher
        if (this.filters) {
            this.filters.forEach((filter) => {
                if (filter.defaultValue) {
                    this.filterValues[filter.name] = filter.defaultValue;
                }
            });
        }
    },

    beforeMount() {
        this.screenWidth = window.screen.width;
    },

    mounted() {
        console.log(this.removesearch);
        this.startDatatable();

        //This is one of my not so brightest moments. I'll forget how this works in the next 5 mins so I'm writing this
        //The code below will simply make a watch to the variable inside the "dynamicParams" of that filter
        //Example for places: dynamicParams: [{name: 'clients', value: 'client_id', variable: 'id'}],
        if (this.filters) {
            this.filters.forEach((filter) => {
                if(filter.dynamicParams){
                    filter.params = {};
                    filter.dynamicParams.forEach((param) => {
                        this.$watch('filterValues.' + param.name, (newVal, oldVal) => {
                            let mainFilter = filter
                            try{
                                if(param.variable){
                                    mainFilter.params[param.value] = this.filterValues[param.name][param.variable];
                                } else {
                                    mainFilter.params[param.value] = this.filterValues[param.name];
                                }
                            } catch (e) {
                                mainFilter.params[param.value] = null;
                            }

                            this.$refs['searchMultiselect_' + filter.name][0].search();
                        })
                    })

                }
            });
        }

    },

    methods: {
        startDatatable() {
            this.getResults();
        },

        async getResults() {
            this.loading = true;

            if (this.datatype === 'ajax' && this.url) {
                let form = {
                    draw: this.draw,
                    start: this.length * (this.page),
                    length: this.length,
                    columns: this.columns,
                    order: [this.order],
                    search: {
                        value: this.searchInput,
                        regex: false,
                    },
                }


                return axios.get(this.url + '?' + jQuery.param(form) + '&' + jQuery.param(this.params) + '&' + jQuery.param(this.filterValues)).then((response) => {
                    this.draw++;

                    this.result = response.data.data;
                    this.recordsCount = response.data.recordsFiltered;

                    this.loading = false;

                    this.recordsTotal = response.data.recordsTotal;

                    this.$emit('drawn', this.result);
                });

            } else if (this.datatype === 'object' && this.obj) {
                this.result = this.obj.filter((e) => {
                    let test = false;
                    this.columns.forEach((column) => {
                        if (test === true) {
                            return;
                        }
                        test = column.searchable && e[column.name].toString().toLowerCase().includes(this.searchInput.toLowerCase());
                    });
                    return test;
                });

                this.loading = false;
            }
        },

        async deleteAction(url) {
            let ask = await this.confirmDelete('Tem a certeza?');
            if (ask) {
                return axios.delete(url).then(() => {
                    this.getResults();
                });
            }
        },

        orderTable(index) {
            if (this.columns[index].orderable) {
                if (this.order.column === index) {
                    this.order.dir = this.order.dir === 'desc' ? 'asc' : 'desc';
                } else {
                    this.order.column = index;
                    this.order.dir = 'desc';
                }

                this.getResults();
            }
        },

        getShowValue(row, column) {
            //Vamos buscar o caminho ate ao valor
            var relations = column.name.split(".");
            var value = row;

            //O ultimo valor e o nome da coluna
            if (relations.length > 1) {
                var columnName = relations.pop();
                relations.forEach((relation) => {
                    if (!value) {
                        value = 'N/D';
                        return;
                    }
                    value = value[relation];
                });
                if (value === 'N/D') {
                    return value;
                }
            } else {
                var columnName = column.name;
            }

            if (column.multiple) {
                if (column.onlyFirst) {
                    value = value[0][columnName];
                } else {
                    if(column.multipleBreakLine) {
                        value = value.map((e) => {
                            return e[columnName];
                        }).join("<br>");
                    }  else {
                        value = value.map((e) => {
                            return e[columnName];
                        }).join(", ");
                    }
                }
            } else {
                if (!value) {
                    return 'N/D';
                }
                value = value[columnName];
            }

            if (column.date) {
                value = momentJS(value).format('DD-MM-YYYY HH:mm');
            }

            if (column.render) {
                value = column.render(value);
            }

            if (!value || (typeof value === 'string' && value.trim() === '')) {
                if(column.defaultContent){
                    value = column.defaultContent;
                } else {
                    value = 'N/D';
                }
            }

            return value;
        },

        getHoverData(row, data){
            var relations = data.split(".");
            var value = row;

            if (relations.length > 1) {
                var columnName = relations.pop();
                relations.forEach((relation) => {
                    if (!value) {
                        value = 'N/D';
                        return;
                    }
                    value = value[relation];
                });
                if (value === 'N/D') {
                    return value;
                }
            } else {
                var columnName = data;
            }

            if (!value) {
                return 'N/D';
            }
            value = value[columnName];

            return value;
        },
        copyToClipboard(row, column, override = null){
            let content = 'owo';
            if(override){
                content = override
            } else {
                content = column['copyData'] ? this.getHoverData(row, column['copyData']) : this.getShowValue(row, column)
            }

            const el = document.createElement('textarea');
            el.value = content;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            toastSuccess('Copiado para a área de transferência');
        },

        redirectTo(url) {
            if(! this.disableClickToRedirect && url){
                this.$inertia.visit(url);
            }
        },
    }
}
</script>

<style scoped>
.form-label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
}
</style>
