<template>
	<PageTitle title="Concursos" />
	<DefaultDataTable
		ref="table"
		:columns="columns"
		:url="route('admin.contest.search')"
		:filters="filters"
		:length-prop="25"
	>
		<template #button>
			<div class="d-flex">
				<Link
					v-if="$page.props.auth.isSuperAdmin"
					class="
					btn
					btn-outline-primary
					d-flex
					align-items-center"
					:href="route('contests.create')"
				>
					<i style="font-size: 20px; line-height: 1px;" class="mdi mdi-plus pe-1" />
					Novo sorteio
				</Link>
			</div>
		</template>
	</DefaultDataTable>
</template>

<script>
import Layout from "../../Shared/Layout.vue";

export default {
    layout: Layout,
    methods: {

    }
}
</script>
<script setup>
import DefaultDataTable from "../../Components/DefaultDataTable.vue";
import PageTitle from "../../Components/etc/PageTitle.vue";

defineProps({
    filters: {
        type: Object,
        default: () => ({})
    }
});

const columns = [
    { data: 'ID', dataTitle: 'ID', name: 'id', searchable: true, orderable: true },
    { data: 'Nome', dataTitle: 'Nome', name: 'name', searchable: true, orderable: true, link: 'admin.contest.show'},
    { data: 'Tipo', dataTitle: 'Tipo', name: 'rule', searchable: true, orderable: true },
    { data: 'Início - Fim', dataTitle: 'Início - Fim', name: 'dates', searchable: true, orderable: true },
    { data: 'Estado', dataTitle: 'Estado', name: 'status', searchable: true, orderable: true },
    { data: 'Data do sorteio', dataTitle: 'Data do sorteio', name: 'ran_date', searchable: true, orderable: true },

]
//define layout
</script>

<style scoped>

</style>
