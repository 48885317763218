<template>
	<div>
		<div class="col-12 col-lg-4 mb-4">
			<free-input
				label="Enviar para"
			>
				<multiselect
					v-model="usersSelected"
					:options="users"
					label="name"
					track-by="id"
					placeholder=""
					class="datatableMultiselect"
				/>
			</free-input>
		</div>

		<div class="col-12 col-lg-4 mb-4">
			<free-input
				label="Icone"
			>
				<multiselect
					v-model="icon"
					:options="[{
						id: 'info',
						name: 'Info',
						icon: 'mdi mdi-information-outline',
						color: 'text-primary'
					}, {
						id: 'success',
						name: 'Success',
						icon: 'mdi mdi-check-circle-outline',
						color: 'text-success'
					}, {
						id: 'warning',
						name: 'Warning',
						icon: 'mdi mdi-alert-outline',
						color: 'text-warning'
					}, {
						id: 'error',
						name: 'Error',
						icon: 'mdi mdi-alert-circle-outline',
						color: 'text-danger'
					}]"
					label="name"
					track-by="id"
					placeholder=""
					class="datatableMultiselect"
                    :show-labels="false"
				>
					<template #option="{ option }">
						<i :class="`mdi ${option.icon} ${option.color}`"></i> {{ option.name }}
					</template>

                    <template #singleLabel="{ option }">
                        <i :class="`mdi ${option.icon} ${option.color}`"></i> {{ option.name }}
                    </template>
				</multiselect>
			</free-input>
		</div>

		<div class="col-12 col-lg-4 mb-4">
			<default-input
				v-model="title"
				label="Título"
				type="text"
			/>
		</div>

		<div class="col-12 col-lg-4 mb-4">
			<default-input
				v-model="body"
				label="Conteúdo"
				type="textarea"
			/>
		</div>

		<button
			class="btn btn-primary"
			type="button"
			@click="save"
		>
			Submeter
		</button>
	</div>
</template>

<script setup>
import Multiselect from "vue-multiselect";
import {ref} from "vue";
import FreeInput from "../../Components/FreeInput.vue";
import DefaultInput from "../../Components/DefaultInput.vue";

const props = defineProps({
    users: {
        type: Array,
        required: true,
    },
})

const usersSelected = ref([])
const title = ref('')
const body = ref('')
const icon = ref([])

const save = () => {
    if (usersSelected.value.length === 0) {
        toast.error('Selecione pelo menos um utilizador')
        return
    }

    if (title.value === '') {
        toast.error('Preencha o título')
        return
    }

    if (body.value === '') {
        toast.error('Preencha o conteúdo')
        return
    }

    if (! icon || icon.value.length === 0) {
        toast.error('Selecione um ícone')
        return
    }

    axios.post(route('notifications.store'), {
        users: usersSelected.value,
        title: title.value,
        message: body.value,
        icon: icon.value,
    })
        .then(response => {
            toast.success('Notificação enviada com sucesso')
            window.location.href = route('notifications.index')
        })
        .catch(error => {
            console.log(error)
        })


}
</script>

<script>
import Layout from "../../Shared/Layout.vue";

export default {
    layout: Layout,
}
</script>
<style scoped>

</style>
