<script>
import {defineComponent} from 'vue'
import { Head } from "@inertiajs/vue3";

export default defineComponent({
    name: "PageTitle",
    components: {
        MetaHead: Head,
    },
    props: {
        title: {
            type: String,
            required: true,
        },

        subTitle: {
            type: String,
            required: false,
        },

        padding: {
            type: String,
            required: false,
            default: 'pb-4'
        }
    }
})
</script>

<template>
	<MetaHead :title="title" />

	<div class="row">
		<div :class="`col-12 ${padding}`">
			<span class="pageTitle"> <i onclick="history.back()" class="cursor-pointer mdi mdi-chevron-double-left" /> {{ title }}</span>
			<span
				v-if="subTitle"
				style="font-weight: 600; font-size: 1rem; color: #A5A6AA;"
				v-html="subTitle"
			/>
		</div>
	</div>
</template>

<style scoped>

</style>
