<template>
	<div class="form-group">
		<label v-if="label" class="form-label"> {{ label }} </label>
		<slot />
		<small v-if="error" class="form-control-feedback text-danger"> <i class="mdi mdi-alert-circle" /> {{ error }} </small>
	</div>
</template>

<script>
export default {
    name: "FreeInput",
    props:{
        label: String,

        error:{
            type: String,
            required: false,
            default: undefined
        }
    },
}
</script>

<style scoped>
    .form-label {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 500;
    }
</style>
