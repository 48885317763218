<template>
	<form @submit.prevent="submit">
		<div v-if="state !=='sucesso'" class="form-title">
			Participar
		</div>
		<div v-if="state ==='sucesso'" class="form-title">
			Consulta o teu email
		</div>

		<login-input
			v-if="state !== 'sucesso'"
			v-model="loginForm.email"
			label="Email"
			name="email"
			placeholder="email@email.com"
			:error="errors.email"
			type="email"
		/>

		<div
			v-if="state === 'unsent'"
			class="form-helper fw-bold mt-4"
			role="alert"
		>
			Vai ser enviado um email com um link que te irá permitir enviar e aceder às tuas participações.
		</div>
		<div
			v-if="state === 'sucesso'"
			class="form-helper big-icon-wrapper fw-bold mt-4 justify-content-center d-flex"
			role="alert"
		>
			<i class="mdi mdi-email-fast-outline" />
		</div>
		<div v-if="state ==='sucesso'" style="font-size: 1.3rem; text-align: center;">
			{{ loginForm.email }}
		</div>
		<div
			v-if="state === 'sucesso'"
			class="form-helper fw-bold mt-4"
			role="alert"
		>
			Acabamos de te enviar um email com um link. Clica no link para enviar e consultar o estado das tuas participações.
		</div>
		<div
			v-if="state === 'sucesso'"
			class="form-helper fw-bold mt-1"
			role="alert"
		>
			Por favor, verifica a tua caixa de entrada.
		</div>
		<div
			v-if="state === 'sucesso'"
			class="form-helper fw-bold mt-1 mb-4"
			role="alert"
		>
			Se não encontrares o email, confere também a tua pasta de SPAM.
		</div>
		<div
			v-if="state === 'failed'"
			class="form-helper fw-bold mt-4"
			role="alert"
		>
			<template v-if="errors.hasOwnProperty('cfResponse')">
				{{ errors.cfResponse }}
			</template>
			<template v-else>
				Algo deu errado. Por favor contacte o suporte.
			</template>
		</div>

		<template v-if="state !== 'sucesso'">
			<p class="mt-2 mb-0">
				<a class="form-check-label text-decoration-underline" :href="route('regulamento')">Regulamento da campanha</a>
				<span class="form-check-label mx-2">/</span>
				<a class="form-check-label text-decoration-underline" :href="route('politica-privacidade')">Política de privacidade</a>
			</p>
			<div class="col-12 pt-3">
				<div class="form-check">
					<input
						id="flexCheckTermsDefault"
						v-model="loginForm.terms"
						class="form-check-input"
						type="checkbox"
						value="true"
						required
					>
					<label class="form-check-label" for="flexCheckTermsDefault">
						Li e aceito o regulamento da campanha *
					</label>
				</div>
			</div>
			<div class="col-12 pt-3">
				<div class="form-check ">
					<input
						id="flexCheckPrivacyDefault"
						v-model="loginForm.privacy"
						class="form-check-input"
						type="checkbox"
						value="true"
						required
					>
					<label class="form-check-label" for="flexCheckPrivacyDefault">
						Li e aceito a política de privacidade *
					</label>
				</div>
			</div>
			<div class="col-12 pt-3">
				<div class="form-check">
					<input
						id="flexCheckPriocommsDefault"
						v-model="loginForm.priocomms"
						class="form-check-input"
						type="checkbox"
						value="true"
					>
					<label class="form-check-label" for="flexCheckPriocommsDefault">
						Aceito receber comunicações de marketing da PRIO
					</label>
				</div>
			</div>

			<template v-if="turnstileEnabled">
				<turnstile
					v-model="loginForm.cfResponse"
					:site-key="siteKey"
					appearance="interaction-only"
					:placeholder="false"
				/>
			</template>

			<div class="col-12 pt-4">
				<button
					type="submit"
					:disabled="loginForm.processing"
					class="btn btn-primary w-100 fw-semibold default-box-prio-radius"
				>
					Clique para receber o email de login
				</button>
			</div>
		</template>
	</form>
</template>

<script>
    import LoginLayout from "../../Shared/LoginLayout.vue";
    import LoginInput from "../../Components/LoginInput.vue";
    import { useForm } from '@inertiajs/vue3';
    import Turnstile from "@any-design/turnstile";
    import {ref} from "vue";

    const SITE_KEY = import.meta.env.VITE_TURNSTILE_SITE_KEY;
    const TURNSTILE_ENABLED = import.meta.env.VITE_TURNSTILE_ENABLED === 'true';

    export default {
        name: "Login",

        components: {
            Turnstile,
            'login-input': LoginInput
        },
        layout: LoginLayout,
        props:{
            errors: Object,
        },

        setup(){
            const loginForm = useForm({
                email: '',
                password:'dodo',
                terms: false,
                privacy: false,
                priocomms: false,
                cfResponse: null,
            });

            const siteKey = ref(SITE_KEY);
            const turnstileEnabled = ref(TURNSTILE_ENABLED);

            return {loginForm, siteKey, turnstileEnabled}
        },

        data(){
            return {
                state:'unsent',
            }
        },

        mounted() {

        },

        methods:{
            submit(){
                this.loginForm.post(route('email-login-request'),{
                    preserveState: true,
                    onSuccess: () => {
                        //window.location.reload()
                        this.state = 'sucesso';
                        toastSuccess('Email enviado com sucesso.');
                    },
                    onError: (e) => {
                        this.pErrors = e;
                        this.state = 'failed';
                    },
                    onFinish: () => {
                        this.$refs.submitButton?.toggleLoading(false)
                    }
                })
            },
        }
    }
</script>
