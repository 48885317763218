<template>
	<MetaHead title="Participa no concurso" />
	<div class="col-12   py-md-4 px-md-4 ">
		<!--        <div class="col-12 background-primary-soft p-1 py-md-4 px-md-4 ">-->
		<div class="row">
			<div class="col-12  text-center">
				<p class="fw-bold colorFontPrimary">
					Participa no concurso
				</p>
				<p v-if="statusClass !=='confirm'" class="post-pagetitle px-3 pb-1">
					Insere o código e o número da fatura que recebeste no posto PRIO
				</p>
				<p v-else-if="statusClass ==='confirm'" class="post-pagetitle post-pagetitle-confirm px-3 pb-1">
					Confirma que os dados inseridos estão corretos?
				</p>
			</div>
			<div class="col-12 d-flex  align-items-center justify-content-center">
				<div class="col-12 col-lg-4 default-box-prio-primary default-box-prio-radius" :class="statusClass">
					<form @submit.prevent="submit">
						<div class="col-12 pt-3 otp-input-wrapper otp-input-wrapper-code">
							<label for="code" class="form-label fw-bold">Código de Participação</label>
							<!--                @on-change="handleOnChange"
                                @on-complete="handleOnComplete"-->
							<div class="justify-content-center d-flex">
								<div class="v-otp-input-group col-auto justify-content-evenly d-flex px-1">
									<div class="row" @click="forceStartInput('otpInput1')">
										<v-otp-input
											ref="otpInput1"
											key="otpInput1"
											v-model:value="activationForm.code"
											name="otpInput1"
											input-classes="otp-input"
											separator=""
											:num-inputs="13"
											input-type="number"
											inputmode="numeric"
											:should-auto-focus="true"
											:conditional-class="['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen']"
											:placeholder="['_', '_', '_', '_', '_' , '_' , '_' , '_', '_' , '_' , '_' , '_' , '_' ]"
											:error="errors.code"
											@on-complete="handleOnCompleteParticipationCode"
										/>
									</div>
								</div>
							</div>
							<span v-if="modifiedErrors && modifiedErrors.code" class="text-danger d-block pt-2">
								<i class="mdi mdi-alert-circle" />
								{{ modifiedErrors.code }}
							</span>
						</div>
						<div class="col-12 pt-3 otp-input-wrapper">
							<label for="invoice_number" class="form-label fw-bold">Número da fatura</label>
							<div class="row justify-content-center">
								<div class="v-otp-input-group col-auto justify-content-evenly d-flex px-1">
									<div class="row" @click="forceStartInput('otpInput2')">
										<v-otp-input
											ref="otpInput2"
											key="otpInput2"
											v-model:value="activationForm.invoice_number9digits"
											name="otpInput2"
											input-classes="otp-input"
											input-type="number"
											inputmode="numeric"
											separator=""
											:should-auto-focus="false"
											:num-inputs="9"
											:conditional-class="['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine']"
											:placeholder="['_', '_', '_', '_', '_' , '_' , '_' , '_', '_']"
											@on-complete="handleOnCompleteInvoiceFirstPart"
										/>
									</div>
								</div>
								<div class="col-auto v-otp-input-group-separator ">
									-
								</div>
								<div class="v-otp-input-group col-auto justify-content-evenly d-flex px-1">
									<div class="row" @click="forceStartInput('otpInput3')">
										<v-otp-input
											ref="otpInput3"
											key="otpInput3"
											v-model:value="activationForm.invoice_number6digits"
											name="otpInput3"
											input-classes="otp-input"
											input-type="number"
											inputmode="numeric"
											separator=""
											:num-inputs="6"
											:should-auto-focus="false"
											:conditional-class="['one', 'two', 'three', 'four', 'five', 'six']"
											:placeholder="['_', '_', '_', '_', '_' , '_' ]"
										/>
									</div>
								</div>
							</div>
							<span
								v-if="modifiedErrors && modifiedErrors.invoice_number"
								class="text-danger d-block pt-2"
							> <i
								class="mdi mdi-alert-circle"
							/> {{ modifiedErrors.invoice_number }} </span>
						</div>
						<div class="col-12 px-3 pb-3 pt-3 mb-3 mt-3">
							<template v-if="turnstileEnabled">
								<turnstile
									v-model="activationForm.cfResponse"
									:site-key="siteKey"
									appearance="interaction-only"
									:placeholder="false"
								/>
							</template>
							<button
								v-if="statusClass==='default'"
								class="btn btn-primary col-12 col-md-12 fw-bold  halfrounded-corners-left"
								@click="changeStatus('confirm')"
							>
								Submeter participação
							</button>
							<SubmitButton
								v-if="statusClass==='confirm'"
								ref="submitButton"
								class="btn btn-confirm  col-12 col-md-12 halfrounded-corners-left"
								label="Sim, confirmo!"
							/>

							<button
								v-if="statusClass==='confirm'"
								class="btn btn-back col-12 col-md-12 mt-4 halfrounded-corners-left"
								@click="changeStatus('default')"
							>
								Não! Quero alterar
							</button>
							<button
								v-if="statusClass==='invalid'"
								class="btn btn-back col-12 col-md-12 halfrounded-corners-left"
								@click="changeStatus('default')"
							>
								Ups! Quero alterar
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import SubmitButton from "../../Components/SubmitButton.vue";
import VOtpInput from "vue3-otp-input";
import {Head as MetaHead, router, useForm} from '@inertiajs/vue3';
import Turnstile from "@any-design/turnstile";
import {defineProps, inject, ref} from "vue";
import {DateTime} from "luxon";

const SITE_KEY = import.meta.env.VITE_TURNSTILE_SITE_KEY;
const TURNSTILE_ENABLED = import.meta.env.VITE_TURNSTILE_ENABLED === 'true';

defineProps({
    errors: {
        type: Object,
        default: () => ({})
    },
})

const route = inject('$route');

const activationForm = useForm({
    code: '',
    invoice_number: '',
    invoice_number9digits: '',
    invoice_number6digits: '',
    cfResponse: null,
});

const siteKey = ref(SITE_KEY);
const turnstileEnabled = ref(TURNSTILE_ENABLED);

const otpInput1 = ref(null);
const otpInput2 = ref(null);
const otpInput3 = ref(null);
const submitButton = ref(null);
const statusClass = ref('default');
const modifiedErrors = ref({});
const successRedirect = ref(route('contests.myParticipations'));

const handleOnCompleteParticipationCode = () => {
    console.debug('handleOnCompleteParticipationCode')
    if (activationForm.code.length === 13) {
        otpInput2.value?.focusInput(1);
        //uma marretada para ir ao início do campo
        //se passar diretamente o focusInput(0) não funciona
        //se passar o focusInput(1) funciona mas não fica como deve ser
        setTimeout(() => {
            forceStartInput('otpInput2')
        }, 10);
    }
}

const handleOnCompleteInvoiceFirstPart = () => {
    console.debug('handleOnCompleteInvoiceFirstPart')
    if (activationForm.invoice_number9digits.length === 9) {
        otpInput3.value?.focusInput(1);
        setTimeout(() => {
            forceStartInput('otpInput3')
        }, 10);
    }
}

const forceStartInput = (otp) => {
    if (otp === 'otpInput1') {
        if (activationForm.code.length === 0) {
            otpInput1.value?.clearInput();
        } else {
            otpInput1.value?.focusInput(activationForm.code.length);
        }

        return;
    }

    if (otp === 'otpInput2') {
        if (activationForm.invoice_number9digits.length === 0) {
            otpInput2.value?.clearInput();
        } else {
            otpInput2.value?.focusInput(activationForm.invoice_number9digits.length);
        }

        return;
    }

    if (otp === 'otpInput3') {
        if (activationForm.invoice_number6digits.length === 0) {
            otpInput3.value?.clearInput();
        } else {
            otpInput3.value?.focusInput(activationForm.invoice_number6digits.length);
        }
    }
}

const changeStatus = (value) => {
    statusClass.value = value
}

const submit = () => {
    activationForm.invoice_number = activationForm.invoice_number9digits + '/' + activationForm.invoice_number6digits;

    submitButton.value?.toggleLoading(true)

    activationForm.post(route('contests.participation.store'), {
        preserveState: true,
        onSuccess: () => {
            setTimeout(() => {
                window.location.href = successRedirect.value;
            }, 1000);
        },
        onError: (e) => {
            submitButton.value?.toggleLoading(false)
            statusClass.value = 'invalid'
            modifiedErrors.value = {...e}
            console.log('no');
        },
    })
}


const currentDate = DateTime.now();

if (currentDate > DateTime.fromISO('2024-02-02T00:00:00.000Z')) {
    router.replace(route('contests.fineshed'));
}

</script>

<script>
import ContestentsLayout from "../../Shared/ContestentsLayout.vue";

export default {
    name: "ActivateCode",
    layout: ContestentsLayout,
}
</script>
<style>

.otp-input-wrapper {
    text-align: center;
    font-weight: 600;
}

.otp-input-wrapper-code .v-otp-input-group {
    width: 250px;
}

.otp-input {
    width: 16px !important;
    height: 50px;
    padding: 2px;
    margin: 0 0px;
    font-size: 20px;
    border-radius: 0px;
    border: 0px solid rgba(0, 0, 0, 0.1);
    text-align: center;
}

.v-otp-input-group-separator {
    width: 12px !important;
    height: 50px;
    font-size: 30px;
    padding-left: 6px !important;
    padding-right: 13px !important;
}

.otp-input:focus {
    border: 0px solid rgba(0, 0, 0, 0.1);
    outline: none;
    box-shadow: none;

}

.v-otp-input-group {
    /*  border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      background-color: #ffffff;*/
}

/* Background colour of an input field with value */
.otp-input.is-complete {
    background-color: rgba(13, 17, 22, 0);
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.otp-input-wrapper > div {
    justify-content: space-evenly;
}

input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
}
</style>
