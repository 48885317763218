<template>

</template>

<script>
import Layout from "../Shared/Layout.vue";
export default {
    layout: Layout,
    name: "Welcome",
    data() {
        return {

        };
    },

    mounted() {

    }
};

</script>
