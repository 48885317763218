<template>
    <div class="selectDiv" @click="openDropdown = !openDropdown">
        {{ modelValue }} <i class="mdi mdi-chevron-down"></i>

        <div class="selectDivDropdown" v-show="openDropdown">
            <div class="selectDivDropdownItem" v-for="option in options" @click="updateValue(option)">
                {{ option }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomSelect",
    props:{
        options: Array,
        modelValue: undefined,
    },

    data(){
        return {
            openDropdown: false,
        }
    },

    methods:{
        updateValue(value) {
            this.$emit('update:modelValue', value);
        },
    }
}
</script>

<style scoped>
    .selectDiv{
        background-color: #F7F7F7;
        padding: 7px 15px;
        border-radius: 5px;
        font-weight: 500;
        position: relative;
        cursor: pointer;
    }

    .selectDivDropdown{
        position: absolute;
        top: 100%;
        left: 0;
    }

    .selectDivDropdownItem{
        background-color: #F7F7F7;
        padding: 7px 15px;
        cursor: pointer;
    }
</style>
