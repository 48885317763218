<template>
	<PageTitle title="Concorrentes pro" />

	<DefaultDataTable
		ref="table"
		:columns="columns"
		:url="route('professionalcontestents.search')"
		:filters="filters"
		:length-prop="25"
	>
		<template #button>
			<div class="d-flex" />
		</template>
	</DefaultDataTable>
</template>
<!--		-->
<script>
import Layout from "../../Shared/Layout.vue";
import {DateTime} from "luxon";

export default {
    layout: Layout,
    props: {
        statuses: {
            type: Array,
            default: () => []
        },
        sorteios: {
            type: Object,
            default: () => []
        },
        filters: {
            type: Object,
            default: () => ({})
        }

    },
    mounted(){
        this.filters[0].options = this.statuses;
        this.filters[1].options = this.sorteios;
    },
    data() {
        return {
            columns: [
                { data: 'ID', dataTitle: 'ID', name: 'id', searchable: true, orderable: true },
                { data: 'Participante', dataTitle: 'Participante', name: 'client_id', searchable: false, orderable: false, html:true, link:'main_link'},
                { data: 'Nome', dataTitle: 'Nome', name: 'client_name', searchable: true, orderable: true},
                { data: 'Email', dataTitle: 'Email', name: 'email', searchable: true, orderable: true},
                { data: 'Data do carregamento', dataTitle: 'Data do Registo', name: 'created_at', searchable: true, orderable: true, render: (data) => {
                        return data ? DateTime.fromISO(data).toFormat('d/MM/y HH:mm') : '';
                    }
                },

            ],
            filters: [
                {
                    label: 'Estados',
                    name: 'tags',
                    options: [],
                    internalSearch: false,
                    isMultiple: true,
                    wrapperClass: 'col-12 col-md-3 col-lg-4',
                },
                {
                    label: 'Sorteios',
                    name: 'sorteio',
                    track: 'id',
                    multiselectLabel: 'contest_type',
                    options: [],
                    internalSearch: false,
                    isMultiple: false,
                    /*dynamicParams: {
                        name: 'name',
                        value: 'id'
                    },*/
                    wrapperClass: 'col-12 col-md-3 col-lg-4',
                }
            ]

        }
    },
    computed: {


    },
    methods: {

    },

}
</script>
<script setup>
import DefaultDataTable from "../../Components/DefaultDataTable.vue";
import PageTitle from "../../Components/etc/PageTitle.vue";
import {DateTime} from "luxon";



//define layout
</script>

<style scoped>

</style>
