<template>
	<!--    <div v-if="validation" class="col-12 align-items-center text-center">
        {{ validation }}
    </div>-->
	<div class="row">
		<span v-if="errorToShow" class="text-danger font-weight-500 d-block pt-4">
			<i class="text-danger mdi mdi-alert-circle-outline" />
			{{ errorToShow }}
		</span>
		<label
			class="col-12 insert-image-new upload-each-row p-2 "
			:class="[validation, {Recusado: proxyFailed}]"
			@click="handleClick"
		>
			<div class="row">
				<div class="col-12 d-flex align-items-center">
					<div class="col-1">
						<template v-if="validation ==='Recusado'">
							<i class="mdi mdi-shield-alert-outline font-24" />
						</template>
						<template v-else-if="validation ==='Por validar'">
							<i class="mdi mdi-text-box-search-outline font-24" />
						</template>
						<template v-else-if="validation ==='Aprovado'">
							<i class="mdi mdi-image-edit-outline font-24" />
						</template>
						<template v-else-if="validation ==='Desqualificadp'">
							<i class="mdi mdi-shield-remove-outline font-24" />
						</template>
						<template v-else>
							<i class="mdi mdi-image-edit-outline font-24" />
						</template>

					</div>

					<div v-if="newFileName" class="col-6 insert-image-new-label align-items-center text-center">
						{{ newFileName }}
						<template v-if="hammerRequired === false" />
						<template v-else>*</template>
					</div>
					<div
						v-else-if="modelValue.filename"
						class="col-6 insert-image-new-label align-items-center text-center"
					>
						{{ modelValue.filename }}
						<template v-if="hammerRequired === false" />
						<template v-else>*</template>
					</div>
					<div v-else-if="label" class="col-6 insert-image-new-label align-items-center text-center">
						{{ label }}
						<template v-if="hammerRequired === false" />
						<template v-else>*</template>
					</div>
					<template v-if="validation === 'Validado'">
						<div class="col-5 upload-fake-btn validado">
							<i class="mdi mdi-check text-success pr-2" />
							<span class="">Validado</span>
						</div>
					</template>
					<template v-else>
						<div class="col-5 upload-fake-btn">
							Upload
						</div>
					</template>

					<!--                <div class="position-relative ">
                    <img
                        alt="Imagem"
                        :src="getImageToShow"
                        class="rounded-pill profile-image"
                        style="width: 115px; height: 115px;"



                    >
                    <div class="profilePicEditButton position-absolute cursor-pointer" >
                        <i class="mdi mdi-image-edit-outline" />
                    </div>

                </div>-->
					<div>
						<span
							v-if="showTopLine"
							class="d-block font-24 font-weight-bold color-272727"
						>
							{{ topLine ?? 'Nome' }}
						</span>
						<span v-if="showBottomLine" class="d-block font-16 color-2E363D">
							<span class="font-weight-500">
								{{ bottomLineLabel }}:
							</span>
							{{ bottomLineValue }}
						</span>
					</div>
				</div>
			</div>
		</label>

		<input
			v-if="hammerRequired === false"
			id="pictureModalInputFile"
			ref="pictureModalInput"
			class="hidden  col-12 88"
			style="opacity: 0; display: none;"
			type="file"
			name="image"
			:accept="accept"
			:required="hammerRequired"
			@input="validateFile($event.target.files[0])"
		>
		<input
			v-else
			id="pictureModalInputFile"
			ref="pictureModalInput"
			class="hidden fake-hide d-flex col-12 8"
			style=""
			type="file"
			name="image"
			:accept="accept"
			:required="hammerRequired"
			@input="validateFile($event.target.files[0])"
		>
	</div>
</template>

<script>

export default {
    name: "InsertImage",

    props: {
        modelValue: undefined,

        fileName: {
            type: String,
            required: false,
            default: null,
        },
        validation: {
            type: String,
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        defaultIcon: {
            type: String,
            required: false,
            default: null,
        },
        topLine: {
            type: String,
            required: false,
            default: null,
        },
        showTopLine: {
            type: Boolean,
            default: true,
            required: false,
        },
        bottomLineLabel: {
            type: String,
            required: false,
            default: null,
        },
        bottomLineValue: {
            type: String,
            required: false,
            default: null,
        },
        showBottomLine: {
            type: Boolean,
            default: true,
            required: false,
        },
        required: {
            type: String,
            default: 'false',
            required: false,
        },
        accept: {
            type: String,
            default: 'image/*',
            required: false,
        },
        failed: {
            type: Boolean,
            default: false,
            required: false,
        }
    },

    emits: [
        'imageUploaded',
        'update:modelValue',
        'update:failed',
    ],

    data() {
        return {
            errorToShow: null,
            newFileName: null,
            hammerRequired: null,
        }
    },
    computed: {
        getImageToShow() {
            if (this.modelValue?.imageChanged) {
                return URL.createObjectURL(this.modelValue.image);
            } else if (this.modelValue?.image) {
                return this.modelValue.image;
            }
            if (this.defaultIcon) {
                return this.asset(this.defaultIcon);
            }

            return this.asset('images/userPlaceholder.jpg');
        },
        proxyFailed: {
            get() {
                return this.failed;
            },
            set(value) {
                this.$emit('update:failed', value);
            }
        }
    },
    mounted() {
        /*console.log(this.modelValue);*/
        this.checkIfRequired();
    },

    methods: {
        checkIfRequired() {
            if (this.required === 'true') {
                if (this.validation === 'Por validar' || this.validation === 'Recusado') {
                    this.hammerRequired = false;
                } else {
                    this.hammerRequired = true;
                }

            } else {
                this.hammerRequired = false;
            }
        },
        uploadImage(blob) {

            /*const blob = new Blob([file], { type: file.type });*/
            this.$emit('update:modelValue', {
                image: blob,
                imageChanged: true,
            })

            this.$emit('imageUploaded', blob);
        },

        validateFile(file) {
            console.log(file)

            if (!file) {
                this.errorToShow = 'Ficheiro inválido';
                this.proxyFailed = true;
                return false;
            }

            let isValid = this.accept.split(',').map((type) => {
                let regex = new RegExp(type.replace(/\*/g, '.*'));
                return regex.test(file.type);
            }).some((type) => type === true);

            if (!isValid) {
                this.errorToShow = 'Tipo de documento não suportado';
                this.proxyFailed = true;
                return false;
            }

            if (file.size > 5000000) {
                this.errorToShow = 'Ficheiro muito grande';
                this.proxyFailed = true;
                return false;
            }

            if (file.name) {
                this.newFileName = file.name;
            }

            this.uploadImage(file);
        },
        handleClick(){
            if (this.validation === 'Validado') {
                return;
            }

            this.$refs.pictureModalInput.click()
        }
    }
}
</script>

<style scoped>
.profilePicEditButton {
    position: absolute;
    bottom: 5px;
    right: 0;
    font-size: 18px;
    background-color: #333;
    height: 30px;
    width: 30px;
    text-align: center;
    border-radius: 50%;
    color: white;
}

.profile-image {
    object-fit: cover;
    object-position: center;
    transition: filter 0.3s;
}

.profile-image:hover {
    cursor: pointer;
    filter: brightness(0.8);
}

.upload-fake-btn.validado {
    background-color: #008EAA;
    color: #fff;
    font-size: 13px;
}

.insert-image-new.upload-each-row.failed {
    border: 1px solid #D95151;
    background-color: #D95151;
}
</style>
