<template>
	<PageTitle title="Utilizadores" />

	<DefaultDataTable
		ref="table"
		:columns="columns"
		:url="route('users.search')"
        :filters="filters"
		:length-prop="25"
	>
		<template #button>
			<div class="d-flex">
				<Link
					class="btn btn-outline-primary d-flex align-items-center"
					:href="route('users.create')"
				>
					<i style="font-size: 20px; line-height: 1px;" class="mdi mdi-plus pe-1" />
					Novo Utilizador
				</Link>
			</div>
		</template>
	</DefaultDataTable>
</template>

<script>
import Layout from "../../Shared/Layout.vue";

export default {
    layout: Layout,
    methods: {

    }
}
</script>
<script setup>
import DefaultDataTable from "../../Components/DefaultDataTable.vue";
import PageTitle from "../../Components/etc/PageTitle.vue";

defineProps({
    filters: {
        type: Object,
        default: () => ({})
    }
});

const columns = [
    { data: 'ID', dataTitle: 'ID', name: 'id', searchable: true, orderable: true },
    { data: 'Nome', dataTitle: 'Nome', name: 'name', searchable: true, orderable: true, link: 'main_link' },
    { data: 'Email', dataTitle: 'Email', name: 'email', searchable: true, orderable: true },
    { data: 'Cargo', dataTitle: 'Cargo', name: 'role', searchable: true, orderable: true },
    { data: 'Estado', dataTitle: 'Estado', name: 'status', searchable: true, orderable: true },
]
//define layout
</script>

<style scoped>

</style>
