<template>
	<image-upload-modal
		v-model:open="modalOpen"
		title="Imagem"
		@upload="uploadImage"
	/>
    <div v-if="label" class="col-12 align-items-center text-center">
        {{ label }}
    </div>
    <div class="col-12">
        <div class="row">
            <div class="col-12 d-flex align-items-center">

                <div class="position-relative ">
                    <img
                        alt="Imagem"
                        :src="getImageToShow"
                        class="rounded-pill profile-image"
                        style="width: 115px; height: 115px;"
                        @click="modalOpen = true"
                    >
                    <div class="profilePicEditButton position-absolute cursor-pointer" @click="modalOpen = true">
                        <i class="mdi mdi-image-edit-outline" />
                    </div>
                </div>
                <div>
                    <span v-if="showTopLine" class="d-block font-24 font-weight-bold color-272727"> {{ topLine ?? 'Nome' }} </span>
                    <span v-if="showBottomLine" class="d-block font-16 color-2E363D"> <span class="font-weight-500"> {{ bottomLineLabel }}: </span> {{ bottomLineValue }} </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageUploadModal from "./ImageUploadModal.vue";
export default {
    name: "ModelImageUpload",
    components: {ImageUploadModal},

    props: {
        modelValue: undefined,

        label: {
            type: String,
            required: false,
            default: null,
        },
        defaultIcon: {
            type: String,
            required: false,
            default: null,
        },
        topLine: {
            type: String,
            required: false,
            default: null,
        },
        showTopLine: {
            type: Boolean,
            default: true,
            required: false,
        },
        bottomLineLabel: {
            type: String,
            required: false,
            default: null,
        },
        bottomLineValue: {
            type: String,
            required: false,
            default: null,
        },
        showBottomLine: {
            type: Boolean,
            default: true,
            required: false,
        },
    },

    emits: ['imageUploaded', 'update:modelValue'],

    data(){
        return{
            modalOpen: false,
        }
    },

     computed:{
        getImageToShow(){
            if(this.modelValue?.imageChanged){
                return URL.createObjectURL(this.modelValue.image);
            } else if(this.modelValue?.image){
                return this.modelValue.image;
            }
            if(this.defaultIcon){
                return this.asset(this.defaultIcon);
            }

            return this.asset('images/userPlaceholder.jpg');
        }
    },

    methods: {
         uploadImage(blob){
             this.$emit('update:modelValue', {
                 image: blob,
                 imageChanged: true,
             })

             this.$emit('imageUploaded', blob);
        },
    }
}
</script>

<style scoped>
.profilePicEditButton {
    position: absolute;
    bottom: 5px;
    right: 0;
    font-size: 18px;
    background-color: #333;
    height: 30px;
    width: 30px;
    text-align: center;
    border-radius: 50%;
    color: white;
}
.profile-image {
    object-fit: cover;
    object-position: center;
    transition: filter 0.3s;
}
.profile-image:hover {
    cursor: pointer;
    filter: brightness(0.8);
}
</style>
