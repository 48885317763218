<template>
    <Multiselect
        v-model="vModel"
        :options="options"
        :track-by="track"
        :label="label"
        :options-limit="optionsLimit"
        :internal-search="!async"
        placeholder=""
        class="datatableMultiselect"
        deselect-label=""
        select-label=""
        selected-label=""
        select-group-label=""
        deselect-group-label=""
        tag-placeholder=""
        :multiple="multiple"
        :close-on-select="!multiple"
        :taggable="taggable"
        :preselect-first="preselectFirst"
        @search-change="search"
        @tag="addTag"
    >
        <template #option="{option}">
            <slot name="option" :option="option">
                <span style="max-width: 50px">{{option[label]}}</span>
            </slot>
        </template>

        <template #singleLabel="{option}">
            <slot name="singleLabel" :option="option" />
        </template>

        <template #noResult>
            Não foram encontrados resultados
        </template>
    </Multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    name: "SearchMultiselect",
    components: {
        Multiselect,
    },

    props: {
        url: {
            type: String,
            required: true,
        },

        params:{
            type: Object,
            required: false,
            default: {}
        },

        track: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },

        searchable: {
            type: Array,
            required: false,
        },

        select: {
            type: Array,
            required: false,
        },

        optionsLimit: {
            type: Number,
            required: false,
            default: 1000,
        },

        async: {
            type: Boolean,
            required: false,
            default: false,
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false,
        },

        storeUrl: {
            type: String,
            required: false,
            default: null,
        },

        taggable: {
            type: Boolean,
            required: false,
            default: false,
        },

        ticketBehavior: {
            type: Boolean,
            required: false,
            default: false,
        },

        preselectFirst: {
            type: Boolean,
            required: false,
            default: false,
        },

        modelValue: undefined,
    },
    emits: ['update:modelValue'],

    data(){
        return{
            options: [],
            firstSearchDone: false,
            vModel: this.modelValue,
            realTimeParams: {},
        }
    },

    watch: {
        vModel(val){
            this.updateModelFunc(val)
        },
        params(){
            this.search()
        },
        realTimeParams(){
            this.search()
        },
    },

    mounted() {
        this.search();
    },

    methods: {
        //NAO ESQUECER QUE OS PARAMETROS QUE PASSAS DÁ PARA VER NO REQUEST, NAO PASSAR DADOS SENSIVEIS !!!!
        search(search){
            if(this.async || !this.firstSearchDone) {
                let form = {
                    params: {
                        toJson: true,
                        search: search,
                        select: this.select,
                        searchable: this.searchable,
                        ...this.params
                    }
                };


                if(this.ticketBehavior && search){
                    delete form.params.client_id;
                }

                return axios.get(this.url, form).then((response) => {
                    this.options = response.data;
                    this.firstSearchDone = true;

                    console.log(this.options)
                })
            }
        },

        updateModelFunc(value){
            this.$emit('update:modelValue', value);
        },

        clear(){
            this.vModel = null;
        },

        addTag(search){
            if(this.storeUrl){
                let form = {
                    toJson: true,
                    search: search,
                    select: this.select,
                    searchable: this.searchable,
                    ...this.params
                };

                return axios.post(this.storeUrl, form).then((response) => {
                    this.options.push(response.data);
                    this.updateModelFunc(response.data);
                })
            } else {
                const tempTag = {
                    id: crypto.getRandomValues(new Uint32Array(1))[0], //hammered
                    name: search,
                    create: true,
                }
                if(! this.vModel){
                    this.vModel = [];
                }
                this.options.push(tempTag);
                if(this.multiple){
                    this.vModel.push(tempTag);
                } else {
                    this.vModel = tempTag;
                }
                this.updateModelFunc(this.vModel);
            }
        }
    },
}
</script>

<style scoped>

</style>
