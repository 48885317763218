<template>
	<div class="form-group">
		<label :for="name" class="form-label"> {{ label }} </label>

		<input
			v-if="type === 'date'"
			:id="name"
			ref="input"
			:value="modelValue"
			type="text"
			:name="name"
			:class="{'is-invalid': error}"
			class="form-control flatpickr-input"
			:placeholder="placeholder"
			@input="updateValue"
		>
		<textarea
			v-else-if="type === 'textarea'"
			:id="name"
			ref="input"
			:value="modelValue"
			:name="name"
			rows="3"
			:class="{'is-invalid': error}"
			class="form-control"
			:placeholder="placeholder"
			@input="updateValue"
		/>
		<input
			v-else
			:id="name"
			ref="input"
			:value="modelValue"
			:type="type"
			:name="name"
			:class="{'is-invalid': error}"
			class="form-control"
			:placeholder="placeholder"
			@input="updateValue"
		>
	</div>
</template>

<script>
export default {
    name: "DatatableInput",
    props:{
        label: String,
        placeholder: String,
        name: String,

        help:{
            type: String,
            required: false,
        },

        type: {
            type: String,
            required: false,
            default: 'text',
        },

        modelValue: undefined,

        error:{
            type: String,
            required: false,
            default: undefined
        }
    },

    mounted() {
        setTimeout(() => {
            this.startFlatpickr();
        }, 1);
    },

    methods:{
        updateValue(event) {
            this.$emit('update:modelValue', event.target.value);
        },

        startFlatpickr() {
            if(this.type === 'date') {
                flatpickr(this.$refs.input, {
                    enableTime: true,
                    time_24hr: true,
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    dateFormat: 'd-m-Y H:i',
                    defaultDate: this.modelValue ? momentJS(this.modelValue).format('DD-MM-YYYY HH:mm:ss') : undefined,
                });
            }
        }
    }
}
</script>

<style scoped>
    .form-label {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 500;
    }

    .form-control{
        border: 0;
        background: #F7F7F7;
        border-radius: 5px;
        padding: 8px 0.75rem;
        font-size: 14px;
    }

    .form-control:focus{
        border: 0;
        box-shadow: none;
    }
</style>
