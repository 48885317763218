<template>
	<div>
		<form @submit.prevent="submit">
			<div class="d-flex flex-column w-100 gap-4">
				<div class="d-flex align-items-center">
					<!--					<model-image-upload
						v-model="form.profilePicture"
						:show-bottom-line="false"
						:show-top-line="false"
					/>-->

					<div>
						<span class="fw-bold" style="font-size: 20px;"> {{ form.name ?? '' }} </span><br>
						<span> {{ form.email ?? '' }} </span>
						<div v-if="defaultForm.id === $page.props.auth.id">
							<a
								data-bs-toggle="modal"
								data-bs-target="#changePasswordModal"
								href="javascript:void(0)"
								class="btn btn-sm btn-primary mt-2"
							>Alterar Password</a>
						</div>
					</div>
				</div>
				<div class="col-12">
					<div class="row gap-5">
						<div class="col-xl-4 col-md-6 col-xs-12">
							<div class="mb-3">
								<DefaultInput
									v-model="form.name"
									label="Name"
									name="name"
									:error="pErrors.name"
								/>
							</div>
							<div class="mb-3">
								<DefaultInput
									v-model="form.abbreviation"
									label="Sigla"
									name="abbreviation"
									:error="pErrors.abbreviation"
									:max-length="3"
								/>
							</div>

							<div class="mb-3">
								<DefaultInput
									v-model="form.email"
									label="Email"
									name="email"
									:error="pErrors.email"
								/>
							</div>

							<div v-if="type === 'create'" class="mb-3">
								<DefaultInput
									v-model="form.password"
									label="Password"
									name="password"
									type="password"
									:error="pErrors.password"
								/>
							</div>


							<div v-if="hasPermission('Estado de Utilizadores')">
								<free-input
									label="Estado"
									:error="pErrors.is_active"
								>
									<multiselect
										ref="is_active"
										v-model="form.is_active"
										:options="[
											{id: 1, name: 'Ativo'},
											{id: 0, name: 'Inativo'},
										]"
										:preselect-first="true"
										class="datatableMultiselect"
										placeholder=""
										track-by="id"
										label="name"
										:show-labels="false"
										:allow-empty="false"
									/>
								</free-input>
							</div>
						</div>
						<div class="col-xl-4 col-md-6 col-xs-12">
							<div v-if="hasPermission('Utilizadores')">
								<div v-if="defaultForm.id !== $page.props.auth.id" class="mb-3">
									<FreeInput
										label="Cargo"
										:error="pErrors['role.id']"
									>
										<SearchMultiselect
											v-model="form.role"
											label="name"
											track="id"
											:url="route('roles.searchwWithoutContestent')"
											:error="pErrors.roles"
										/>
									</FreeInput>
								</div>

								<label class="form-label">
									Permissões
								</label>
								<div
									v-for="permission in permissions"
									class="d-flex gap-2 mb-1"
								>
									<template v-if="canSeePermission(permission)">
										<input
											:id="'permission_' + permission.id"
											type="checkbox"
											class="form-check-input"
											:checked="form.permissions.includes(permission.id)"
											@change="togglePermission(permission)"
										>
										<label :for="'permission_' + permission.id">{{ permission.name }}</label>
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-3">
					<SubmitButton ref="submitButton" />
				</div>
			</div>
		</form>
		<div
			id="changePasswordModal"
			class="modal fade"
			tabindex="-1"
			aria-labelledby="changePasswordModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 id="changePasswordModalLabel" class="modal-title fw-bold">
							Alterar Password
						</h5>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						/>
					</div>
					<div class="modal-body">
						<form @submit.prevent="changePassword">
							<div class="mb-3">
								<DefaultInput
									v-model="form.old_password"
									label="Password Antiga"
									name="old_password"
									type="password"
									:error="pErrors.old_password"
								/>
							</div>
							<div class="mb-3">
								<DefaultInput
									v-model="form.password"
									label="Password"
									name="password"
									type="password"
									:error="pErrors.password"
								/>
							</div>
							<div class="mb-3">
								<DefaultInput
									v-model="form.password_confirmation"
									label="Confirmar Password"
									name="password_confirmation"
									type="password"
									:error="pErrors.password_confirmation"
								/>
							</div>
							<div class="mt-3">
								<SubmitButton ref="submitButtonPassword" />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DefaultInput from "../../Components/DefaultInput.vue";
import {useForm} from "@inertiajs/vue3";
import FormSubmitButton from "../../Components/FormSubmitButton.vue";
import SearchMultiselect from "../../Components/SearchMultiselect.vue";
import FreeInput from "../../Components/FreeInput.vue";
import SubmitButton from "../../Components/SubmitButton.vue";
import ModelImageUpload from "../../Components/ModelImageUpload.vue";
import Multiselect from "vue-multiselect";

export default {
    name: "Form",
    components: {
        ModelImageUpload,
        SubmitButton,
        DefaultInput,
        FormSubmitButton,
        SearchMultiselect,
        FreeInput,
        Multiselect
    },

    props: {
        defaultForm: {
            type: Object,
            required: true,
        },
        submitRoute: {
            type: String,
            required: true,
        },
        errors: {
            type: Object,
            required: false,
            default: undefined
        },
        type: {
            type: String,
            required: true,
            default: undefined
        }
    },
    data() {
        return {
            form: useForm(this.defaultForm),
            permissions: [],
            locales: [
                {name: 'Português', id: 'pt'},
                {name: 'Inglês', id: 'en'},
            ],
            pErrors: {}
        }
    },

    created() {
        this.pErrors = this.errors
        this.form.type = this.type
        this.getPermissions()
    },

    beforeMount() {
        try {
            this.form.locale = JSON.parse(localStorage.getItem('full-locale') || [])
        } catch (e) {
            localStorage.removeItem('full-locale')
        }
    },

    mounted() {
        //Proof of concept to set the default value of the multiselect from the id
        if(this.$refs['is_active']){
            this.$refs['is_active'].options.forEach((option) => {
                if (option.id === this.form.is_active) {
                    this.form.is_active = option
                }
            })
        }
    },

    methods: {
        submit() {
            if(this.form.locale){
                localStorage.setItem('locale', this.form.locale.id)
                localStorage.setItem('full-locale', JSON.stringify(this.form.locale))
            }
            this.form.post(this.submitRoute, {
                preserveState: true,
                onSuccess: () => {
                    //window.location.reload()
                    this.$refs.submitButton?.toggleLoading(false)
                },
                onError: (e) => {
                    this.pErrors = e
                    this.$refs.submitButton?.toggleLoading(false)
                },
                onFinish: () => {
                    this.$refs.submitButton?.toggleLoading(false)
                }
            })
        },

        getPermissions() {
            axios.get(route('permissions.search'))
                .then(response => {
                    this.permissions = response.data
                })
        },

        togglePermission(permission) {
            if (this.form.permissions.includes(permission.id)) {
                //keep only ids
                this.form.permissions = this.form.permissions.reduce((acc, curr) => {
                    if (curr !== permission.id) {
                        acc.push(curr)
                    }
                    return acc
                }, [])
            } else {
                this.form.permissions.push(permission.id)
            }
        },

        changeLocale(locale) {
            //Probably will need a full refresh to update locale
            //update locale
            // i18nObject.locale = locale.id

            // console.log(i18nObject.locale)
        },
        changePassword(){
            this.pErrors = {}
            axios.post(route('users.change_password', this.form.id), this.form)
                .then((res) => {
                    if(res.response?.data?.errors){
                        //emit errors change
                        Object.keys(res.response.data.errors).forEach((key) => {
                            this.pErrors[key] = res.response.data.errors[key][0]
                        })
                        return;
                    }
                    this.form.old_password = ''
                    this.form.password = ''
                    this.form.password_confirmation = ''

                    //Modal wasn't closing
                    window.location.reload()
                })
                .catch(error => {
                })
                .finally(() => {
                    this.$refs.submitButtonPassword?.toggleLoading(false)
                })
        },
        canSeePermission(permission){
            //These permissions can only be given by Super Admins
            const permissionsSA = [
                'Estado de Utilizadores'
            ]

            if(permissionsSA.includes(permission.name)){
                return !!this.$page.props.auth.isSuperAdmin;

            }

            return true
        }
    }
}
</script>

<style scoped>

</style>
