<template>
	<div>
		<div class="block-editing-wrapper col-12">
			<h5 class="fw-bold">
				Bloquear edição dos participantes
			</h5>
			<p>
				Deve bloquear a edição dos participantes para que estes não possam alterar os seus dados enquanto os
				esta a validar.
			</p>
			<div class="col-12 pt-2 each-validation-wrapper">
				<div class="row justify-content-end ">
					<div class="col-auto">
						<!--						<label class="input-radio">
                                                    <input
                                                        v-model="form.blocked"
                                                        type="radio"
                                                        value="blocked"
                                                    > Bloqueado
                                                </label>
                                                <label class="input-radio">
                                                    <input
                                                        v-model="form.blocked"
                                                        type="radio"
                                                        value=""
                                                    > Desbloqueado
                                                </label>-->
						<div class="form-check form-switch">
							<input
								id="flexSwitchCheckDefault"
								v-model="form.blocked"
								class="form-check-input"
								type="checkbox"
								value="blocked"
							>
							<label
								class="form-check-label fw-bold"
								style="font-size:12pt; min-width:120px"
								for="flexSwitchCheckDefault"
							>
								<template v-if="form.blocked">
									Bloqueado
								</template>
								<template v-else>
									Desbloqueado
								</template>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<form @submit.prevent="submit">
			<div class="col-12">
				<h5 class="fw-bold">
					Validação da participação
				</h5>
				<progress
					id="progress-bar"
					max="100"
					:value="progress"
					:class="progressClass"
				>
					0%
				</progress>
				<p>Deve verificar os documentos dos participantes para que estes possam usufruir do prémio</p>
				<a
					v-if="participationStatus.status_label_administration === 'Suplente' && hasPermission('Qualificar e Desqualificar Vencedores')"
					class="btn btn-primary"
					data-bs-toggle="modal"
					data-bs-target="#qualificarVencedoresModal"
				>
					Desqualificar Premiado
				</a>
				<a
					v-if="participationStatus.status_label_administration === 'Suplente' && hasPermission('Qualificar e Desqualificar Vencedores')"
					class="btn btn-primary"
					data-bs-toggle="modal"
					data-bs-target="#qualificarVencedoresModal"
				>
					Qualificar como Premiado
				</a>
			</div>

			<div class="d-flex flex-column w-100 gap-4 mt-4">
				<div class="row">
					<div class="col-12 each-validation-wrapper pb-4" :class="form.moradaCP.validation">
						<div class="row">
							<div class="col-12 each-validation-title">
								<h5 class="fw-bold ">
									Morada do participante
									<template v-if="form.moradaCP.validation !== null">
										<div class="badge badge-pill each-validation-wrapper-badge">
											{{ form.moradaCP.validation }}
										</div>
									</template>
								</h5>
							</div>

							<div class="col-12 col-md-9 ">
								<div class="col-12 fake-input-text">
									{{ participationStatus.morada_value }}
								</div>
							</div>
							<div class="col-12 col-md-3 ">
								<div class="col-12 fake-input-text">
									{{ participationStatus.cp_value }}
								</div>
							</div>
							<div class="col-12 pt-2">
								<div class="row justify-content-end">
									<div class="col-auto">
										<label class="input-radio">
											<input
												v-model="form.moradaCP.validation"
												type="radio"
												value="Validado"
											> Validar
										</label>
										<label class="input-radio">
											<input
												v-model="form.moradaCP.validation"
												type="radio"
												value="Recusado"
											> Recusar
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class=" col-12 mt-4 mb-4 pt-4 pb-4 ">
						<validate-image-backoffice
							v-model="form.CCFrente"
							:show-bottom-line="false"
							:show-top-line="false"
							:default-icon="'images/card-front.jpg'"
							:second-default-icon="'images/card-back.png'"
							:label="'Doc ID'"
							:second-image="form.CCBack"
							:validation="participationStatus.cc_frente"
							:already-validated="participationStatus.status_label_administration"
							file-on-error="images/card-front.jpg"
						/>
					</div>
					<div class=" col-12 mt-4 mb-4 pt-4 pb-4 ">
						<validate-image-backoffice
							v-model="form.CodePhoto"
							:show-bottom-line="false"
							:show-top-line="false"
							:default-icon="'images/card-front.jpg'"
							:label="'Talão código participação'"
							:label-two="'Código preenchido pelo utilizador'"
							:matching-value="participation.code"
							:validation="participationStatus.code_photo"
							:already-validated="participationStatus.status_label_administration"
							file-on-error="images/card-front.jpg"
						/>
					</div>
					<div class=" col-12 mt-4 mb-4 pt-4 pb-4 ">
						<validate-image-backoffice
							v-model="form.BillPhoto"
							:show-bottom-line="false"
							:show-top-line="false"
							:default-icon="'images/invoice.png'"
							:label="'Fatura da compra'"
							:label-two="'Nº factura preenchido pelo utilizador'"
							:matching-value="participation.invoice_number"
							:validation="participationStatus.bill_photo"
							:already-validated="participationStatus.status_label_administration"
							file-on-error="images/invoice.png"
						/>
					</div>
					<div class="col-12 pt-4 mt-4 each-validation-wrapper">
						<div class="row">
							<div class="col-12 each-validation-title">
								<h5 class="fw-bold ">
									Mensagem para o utilizador
								</h5>
							</div>
							<div class="col-12">
								<textarea
									v-model="form.mensagem.value"
									class="col-12"
									rows="5"
								/>
								<template v-if="hasPermission('Qualificar e Desqualificar Vencedores')">
									<div
										v-if="participationStatus.status_label_administration === 'Premiado' || participationStatus.status_label_administration === 'Recusado' "
										class="col-12 p-4 fake-input-text"
									>
										<p>
											Utilize este campo para escrever uma mensagem para o concorrente,
											por exemplo para justificar porque motivo(s) a participação não se encontra
											validada.
											A mensagem será enviada para o email do concorrente caso a check box se encontre
											preenchida.
											Será sempre enviada para a central de notificações da aplicação.
										</p>
										<p>
											Caso pretenda desqualificar esta participação, <a

												class="fake-href-red"
												data-bs-toggle="modal"
												data-bs-target="#desqualificarVencedoresModal"
											>clique aqui</a>.
										</p>
									</div>
								</template>
								<div class="col-12 pt-2">
									<div class="row justify-content-end">
										<div class="col-auto">
											<label>
												<input
													v-model="form.mensagem.sendEmail"
													type="checkbox"
													value="true"
												> Desejo notificar o utilizador por email
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="participationStatus.status_label_administration !== 'Desqualificado'" class="col-12">
						<div class="row gap-5 justify-content-end">
							<div class="col-xl-4 col-md-6 col-12">
								<div class="mt-3">
									<SubmitButton
										v-if="form.blocked === true || form.blocked === 'blocked'"
										ref="submitButton"
										label="Gravar"
									/>
									<SubmitButton
										v-else
										ref="submitButton"
										label="Gravar"
										disabled="disabled"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 pt-4 mt-4">
					<h5 class="fw-bold">
						Envio do prémio
					</h5>

					<!--					<p v-if="defaultForm.ctt === null">
                        Depois de validar todos os documentos do participante, deve enviar o código CTT para o informar
                        do código de envio.
                        Esta opção vai estar disponível apenas quando todos os documentos estiverem validados.<br>
                        <a
                            v-if="progressClass === 'completed'"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#enviarCttModal"
                        >
                            Clique aqui para enviar o código CTT
                        </a>
                    </p>
                    <p v-else>
                        O prémio foi enviado ao participante, com o código: {{ defaultForm.ctt }}.
                    </p>-->
					<div class="col-12 pt-2">
						<table>
							<thead>
								<tr>
									<th>Prémio</th>
									<th>Nº de registo postal</th>
									<th>Data de envio</th>
									<th>Utilizador</th>
									<th>Notas</th>
									<th />
								</tr>
							</thead>
							<tbody>
								<!-- Add your table rows (data) here -->
								<tr>
									<td>{{ participationStatus.contest.name }}</td>
									<td>{{ participationStatus.ctt ?? '' }}</td>
									<td>{{ participationStatus.ctt_date ?? '' }}</td>
									<td>{{ participationStatus.ctt_admin?.name ?? '' }}</td>
									<td>{{ participationStatus.ctt_notes ?? '' }}</td>
									<td>
										<a
											v-if="progressClass === 'completed'"
											href="#"
											data-bs-toggle="modal"
											data-bs-target="#enviarCttModal"
										>
											<i class="mdi mdi-pencil-outline" />
										</a>
									</td>
								</tr>

								<!-- Add more rows as needed -->
							</tbody>
						</table>
					</div>
				</div>


				<div
					v-if="hasPermission('Qualificar e Desqualificar Vencedores')"
					id="desqualificarVencedoresModal"
					class="modal fade"
					tabindex="-1"
					aria-labelledby="desqualificarVencedoresLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title">
									<!--									Desqualificar Premiado-->
								</h5>
								<div
									style="width: 20px; height: 20px"
									class="btn-close cursor-pointer"
									data-bs-dismiss="modal"
									aria-label="Close"
								/>
							</div>


							<div class="modal-body">
								<div class="col-12 text-center">
									<h5>Desqualificar participação</h5>
									<p style="font-size: 30px; font-weight: 500">
										{{ participation.id }} - {{ participation.contestent.name }}
									</p>
									<p style="font-size: 24px; font-weight: 700">
										Esta participação será <span style="color:#ff1b1b">desqualificada</span>. Por
										favor indique o motivo*
									</p>
									<div class="col-12">
										<textarea
											v-model="desqualifyText"
											class="col-12"
											rows="5"
										>
                                          test
                                          </textarea>
									</div>
									<div class="col-12 pt-2 pb-4 mb-4">
										<div class="row justify-content-end">
											<div class="col-auto">
												<label>
													<input
														v-model="desqualifyTextSendContestent"
														type="checkbox"
														value="true"
													> Desejo notificar o utilizador por email
												</label>
											</div>
										</div>
									</div>
									<div class="col-12 pt-2 pb-4 mb-4">
										<div class="row justify-content-center">
											<div class="col-auto">
												<label style="font-size: 24px">
													<input
														v-model="desqualifyTextAndPromoteNextSuplente"
														type="checkbox"
														value="true"
													> Desejo promover a premiado o primeiro suplente disponível
												</label>
											</div>
										</div>
									</div>
									<div class="col-12 pt-2 pb-4 mt-4">
										<div class="row justify-content-center">
											<div class="col-auto">
												<a
													v-if="hasPermission('Qualificar e Desqualificar Vencedores')"
													class="btn btn-secondary btn-refuse"
													href="#"
													data-bs-dismiss="modal"
													@click="desqualificarVencedor"
												>
													Desqualificar esta participação
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					id="enviarCttModal"
					class="modal fade"
					tabindex="-1"
					aria-labelledby="enviarCttLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title">
									<!--									Desqualificar Premiado-->
								</h5>
								<div
									style="width: 20px; height: 20px"
									class="btn-close cursor-pointer"
									data-bs-dismiss="modal"
									aria-label="Close"
								/>
							</div>


							<div class="modal-body">
								<form>
									<div class="col-12 text-center mt-4">
										<h5>Envio de prémio</h5>
										<p style="font-weight: 500" class="pt-4">
											Ao preencher o campo abaixo, vamos enviar um email a informar o participante
											que o prémio foi enviado
											para a morada que temos validada.
										</p>

										<div class="col-12 ">
											<div class="col-12 pt-4 d-flex justify-content-center">
												<DefaultInput
													v-model="formCtt.ctt"
													label="Código CTT"
													type="text"
													class="default-input-pull-left overide-default-input"
													placeholder="Insira o Código CTT"
												/>
											</div>
										</div>
										<div class="col-12 pt-4 d-flex justify-content-center">
											<DefaultInput
												v-model="formCtt.ctt_date"
												label="Data de envio"
												type="date"
												class="default-input-pull-left overide-default-input"
												placeholder="Clique para selecionar a data de envio"
												date-format="YYYY-MM-DD"
											/>
										</div>
										<div class="col-12 pt-4 d-flex justify-content-center">
											<label class="form-label">
												Notas
											</label>
										</div>
										<div class="col-12  d-flex justify-content-center overide-default-input">
											<textarea
												v-model="formCtt.ctt_notes"
												class="col-12 col-md-8 "
												rows="5"
											>
                                              test
                                              </textarea>
										</div>
										<div
											v-if="formCtt.ctt && formCtt.ctt !=='' && formCtt.ctt !==' ' && formCtt.ctt !=='  ' && formCtt.ctt !=='   ' && formCtt.ctt_date"
											class="col-12 pt-2 pb-4 mt-4"
										>
											<div class="row justify-content-center">
												<div class="col-auto">
													<a
														class="btn btn-secondary "
														href="#"
														data-bs-dismiss="modal"
														@click="submitCtt"
													>
														Enviar CTT
													</a>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<!--				<div
                    v-if="hasPermission('Qualificar e Desqualificar Vencedores')"
                    id="qualificarVencedoresModal"
                    class="modal fade"
                    tabindex="-1"
                    aria-labelledby="qualificarVencedoresLabel"
                    aria-hidden="true"
                >
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    Qualificar Premiado
                                </h5>
                                <div
                                    style="width: 20px; height: 20px"
                                    class="btn-close cursor-pointer"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>


                            <div class="modal-body">
                                <div class="col-12">
                                    Tem a certeza que quer qualificar este premiado?
                                    Esta ação não pode ser desfeita.
                                </div>
                            </div>

                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Fechar
                                </button>

                                <a
                                    v-if="hasPermission('Qualificar e Desqualificar Vencedores')"
                                    :href="route('admin.contest.qualificar', participationStatus.id)"
                                    class="btn btn-primary"
                                    data-bs-dismiss="modal"
                                    @click="qualificarVencedor"
                                >
                                    <i class="mdi mdi-pencil-outline" />
                                    Qualificar Premiado
                                </a>
                            </div>
                        </div>
                    </div>
                </div>-->
			</div>
		</form>


		<!--		<div class="col-12">
            <div class="row gap-5 justify-content-end">
                <div class="col-xl-4 col-md-6 col-12">
                    <div class="mt-3">
                        <SubmitButton ref="submitCttButton" label="Enviar Ctt" />
                    </div>
                </div>
            </div>
        </div>
        </form>-->
	</div>
</template>

<script>

import {useForm} from "@inertiajs/vue3";
import SubmitButton from "../../Components/SubmitButton.vue";
import ValidateImageBackoffice from "../../Components/ValidateImageBackoffice.vue";
import DefaultInput from "@/Components/DefaultInput.vue";


export default {
    name: "Form",
    components: {
        DefaultInput,
        ValidateImageBackoffice,
        SubmitButton,
    },

    props: {
        defaultForm: {
            type: Object,
            required: true,
        },
        cttForm: {
            type: Object,
            required: true,
        },
        participation: {
            type: Object,
            required: true,
        },
        participationStatus: {
            type: Object,
            required: true,
        },
        submitRoute: {
            type: String,
            required: true,
        },
        submitCttRoute: {
            type: String,
            required: true,
        },
        errors: {
            type: Object,
            required: false,
            default: undefined
        },
        type: {
            type: String,
            required: true,
            default: undefined
        }
    },
    data() {
        return {
            form: useForm(this.defaultForm),
            formCtt: useForm(this.cttForm),
            desqualifyText: ' ',
            desqualifyTextSendContestent: true,
            desqualifyTextAndPromoteNextSuplente: true,
            permissions: [],
            progress: 0,
            progressRefused: 0,
            progressClass: '',
            locales: [
                {name: 'Português', id: 'pt'},
                {name: 'Inglês', id: 'en'},
            ],
            pErrors: {}
        }
    },
    watch: {
        'form.moradaCP.validation': function (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateProgress();
            }
        },
        'form.CCFrente.validation': function (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateProgress();
            }
        },
        'form.CodePhoto.validation': function (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateProgress();
            }
        },
        'form.BillPhoto.validation': function (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateProgress();
            }
        },
        'form.blocked': function (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateBlocked();
            }
        },


    },

    created() {
        this.pErrors = this.errors
        this.form.type = this.type
    },

    beforeMount() {
        try {
            this.form.locale = JSON.parse(localStorage.getItem('full-locale') || [])
        } catch (e) {
            localStorage.removeItem('full-locale')
        }
    },

    mounted() {
        //Proof of concept to set the default value of the multiselect from the id
        if (this.$refs['is_active']) {
            this.$refs['is_active'].options.forEach((option) => {
                if (option.id === this.form.is_active) {
                    this.form.is_active = option
                }
            })
        }
        setTimeout(() => {
            this.updateProgress()
        }, 2000);

        if(this.form.blocked === 'blocked') {
            this.form.blocked = true
        } else {
            this.form.blocked = false
        }
        console.log(this.participationStatus.cttAdmin);
    },

    methods: {
        updateProgress() {
            this.progress = 0;
            this.progressRefused = 0;
            if (this.form.moradaCP.validation !== null && this.form.moradaCP.validation === 'Validado') {
                this.progress = this.progress + 25;
                /* console.log(this.progress,1)*/
            }
            if (this.form.CCFrente.validation !== null && this.form.CCFrente.validation === 'Validado') {
                this.progress = this.progress + 25;
                /*console.log(this.progress,2)*/
            }
            if (this.form.CodePhoto.validation !== null && this.form.CodePhoto.validation === 'Validado') {
                this.progress = this.progress + 25;
                /*console.log(this.progress,3)*/
            }
            if (this.form.BillPhoto.validation !== null && this.form.BillPhoto.validation === 'Validado') {
                this.progress = this.progress + 25;
                /*console.log(this.progress,4)*/
            }
            if (this.form.moradaCP.validation !== null && this.form.moradaCP.validation === 'Validado'
                && this.form.CCFrente.validation !== null && this.form.CCFrente.validation === 'Validado'
                && this.form.CodePhoto.validation !== null && this.form.CodePhoto.validation === 'Validado'
                && this.form.BillPhoto.validation !== null && this.form.BillPhoto.validation === 'Validado'
            ) {
                this.progressClass = 'completed'
            }

            if (this.form.moradaCP.validation !== null && this.form.moradaCP.validation === 'Recusado') {
                this.progressRefused = this.progressRefused + 25;
                /* console.log(this.progress,1)*/
            }
            if (this.form.CCFrente.validation !== null && this.form.CCFrente.validation === 'Recusado') {
                this.progressRefused = this.progressRefused + 25;
                /*console.log(this.progress,2)*/
            }
            if (this.form.CodePhoto.validation !== null && this.form.CodePhoto.validation === 'Recusado') {
                this.progressRefused = this.progressRefused + 25;
                /*console.log(this.progress,3)*/
            }
            if (this.form.BillPhoto.validation !== null && this.form.BillPhoto.validation === 'Recusado') {
                this.progressRefused = this.progressRefused + 25;
                /*console.log(this.progress,4)*/
            }

            if (this.progress === 100) {
                this.form.mensagem.value = 'Os teus dados foram validados e aprovados.\nEm breve, daremos mais informação sobre o envio do prémio.\n \n Obrigado!'
            } else if (this.progressRefused === 100) {
                this.form.mensagem.value = 'Tens pelo menos um dado que submeteste na participação que não foi aprovado.\nVerifica por favor os dados para poderes receber o prémio.\n \n Obrigado!'
            } else if (this.progressRefused > 0) {
                this.form.mensagem.value = 'Tens pelo menos um dado que submeteste na participação que não foi aprovado.\nVerifica por favor os dados para poderes receber o prémio.\n \n Obrigado!'
            }


        },
        submitCtt() {

            this.formCtt.post(this.submitCttRoute, {
                preserveState: true,
                onSuccess: () => {
                    //window.location.reload()
                    this.$refs.submitCttButton?.toggleLoading(false)
                },
                onError: (e) => {
                    this.pErrors = e
                    this.$refs.submitCttButton?.toggleLoading(false)
                },
                onFinish: () => {
                    this.$refs.submitCttButton?.toggleLoading(false)
                }
            })
        },
        submit() {
            if (this.form.blocked === 'blocked' || this.form.blocked === true) {
                if (this.form.locale) {
                    localStorage.setItem('locale', this.form.locale.id)
                    localStorage.setItem('full-locale', JSON.stringify(this.form.locale))
                }
                this.form.post(this.submitRoute, {
                    preserveState: true,
                    onSuccess: () => {
                        //window.location.reload()
                        this.$refs.submitButton?.toggleLoading(false)
                    },
                    onError: (e) => {
                        this.pErrors = e
                        this.$refs.submitButton?.toggleLoading(false)
                    },
                    onFinish: () => {
                        this.$refs.submitButton?.toggleLoading(false)
                    }
                })
            } else {
                alert('Deve bloquear a edição dos participantes para que estes não possam alterar os seus dados enquanto os esta a validar.')
            }
        },
        updateBlocked() {

            this.$inertia.post(route('admin.contest.blocked', this.participationStatus.id), this.form);
            /*setTimeout(() => {
                window.location.reload()
            }, 1000);*/
        },
        desqualificarVencedor() {

            var sendSettings = {
                'desqualifyText': this.desqualifyText,
                'desqualifyTextSendContestent': this.desqualifyTextSendContestent,
                'desqualifyTextAndPromoteNextSuplente': this.desqualifyTextAndPromoteNextSuplente
            };
            this.$inertia.post(route('admin.contest.desqualificar', this.participationStatus.id), sendSettings);
            /* setTimeout(() => {
                 window.location.reload()
             }, 1000);*/
        },
        qualificarVencedor() {
            this.$inertia.post(route('admin.contest.qualificar', this.participationStatus.id));
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }


    }
}
</script>

<style scoped>
table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    border: 0px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
    padding-left: 15px;
}

th:first-child {
    border-top-left-radius: 12px;
}

th:last-child {
    border-top-right-radius: 12px;
}
</style>

