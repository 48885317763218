<template>
	<PageTitle title="Exportações" />

	<div class="row">
		<div class="col-lg-3">
			<div class="card">
				<div class="card-body">
					<h5 class="card-title">
						Report Financeiro
					</h5>
					<p class="card-text">
						2 folhas com os produtos vendidos e os respetivos pagamentos
					</p>
					<a
						class="btn btn-primary"
						@click="openModal('financialReport')"
					>
						Exportar
					</a>
				</div>
			</div>
		</div>
	</div>

	<Modal
		v-model:show="showModal"
		title="Filtrar resultados"
		save-label="Exportar"
		@save="exportReport"
		@close="clearFilters"
	>
		<div class="row mb-3">
			<div class="col-lg-6">
				<DefaultInput
					v-model="filters.start_date"
					label="Data de Início"
					type="date"
				/>
			</div>

			<div class="col-lg-6">
				<DefaultInput
					v-model="filters.end_date"
					label="Data de Fim"
					type="date"
				/>
			</div>
		</div>
	</Modal>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import PageTitle from "../../Components/etc/PageTitle.vue";
import Modal from "../../Components/Modal.vue";
import DefaultInput from "../../Components/DefaultInput.vue";

export default {
    components: {DefaultInput, Modal, PageTitle},
    layout: Layout,

    data(){
        return {
            loading: false,

            showModal: false,

            type: undefined,

            //Este objecto serve apenas para guardar o estado default dos filtros
            defaultFilters: undefined,
            filters: {
                start_date: undefined,
                end_date: undefined,
            },
        }
    },

    mounted(){
        this.defaultFilters = this.filters;
    },

    methods: {
        openModal(type){
            this.type = type;
            this.showModal = true;
        },

        exportReport(){
            if(this.loading) return;

            this.loading = true;
            window.open(`/exports/${this.type}?${new URLSearchParams(this.filters).toString()}`);
            this.loading = false;
        },

        clearFilters(){
            this.type = undefined;
            this.filters = this.defaultFilters;
        }
    }
}
</script>

<style scoped>

</style>
