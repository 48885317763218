<template>
    <bread-cumbs :links="breadcumbs"></bread-cumbs>

<!--    <PageTitle
        :title="`Concurso ${contest.name}`"
    />-->

    <div class="row gap-4 flex-column-reverse flex-xxl-row">

        <div class="col-lg-auto mt-3">
            <div class="p-3 inventoryLeftCard" :class="contest.status">
                <p><b> Nome: </b> {{ contest.name }} </p>

                <p v-if="contest.rule === 'Diário'"><b> {{contest.rule}}: </b> {{ contest.start_date }}</p>
                <p v-else><b> {{contest.rule}}: </b> {{ contest.start_date }} - {{ contest.end_date }} </p>
                <p v-if="contest.status === 'Concluído'"><b> {{contest.status}} com </b>  {{participationsUsedInContestCount}} participações</p>
                <p v-if="contest.status === 'Em Curso'"><b> {{contest.status}} com </b>  {{participationsUsedInContestCount}} participações</p>
                <p v-if="contest.status === 'Por Iniciar'"><b> {{contest.status}} com </b>  {{participationsUsedInContestCount}} participações possiveis</p>

            </div>
        </div>
        <div class="col-auto align-self-end justify-content-end " v-if="contest.status !== 'Concluído' && contest.status !== 'Em Curso'" style="margin-left: auto;">
            <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#runSorteioModal"
                v-if="hasPermission('Correr Sorteios')">
                <i class="mdi mdi-pencil-outline"></i>
                Correr Sorteio
            </button>
        </div>
        <div class="col-auto align-self-end justify-content-end " v-if="contest.status === 'Concluído'"  style="margin-left: auto;">
            <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#notificarVencedoresModal"
                v-if="hasPermission('Notificar Vencedores')">
                <i class="mdi mdi-pencil-outline"></i>
                Notificar Vencedores
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-lg">
            <DefaultDataTable
                ref="main-dt"
                :url="route('admin.contests.getParticipationStatus', {contest: contest.id})"
                :columns="dtColumns"
                :search="true"
                :show-pagination="true"
            />
        </div>
    </div>
    <div
        v-if="hasPermission('Correr Sorteios')"
        id="runSorteioModal"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="runSorteioLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" >
                    <h5  class="modal-title">
                        Correr Sorteio
                    </h5>
                    <div
                        style="width: 20px; height: 20px"
                        class="btn-close cursor-pointer"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                </div>


                <div class="modal-body">

                    <div class="col-12">
                        Tem a certeza que quer correr o sorteio?
                        Esta ação não pode ser desfeita.
                    </div>

                </div>

                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        Fechar
                    </button>

                    <a
                        :href="route('admin.contest.run', contest.id)"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                        v-if="hasPermission('Correr Sorteios')"
                        @click="runSorteio">
                        <i class="mdi mdi-pencil-outline"></i>
                        Correr Sorteio
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div
        v-if="hasPermission('Notificar Vencedores')"
        id="notificarVencedoresModal"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="notificarVencedoresLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" >
                    <h5  class="modal-title">
                        Notificar Vencedores
                    </h5>
                    <div
                        style="width: 20px; height: 20px"
                        class="btn-close cursor-pointer"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                </div>


                <div class="modal-body">

                    <div class="col-12">
                        Tem a certeza que quer notificar os Vencedores?
                        Esta ação não pode ser desfeita.
                    </div>

                </div>

                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        Fechar
                    </button>

                    <a
                        :href="route('admin.contest.notifyWinners', contest.id)"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                        v-if="hasPermission('Notificar Vencedores')"
                        @click="notifyWinners">
                        <i class="mdi mdi-pencil-outline"></i>
                        Notificar Vencedores
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import PageTitle from "../../Components/etc/PageTitle.vue";
import BreadCumbs from "../../Components/etc/BreadCumbs.vue";
import DefaultDataTable from "../../Components/DefaultDataTable.vue";
import DefaultInput from "../../Components/DefaultInput.vue";
import Multiselect from "vue-multiselect";
import {DateTime} from "luxon";

export default {
    name: "Show",
    computed: {
        DateTime() {
            return DateTime
        }
    },
    components: {Multiselect, DefaultInput, DefaultDataTable, PageTitle, BreadCumbs},
    layout: Layout,

    props: {
        contest: {
            type: Object,
            required: true
        },
        participationsUsedInContestCount: {
            type: Number,
            required: true
        }

    },

    data() {
        return {
            contest: this.contest,
            isEditing: false,
            saving: false,
            breadcumbs: [
                {
                    name: 'Sorteios',
                    link: route('admin.contest.index')
                },
                {
                    name: this.contest.name,
                    link: route('admin.contest.show', this.contest.id)
                },
            ],
            dtColumns: [
                {data: 'ID', dataTitle: 'ID', name: 'id', searchable: true, orderable: true, minWidth: '50px'},
                {data: 'Participante', dataTitle: 'Participante', name: 'contestent.name', searchable: true, orderable: true, minWidth: '50px'},
                {
                    data: 'Estado',
                    dataTitle: 'Estado',
                    name: 'status_label_administration',
                    searchable: true,
                    orderable: true,
                    center: true,
                    minWidth: '50px',
                    link: 'main_link'
                },
                {
                    data: 'Participação',
                    dataTitle: 'Participação',
                    name: 'participation.code',
                    searchable: true,
                    orderable: true,
                    center: true,
                    minWidth: '50px'
                },
                {
                    data: 'Factura',
                    dataTitle: 'Factura',
                    name: 'participation.invoice_number',
                    searchable: true,
                    orderable: true,
                    center: true,
                    minWidth: '50px'
                },
            ]
        }
    },

    methods: {
        runSorteio() {
            this.$inertia.post(route('admin.contest.run', this.contest.id))

        },
        notifyWinners() {
            this.$inertia.post(route('admin.contest.notifyWinners', this.contest.id))

        },

    }
}
</script>

<style>

</style>
