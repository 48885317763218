<template>
	<div class="col-12  p-1 py-md-4 px-md-4 ">
		<bread-cumbs :links="breadcumbs" />
		<div class="row">
			<div class="col-auto col-md-5 mt-3">
				<div class="col-12 ">
					<div class="col-12 label-for-data">
						Participação Premiada
					</div>
					<div class="col-12 title-contest">
						{{ participation.id }} - {{ participation.contestent.name }}
					</div>
					<div class="col-12 mb-3">
						Concurso: Ganha uma volta ao mundo
					</div>
					<div class="col-12 ">
						<div class="row">
							<div class="col-12 ">
								<div class="col-12 label-for-data">
									Sorteio
								</div>
								<div class="col-12 data-with-label data-with-label-blue">
									{{ contest.name }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-auto col-md-7 mt-3">
				<div class="p-3 inventoryLeftCard" :class="contest.status">
					<div class="col-12 label-for-data">
						Código de participação
					</div>
					<div class="col-12 fw-bold" style="font-size: 1.35rem;">
						{{ participation.code }}
					</div>
					<div class="col-12 label-for-data">
						Número da factura
					</div>
					<div class="col-12 fw-bold" style="font-size: 1.35rem;">
						{{ participation.invoice_number }}
					</div>
					<div class="col-12 pt-2">
						<div class="row">
							<div class="col-12 col-md-5">
								<div class="col-12 label-for-data">
									Data de participação
								</div>
								<div class="col-12 data-with-label">
									{{ formattedDate(participation.created_at) }}h
								</div>
							</div>
							<div class="col-12 col-md-3">
								<div class="col-12 label-for-data">
									Data do sorteio
								</div>
								<div class="col-12 data-with-label">
									{{ formattedOnlyDate(contest.ran_date) }}
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="col-12 label-for-data">
									Estado
								</div>
								<div class="col-12 data-with-label">
									<template v-if="participationStatus.status_label_administration === 'Premiado'">
										Por Validar
									</template>
									<template v-else>
										{{ participationStatus.status_label_administration }}
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 pt-4">
				<div class="row">
					<ParticipationForm
						:default-form="defaultForm"
						:ctt-form="cttForm"
						:participation="participation"
						:participation-status="participationStatus"
						:submit-route="route('admin.participation.validate', [participationStatus, defaultForm])"
						:submit-ctt-route="route('admin.participation.ctt', [participationStatus, defaultForm])"
						:errors="errors"
						type="edit"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import ParticipationForm from "./ParticipationStatusValidateForm.vue"
import BreadCumbs from "../../Components/etc/BreadCumbs.vue";
import {DateTime} from "luxon";
export default {
    name: "ParticipationStatusFiles",

    components: {
        ParticipationForm,
        BreadCumbs
    },

    layout: Layout,

    props: {
        defaultForm: {
            type: Object,
            required: true,
        },
        participation: {
            type: Object,
            required: true,
        },
        participationStatus: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {
            contest: this.participationStatus.contest,
            cttForm: this.defaultForm,
            breadcumbs: [
                {
                    name: 'Concursos',
                    link: route('admin.contest.index')
                },
                {
                    name: '01 Ganha uma volta ao mundo',
                    link: route('admin.contest.index')
                },
                {
                    name: 'Sorteios',
                    link: route('admin.contest.index')
                },
                {
                    name: this.participationStatus.contest.name,
                    link: route('admin.contest.show', this.participationStatus.contest_id)
                },
                {
                    name: 'Validar Premiado - '+ this.participation.id,
                    link: route('admin.participation.validate.show', [this.participationStatus])
                },
            ],
        }
    },

    computed: {
        DateTime() {
            return DateTime
        }
    },
    methods: {
        formattedDate(date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};
            return new Date(date).toLocaleDateString(undefined, options);
        },
        formattedOnlyDate(date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric'};
            return new Date(date).toLocaleDateString(undefined, options);
        },
        printDate(date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric'};
            return new Date(date).toLocaleDateString(undefined, options);
        },
    }
}
</script>
<script setup>




</script>
<style>


</style>
