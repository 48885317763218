<template>
	<div>
		<template v-if="label">
			<label :for="name" class="customLabel fw-bold">
				{{ label }}
				<span v-if="required">*</span>
				<span v-if="opcional" class="fw-light">
					(opcional)
				</span>
			</label>
		</template>
		<div class="relative">
			<input
				:id="name"
				:value="modelValue"
				:required="required"
				:type="type"
				:name="name"
				class="form-control"
				:placeholder="placeholder"
				@input="updateValue"
			>
		</div>

		<span v-if="error" class="text-danger d-block pt-2"> <i class="mdi mdi-alert-circle" /> {{ error }} </span>
	</div>
</template>

<script>
export default {
    name: "LoginInput",
    props: {
        label: String,
        required: Boolean,
        opcional: Boolean,
        placeholder: String,
        name: String,

        type: {
            type: String,
            required: false,
            default: 'text',
        },

        modelValue: {
            type: [String, Number, null],
            required: true,
            default: ''
        },

        error: {
            type: String,
            required: false,
            default: undefined
        }
    },
    emits: ['update:modelValue'],

    methods: {
        updateValue(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
}
</script>

<style scoped>

</style>
