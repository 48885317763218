<template>
	<PageTitle title="Criar sorteio" />

	<ContestForm
		:default-form="defaultForm"
		:submit-route="route('professionalcontests.store')"
		:errors="errors"
		type="create"
	/>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import ContestForm from "./Form.vue"
import PageTitle from "../../Components/etc/PageTitle.vue";

export default {
    name: "Create",
    components: {
        PageTitle,
        ContestForm,
    },
    layout: Layout,
    props: {
        defaultForm: {
            type: Object,
            required: true,
            default: () => ({
                contest_type: null,
                start_date: null,
                end_date: null,
                expected_results_date: null,
                expected_finishing_date: null,
                is_active: null,
                possible_winners: null,
                possible_replacements: null,
                status: null,
            })
        },
        errors: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
}
</script>

<style scoped>

</style>
