import './bootstrap';
import {createSSRApp, h} from 'vue'
import {createInertiaApp, Link, router} from '@inertiajs/vue3'
import {InertiaProgress} from '@inertiajs/progress'
import {createToaster} from '@meforma/vue-toaster'
import {createI18n} from 'vue-i18n'
import Toast, {useToast} from "vue-toastification";
import "vue-toastification/dist/index.css";
import en from '../locales/en.json'
import pt from '../locales/pt.json'

import ziggyRoute from 'ziggy-js';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import {Ziggy} from './ziggy';
import Swal from "sweetalert2";
import '../sass/app.scss'
import '../css/app.css'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import * as Sentry from "@sentry/vue";
import {createGtm, useGtm} from '@gtm-support/vue-gtm';
import globalMixin from "./global-mixin.js";

const isProduction = import.meta.env.PROD;
const isDevelopment = import.meta.env.DEV;

let locale = 'pt';

if (typeof window !== 'undefined') {
    window.Swal = Swal;
    window.toaster = createToaster()
    window.toast = useToast()

    if (window.location) {
        Ziggy.url = window.location.origin;
    }

    locale = localStorage.getItem('locale') || 'pt';
}

const route = (name, params, absolute, config = Ziggy) => ziggyRoute(name, params, absolute, config);

/*
 * Luxon is installed, import it on the vue file with: import { DateTime } from "luxon";
 */

createInertiaApp({
    title: title => title ? `${title} - Ganha ECO diesel` : 'Ganha ECO diesel',
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', {eager: true})
        return pages[`./Pages/${name}.vue`]
    },
    setup({el, App, props, plugin}) {
        const i18n = createI18n({
            locale,
            messages: {
                en,
                pt
            }
        });

        const app = createSSRApp({render: () => h(App, props)})
            .provide('$sentry', Sentry)
            .provide('$route', route)
            .use(createGtm({
                id: 'GTM-PQZRWPFN',
                defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
                compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
                nonce: '2726c7f26c', // Will add `nonce` to the script tag
                enabled: isProduction, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
                debug: isDevelopment, // Whether or not display console logs debugs (optional)
                loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
                trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
            }))
            .use(plugin)
            .use(i18n)
            .use(ZiggyVue, Ziggy)
            .use(Toast, {
                position: 'bottom-right',
                timeout: 3000,
                closeOnClick: true,
            })
            .component("Link", Link)
            .mixin(globalMixin)
            .mixin({
                mounted() {
                    this.setSentryIdentifier();
                },
                methods: {
                    setSentryIdentifier() {
                        if (!this.$page.props.auth) {
                            return;
                        }

                        Sentry.setUser({
                            id: this.$page.props.auth.id,
                            username: this.$page.props.auth.name,
                        });
                    },
                    async confirmDelete(message) {
                        return Swal.fire({
                            title: message,
                            text: 'Esta ação é irreversível',
                            icon: 'error',
                            showCancelButton: true,
                            confirmButtonColor: '#1F97CC',
                            cancelButtonColor: '#dc3545',
                            confirmButtonText: 'Confirmar',
                            cancelButtonText: 'Cancelar',
                            reverseButtons: true,
                        }).then((result) => {
                            return result.value;
                        })
                    },

                    copyToClipboard(data) {
                        let content = data;

                        const el = document.createElement('textarea');
                        el.value = content;
                        document.body.appendChild(el);
                        el.select();
                        document.execCommand('copy');
                        document.body.removeChild(el);

                        toastSuccess('Copiado para a área de transferência');
                    },
                }
            });

        if (import.meta.env.PROD) {
            if (import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
                Sentry.init({
                    app,
                    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
                    integrations: [
                        new Sentry.BrowserTracing(),
                        new Sentry.Replay({
                            maskAllInputs: true,
                        })
                    ],
                    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                    trackComponents: true,
                });
            }
        }

        router.on('success', (event) => {
            const gtm = useGtm();
            if (gtm.enabled()) {
                gtm.trackView(event.detail.page.component, event.detail.page.url)
            }
        })

        return app.mount(el)
    },
});

InertiaProgress.init();

//Check if window has available
if (typeof window !== 'undefined') {
    let messageErrorDefault = "Não conseguimos processar o pedido, tente novamente mais tarde!";
    let timerDefault = 5000;
    let messageSuccess = 'Gravado com sucesso!';

    window.toastSuccess = (message = messageSuccess, timer = timerDefault) => {
        const success = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: timer,
            customClass: {
                actions: 'd-none',
            }
        });

        success.fire({
            icon: 'success',
            title: message
        });
    };

    window.toastError = (message = messageErrorDefault, timer = timerDefault) => {
        const messageError = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: timer,
            customClass: {
                actions: 'd-none',
            }
        });

        messageError.fire({
            icon: 'error',
            title: message
        });
    };

    window.route = route;
}
