<template>
	<PageTitle title="Editar Utilizador" />
	<template v-if="$page.props.auth.isSuperAdmin">
		<p class="mb-4">
			<a class="btn btn-warning" :href="route('devtools.changeUserAuth', {user_id: defaultForm.id})">
				<i class="mdi mdi-account-key" /> Fazer login na conta do utilizador
			</a>
		</p>
	</template>

	<UserForm
		:default-form="defaultForm"
		:submit-route="route('users.update', defaultForm.id)"
		:errors="errors"
		type="edit"
	/>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import UserForm from "./Form.vue"
import PageTitle from "../../Components/etc/PageTitle.vue";

export default {
    name: "Edit",
    components: {
        PageTitle,
        UserForm,
    },
    layout: Layout,
    props: {
        defaultForm: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
}
</script>

<style scoped>

</style>
