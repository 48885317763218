<template>
	<div class="form-group" :class="customWrapperClass">
		<label
			v-if="label && showLabel"
			:for="name"
			class="form-label"
			:class="{'invisible': type === 'checkbox'}"
		>
			{{ label }}
			<span v-if="isRequired" class="text-danger">*</span>
		</label>

		<input
			v-if="type === 'date' || type === 'time' || type === 'datetime' || type === 'date_range' || type === 'month'"
			:id="name"
			ref="input"
			:value="modelValue"
			type="text"
			:name="name"
			class="form-control flatpickr-input"
			:class="[{'is-invalid': error}, customInputClass]"
			:placeholder="placeholder"
			@input="updateValue"
		>

		<textarea
			v-else-if="type === 'textarea'"
			:id="name"
			ref="input"
			:value="modelValue"
			:name="name"
			rows="3"
			:class="[{'is-invalid': error}, customInputClass]"
			class="form-control"
			:placeholder="placeholder"
			@input="updateValue"
		/>

		<div v-else-if="type === 'radio'" class="form-group">
			<div
				v-for="(option, index) in options"
				:key="index"
				:class="vertical_align ? '' : 'form-check-inline'"
			>
				<input
					:id="name + index"
					:checked="modelValue == (optionValues ? optionValues[index] ?? option : option)"
					class="form-check-input form-radio-custom"
					type="radio"
					:name="name"
					:value="optionValues ? optionValues[index] ?? option : option"
					@input="updateValue"
				>
				<label class="form-check-label form-radio-label-custom" :for="name + index">
					{{ option }}
				</label>
			</div>
		</div>
		<div v-else-if="type === 'checkbox'" class="d-flex align-items-center">
			<input
				:id="name"
				:checked="modelValue"
				class="form-check-input form-checkbox-custom mt-0"
				type="checkbox"
				:name="name"
				@input="updateValue"
			>
			<label class="form-label ps-2 mb-0" :for="name">
				{{ label }}
			</label>
		</div>
		<div v-else-if="type === 'color'">
			<input
				:id="name"
				ref="input"
				:value="modelValue"
				:type="type"
				:name="name"
				class="form-control-color border-0 rounded-2"
				:class="[{'is-invalid': error}, customInputClass]"
				:placeholder="placeholder"
				@input="updateValue"
			>
		</div>
		<input
			v-else
			:id="name"
			ref="input"
			:value="modelValue"
			:type="type"
			:name="name"
			class="form-control"
			:class="[{'is-invalid': error}, customInputClass]"
			:placeholder="placeholder"
			@input="updateValue"
		>

		<small v-if="help" class="form-control-feedback d-block pt-2">
			<i class="mdi mdi-alert-circle" />
			{{ help }}
		</small>
		<small v-if="error" class="form-control-feedback text-danger d-block pt-2">
			<i class="mdi mdi-alert-circle" />
			{{ error }}
		</small>
	</div>
</template>

<script>
import { DateTime, Settings } from "luxon";
import Flatpickr from "flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

Settings.defaultLocale = "pt";
Settings.defaultZone = "Europe/Lisbon";

export default {
    name: "DefaultInput",
    props: {
        label: String,
        placeholder: String,
        name: String,

        showLabel: {
            type: Boolean,
            required: false,
            default: true
        },

        help: {
            type: String,
            required: false,
        },

        type: {
            type: String,
            required: false,
            default: 'text',
        },

        options: {
            type: Array,
            required: false,
        },

        optionValues: {
            type: Array,
            required: false,
        },

        modelValue: undefined,

        error: {
            type: String,
            required: false,
            default: undefined
        },

        vertical_align: {
            type: Boolean,
            required: false,
            default: true
        },

        customInputClass: {
            type: String,
            required: false,
            default: ''
        },

        minDate: {
            type: String,
            required: false,
            default: null,
        },

        customWrapperClass: {
            type: String,
            required: false,
            default: ''
        },

        isRequired: {
            type: Boolean,
            required: false,
            default: false
        },

        maxLength: {
            type: Number,
            required: false,
            default: null
        },
    },

    mounted() {
        setTimeout(() => {
            this.startFlatpickr();

            //Flatpickr things it can win by hammering their own classes... haha
            // for (let i = 0; i < document.getElementsByClassName('flatpickr-input').length; i++) {
                // document.getElementsByClassName('flatpickr-input')[i].style.border = '0';
                // document.getElementsByClassName('flatpickr-input')[i].style.background = '#F7F7F7';
                // document.getElementsByClassName('flatpickr-input')[i].style.padding = '8px 0.75rem';
            // }
        }, 1);
    },

    methods: {
        updateValue(event) {
            if (this.maxLength && event.target.value.length > this.maxLength) {
                event.target.value = event.target.value.substring(0, this.maxLength)
            }

            if (this.type === 'checkbox') {
                this.$emit('update:modelValue', event.target.checked);
            } else {
                this.$emit('update:modelValue', event.target.value);
            }
        },

        forceUpdateFlatpickr(value) {
            this.$refs.input._flatpickr.setDate(value, true);
        },

        startFlatpickr() {
            let types = [
                'date',
                'time',
                'datetime',
                'date_range',
                'month'
            ];

            if (!types.includes(this.type)) {
                return;
            }

            let format = ''
            let value = this.modelValue

            try {
                if (this.type === 'date' || this.type === 'date_range') {
                    format = 'd-m-Y';
                    console.debug('modelValue', this.modelValue)
                    value = this.modelValue ? DateTime.fromFormat(this.modelValue, 'dd-MM-yyyy').toFormat('dd-MM-yyyy') : undefined

                    // if (!value) {
                    //     value = DateTime.fromISO(this.modelValue).toFormat('dd-MM-yyyy')
                    // }

                    console.debug('value date', value)
                } else if (this.type === 'time') {
                    format = 'H:i';
                } else if (this.type === 'month') {
                    value = this.modelValue ? DateTime.fromISO(this.modelValue).toFormat('MM yyyy') : undefined
                } else {
                    format = 'd-m-Y H:i';
                    value = this.modelValue ? DateTime.fromISO(this.modelValue).toFormat('dd-MM-yyyy HH:mm') : undefined
                    console.debug('value datetime', value, this.modelValue)
                }

                new Flatpickr(this.$refs.input, {
                    enableTime: this.type === 'time' || this.type === 'datetime',
                    time_24hr: this.type === 'time' || this.type === 'datetime',
                    noCalendar: this.type === 'time',
                    altInput: true,
                    altFormat: format,
                    dateFormat: format,
                    minDate: this.minDate,
                    defaultDate: this.type === 'date_range' ? value + ' to ' + value : value,
                    mode: this.type === 'date_range' ? 'range' : 'single',
                    plugins: this.type === 'month' ? [
                        new monthSelectPlugin({
                            shorthand: true, //defaults to false
                            dateFormat: "m Y", //defaults to "F Y"
                            altFormat: "F Y", //defaults to "F Y"
                        })
                    ] : [],
                });

            } catch (e) {
                console.error(e)
            }
        }
    }
}
</script>

<style scoped>
.form-label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
}
/*
.form-control {
    background: #F7F7F7;
    border-radius: 5px;
    padding: 8px 0.75rem !important;
    font-size: 14px;
    border: 1px solid transparent !important;
}

.form-control:focus {
    border: none;
    background: #F7F7F7 !important;
    box-shadow: #2E77E44d 0px 0px 4px 1px !important;

}
 */

.form-control::placeholder {
    color: #adadad;
}
</style>
