<template>
	<div v-show="open">
		<transition name="modal">
			<div class="modal-mask">
				<div class="row justify-content-center align-items-center">
					<div class="col-lg-5">
						<div class="col-12 p-4 br-5 bg-white">
							<div class="modal-header mb-3">
								<h5 class="modal-title" style="font-size: 16px;">
									{{ title ?? 'Upload' }}
								</h5>

								<a style="font-size: 20px;" @class="close">
									<i class="mdi mdi-close" @click="close" />
								</a>
							</div>

							<div class="modal-body mb-4">
								<div class="box mb-0 shadow-none">
									<div
										v-show="phase === 'uploadPhase'"
										ref="uploadPhase"
										class="box-body p-0"
									>
										<h4 class="header-title text-center pb-1">
											Upload
										</h4>
										<p class="sub-header text-center pb-2">
											Carregue no botão abaixo ou arraste o ficheiro para fazer upload, depois pode recortar a imagem ou fazer upload diretamente.
										</p>

										<div
											id="dropzone"
											ref="dropzone"
											class="dropzone text-center px-4 py-5"
											@click="dropzoneClick($event)"
										>
											<div class="needsclick text-center pb-3">
												<h5 id="dropzoneMessage">
													Arraste o ficheiro para aqui ou clique em upload
												</h5>
											</div>
											<button type="button" class="btn btn-success px-4">
												Upload
											</button>
											<span v-if="errorToShow" class="text-danger font-weight-500 d-block pt-4"> <i class="text-danger mdi mdi-alert-circle-outline" /> {{ errorToShow }} </span>
											<input
												id="pictureModalInputFile"
												ref="pictureModalInput"
												class="hidden"
												style="opacity: 0; display: none;"
												type="file"
												name="image"
												accept="image/*"
												@change="fileUploaded(this)"
											>
										</div>
									</div>

									<div
										v-show="phase === 'cropPhase'"
										id="cropPhase"
										ref="cropPhase"
										class="box-body p-0"
									/>

									<div
										v-show="phase === 'submitPhase'"
										id="croppedImageContainer"
										ref="submitPhase"
										class="box-body p-0"
									/>
								</div>
							</div>

							<div class="modal-footer">
								<button
									type="button"
									class="btn btn-danger text-white me-1"
									@click="close"
								>
									<i class="fas fa-ban" /> Cancelar
								</button>

								<button
									v-show="imageRawFile && phase === 'uploadPhase'"
									type="button"
									class="btn btn-success me-1"
									@click="startCropPhase"
								>
									Avançar <i class="fas fa-arrow-right ms-1" />
								</button>

								<button
									v-show="phase === 'submitPhase' || phase === 'cropPhase'"
									type="button"
									class="btn btn-dark me-1"
									@click="restart"
								>
									Recomeçar <i class="fas fa-undo-alt ms-1" />
								</button>

								<button
									v-show="phase === 'cropPhase'"
									type="button"
									class="btn btn-success me-1"
									@click="cropPicture"
								>
									Recortar <i class="fas fa-crop-alt ms-1" />
								</button>

								<button
									v-show="phase === 'submitPhase'"
									type="button"
									class="btn btn-success"
									data-dismiss="modal"
									@click="finish"
								>
									<i class="fas fa-save" />Guardar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import Cropper from 'cropperjs';
export default {
    name: "ImageUploadModal",

    props: {
        open: Boolean,
        title: String,
    },

    data(){
        return {
            errorToShow: undefined,

            phase: 'uploadPhase',

            cropperInstance: undefined,
            dropzone: undefined,

            imageRawFile: undefined,
            imageBlob: undefined,
            imageUrl: undefined,

            finalImageUrl: undefined,
            finalImageBlob: undefined,

            errors: [],
        }
    },

    mounted() {
        this.startDropZone();
    },

    methods: {
        close() {
            this.$emit('update:open', false);
        },

        startDropZone() {
            const div = this.$refs.dropzone;

            // Determine if drag and drop functionality is capable in the browser
            this.dragAndDropCapable = this.isDragAndDropCapable();

            if (this.dragAndDropCapable) {
                ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(event => {
                    document.body.addEventListener(event, e => {
                        e.preventDefault();
                        e.stopPropagation();
                    })
                })

                div.addEventListener('drop', e => {
                    this.onFileDrop(e)
                })
            }

            this.isDragAndDropCapable();
        },

        isDragAndDropCapable() {
            const div = this.$refs.dropzone;
            return (('draggable' in div)
                    || ('ondragstart' in div && 'ondrop' in div))
                && 'FormData' in window
                && 'FileReader' in window
        },

        dropzoneClick(e) {
            $("#pictureModalInputFile").trigger('click');
        },

        validateFile(file){
             if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png") {
                this.errorToShow = 'Tipo de documento não suportado';
                return false;
            }

            if(file.size > 5000000){
                this.errorToShow = 'Ficheiro muito grande';
                return false;
            }

            return true;
        },

        onFileDrop: function (e) {
            //CHECK IF DATATRANSFER EXSISTS, IF IT DOES GET THE FILES FROM IT
            var DT = e.dataTransfer;

            if (DT != undefined) {
                var file = e.dataTransfer.files[0];
            } else {
                var file = e.target.files[0];
            }

            if(!this.validateFile(file)){
                return;
            }

            this.imageRawFile = file;
            this.dropzoneStylesOnFileUpload();
        },

        fileUploaded(ok) {
            try {
                this.errorToShow = undefined;
                var file = this.$refs.pictureModalInput.files[0];

                if(!this.validateFile(file)){
                    return;
                }
                this.imageRawFile = file;
            } catch (e) {
                this.errorToShow = 'Erro ao processar imagem';
            }

            this.dropzoneStylesOnFileUpload();
        },

        async startCropPhase() {
            this.phase = 'cropPhase';

            await this.transformFileToBlob();
            await this.getImageUrl();

            this.startCropper();
        },

        async transformFileToBlob() {
            const self = this;
            return new Promise(resolve => {
                var file = this.imageRawFile;
                var fileReader = new FileReader();

                fileReader.onloadend = function (e) {
                    self.imageBlob = e.target.result;
                    resolve(e.target.result);
                };
                fileReader.readAsBinaryString(file);
            });
        },

        async getImageUrl() {
            const self = this;
            return new Promise(resolve => {
                var fileReader = new FileReader();
                fileReader.onloadend = function () {
                    self.imageUrl = fileReader.result;
                    resolve(fileReader.result);
                };

                fileReader.readAsDataURL(this.imageRawFile);
            });
        },

        async startCropper() {
            const parent = $('#cropPhase');
            parent.empty();

            if(this.cropperInstance){
                this.cropperInstance = undefined;
            }

            $(this.$refs.cropPhase).append('<img id="imageToCrop" src="'+ this.imageUrl +'" class="border imageToCropClass">');
            let image = document.getElementById('imageToCrop');
            this.cropperInstance = new Cropper(image, {
                aspectRatio: 1 / 1,
                viewMode: 2,
            });
        },

        cropPicture() {
            this.phase = 'submitPhase';

            var cropped = this.cropperInstance.getCroppedCanvas();

            this.finalImageUrl = cropped.toDataURL('image/jpeg', 1);
            cropped.toBlob((blob) => {
                this.finalImageBlob = blob;
            }, 'image/jpeg', 1);

            $('#croppedImageContainer').html('<img class="imageCropped" src="'+ this.finalImageUrl +'">');
        },

        finish() {
            this.$emit('upload', this.finalImageBlob);
            this.$emit('update:open', false);
            this.restart();
        },

        restart() {
            this.phase = 'uploadPhase';

            this.cropperInstance = undefined;
            this.dropzone = undefined;
            this.imageRawFile = undefined;
            this.imageBlob = undefined;
            this.imageUrl = undefined;
            this.finalImageUrl = undefined;
            this.finalImageBlob = undefined;

            document.getElementById('pictureModalInputFile').value = null;

            $('#dropzoneIcon').removeClass('text-info').addClass('text-muted');
            $('#dropzoneMessage').text('Arraste o ficheiro para aqui ou clique em upload');
        },

        dropzoneStylesOnFileUpload() {
            $('#dropzoneIcon').removeClass('text-muted').addClass('text-info');
            $('#dropzoneMessage').text(this.imageRawFile.name);
        },
    },
}
</script>

<style>
    .dropzone {
        cursor: pointer;
        border: 2px dashed rgba(50, 58, 70, 0.3);
        background: #ffffff;
        border-radius: 6px;
    }

    .imageToCropClass{
        max-width: 100%;
        max-height: 500px;
    }

    /*I had ignored it*/
    /*Imagine reading documentation :^)*/
    img {
        max-width: 100%; /* This rule is very important, please do not ignore this! */
    }
</style>
