<template>
	<button
		:class="localClass"
		type="submit"
		@click="submit"
	>
		{{ label }}
	</button>
</template>

<script>
export default {
    name: "SubmitButton",
    props: {
        class: {
            type: String,
            required: false,
            default: "btn btn-primary w-100 fw-semibold default-box-prio-radius"
        },
        label: {
            type: String,
            required: false,
            default: "Submeter"
        }
    },

    emits: ['submit', 'update:class'],

    data(){
        return {
            loading: false,
            localClass: this.class
        }
    },

    methods: {
        submit(){
            /*this.toggleLoading();*/
            this.$emit("submit");
        },

        toggleLoading(forcedState = null){
            if (forcedState === null) {
                this.loading = !this.loading;
            } else {
                this.loading = forcedState;
            }

            this.localClass = this.loading ? this.class + " disabled" : this.class;
        }
    }
}
</script>

<style scoped>

</style>
