<template>
	<div>
		<form @submit.prevent="submit">
			<div class="d-flex flex-column w-100 gap-4 pt-4">
				<div class="col-12">
					<div class="row">
						<p class="fw-bold">
							Documento de  Identificação <br>
							<span class="color--grey font-22 mt-1">
								Cartão de cidadão ou passaporte
							</span>
						</p>
						<div class=" col-12">
							<div class="  col-12 col-md-4">
								<insert-image
									ref="cc_frente"
									v-model="form.CCFrente"
									:show-bottom-line="false"
									:show-top-line="false"
									:default-icon="'images/card-front.jpg'"
									:label="'Doc.Id (frente)'"
									:validation="participationStatus.cc_frente"
									required="required"
									accept="image/*,application/pdf"
									:failed="pErrors.hasOwnProperty('cc_frente')"
								/>
							</div>
						</div>
						<div class="col-12 mt-1">
							<div class="  col-12 col-md-4">
								<insert-image
									v-model="form.CCBack"
									:show-bottom-line="false"
									:show-top-line="false"
									:default-icon="'images/card-back.jpg'"
									:label="'Doc.Id (verso)'"
									:validation="participationStatus.cc_back"
									accept="image/*,application/pdf"
									:failed="pErrors.hasOwnProperty('cc_back')"
								/>
							</div>
						</div>
						<p class="fw-bold mt-4">
							Código de participação <br>
							<span class="color--grey font-22 mt-1">
								Foto ou scan do talão de participação
							</span>
						</p>
						<div class="col-12">
							<div class="  col-12 col-md-4">
								<insert-image
									ref="code_photo"
									v-model="form.CodePhoto"
									:show-bottom-line="false"
									:show-top-line="false"
									:default-icon="'images/card-front.jpg'"
									:label="'Talão c/código'"
									:validation="participationStatus.code_photo"
									required="required"
									accept="image/*,application/pdf"
									:failed="pErrors.hasOwnProperty('code_photo')"
								/>
							</div>
						</div>
						<p class="fw-bold mt-4">
							Fatura <br>
							<span class="color--grey font-22 mt-1">
								Foto ou scan da fatura da compra associada
							</span>
						</p>
						<div class="col-12">
							<div class=" col-12 col-md-4 ">
								<insert-image
									ref="bill_photo"
									v-model="form.BillPhoto"
									:show-bottom-line="false"
									:show-top-line="false"
									:default-icon="'images/card-front.jpg'"
									:label="'Fatura da compra'"
									:validation="participationStatus.bill_photo"
									required="required"
									accept="image/*,application/pdf"
									:failed="pErrors.hasOwnProperty('bill_photo')"
								/>
							</div>
						</div>

						<div class="col-12  ">
							<p class="fw-bold mt-4 mb-0">
								Morada e Código Postal <br>
								<span class="color--grey font-22 mt-1">
									Estes dados são obrigatórios para o envio do prémio
								</span>
							</p>
						</div>
						<div class="col-12  ">
							<div class="col-12 col-md-4 pt-3">
								<div class="row">
									<div class=" morada py-1 px-1 upload-each-row validator-input-row" :class="participationStatus.morada_validate">
										<input
											id="morada"
											v-model="form.morada_value"
											class="validator-input w-100"
											type="text"
											placeholder="Morada*"
											required
										>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12  ">
							<div class="col-12 col-md-4 pt-3">
								<div class="row">
									<div class="cp py-1 px-1 upload-each-row validator-input-row" :class="participationStatus.cp_validate">
										<input
											id="cp"
											v-model="form.cp_value"
											class="validator-input w-100"
											type="text"
											placeholder="Código Postal*"
											required
										>
									</div>
								</div>
							</div>
						</div>
						<!--                    <div class="col-12">
                        <div class=" col-12 col-md-4">
                            <insert-image
                                v-model="form.SignedContract"
                                :show-bottom-line="false"
                                :show-top-line="false"
                                :default-icon="'images/card-front.jpg'"
                                :label="'Declaração assinada'"
                                :validation="participationStatus.signed_contract"

                            />
                        </div>
                    </div>-->
						<p class="fw-bold mt-4 mb-0">
							Declaração de aceitação do prémio (Ler) <br>
							<span class="color--grey font-22 mt-1">
								Tem de aceitar os termos para poder receber o prémio
							</span>
						</p>
						<div class="col-12 col-md-4 pt-1">
							<div class="form-check acceptedterms-wrap">
								<input
									id="acceptedterms"
									v-model="form.accepted_terms"
									class="form-check-input"
									type="checkbox"
									value="false"
									required
								>
								<label class="form-check-label" for="acceptedterms">
									Li e concordo com os <!--<a
										data-bs-toggle="modal"
										data-bs-target="#declaracaoModal"
										class="link-blue"
									>termos da declaração</a> -->
									<a
										:href="route('declaracao-aceitacao')"
										class="link-blue"
										target="_blank"
									>termos da declaração</a> de aceitação desta participação.
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4 mt-3">
					<p v-if="defaultForm.blocked === 'blocked'">
						Estamos a validar os seus dados. Submissão de comprovativos bloqueada.
						Por favor tente mais tarde
					</p>
					<template v-if="turnstileEnabled && useTurnstile">
						<turnstile
							v-model="form.cfResponse"
							:site-key="siteKey"
							appearance="interaction-only"
							:placeholder="false"
						/>
					</template>
					<SubmitButton
						v-if="form.blocked !== 'blocked'"
						ref="submitButton"
						label="Enviar"
						class="btn btn-primary btn-primary-yellow w-100 fw-semibold default-box-prio-radius"
					/>
					<SubmitButton
						v-else
						ref="submitButton"
						label="Enviar"
						class="btn btn-primary btn-primary-yellow w-100 fw-semibold default-box-prio-radius"
						disabled
					/>
				</div>
			</div>
			<div
				id="declaracaoModal"
				class="modal fade"
				tabindex="-1"
				aria-labelledby="declaracaoModal"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">
								Declaração de aceitação
							</h5>
							<div
								style="width: 20px; height: 20px"
								class="btn-close cursor-pointer"
								data-bs-dismiss="modal"
								aria-label="Close"
							/>
						</div>


						<div class="modal-body">
							<div class="col-12">
								Lorem ipsum dolor sit amet consectetur. Pharetra quis mattis at in amet venenatis vel.
								Nisi viverra sit in ullamcorper sed nisl integer. Mi sodales nibh dignissim ipsum dolor
								quam ornare amet scelerisque. Donec praesent pellentesque magna nunc consequat nullam
								est arcu arcu. Libero egestas mauris suspendisse nibh convallis adipiscing nibh.
								Faucibus sed pellentesque in faucibus. Lorem scelerisque adipiscing pretium elementum
								duis porta nisi. Sagittis sagittis sagittis eu enim feugiat nunc aliquet. Urna odio sed
								interdum amet viverra non et nibh. Eget lobortis enim rhoncus ut vel turpis scelerisque.
								Interdum eget augue habitasse non ullamcorper. Sit fringilla vitae diam enim feugiat
								est.
								Congue leo sed a a ipsum in molestie. Egestas at cursus nam nullam tempus a. Tincidunt
								egestas dolor elementum scelerisque porta sollicitudin. Nulla bibendum gravida ut congue
								erat velit. Suspendisse in neque sed dolor est. Egestas accumsan sodales mattis ac
								accumsan mattis molestie. Sed quis semper sagittis libero consequat molestie eget. Vel
								ultrices amet congue pulvinar eget porttitor fermentum. Ultrices lorem ornare tristique
								urna semper purus at vitae donec. Sodales cras adipiscing suspendisse velit sollicitudin
								sed.
							</div>
						</div>

						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-primary btn-primary-yellow w-100 fw-semibold default-box-prio-radius"
								data-bs-dismiss="modal"
							>
								Fechar
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import {useForm} from "@inertiajs/vue3";
import SubmitButton from "../../Components/SubmitButton.vue";
import InsertImage from "../../Components/InsertImageNew.vue";
import Turnstile from "@any-design/turnstile";

const SITE_KEY = import.meta.env.VITE_TURNSTILE_SITE_KEY;
const TURNSTILE_ENABLED = import.meta.env.VITE_TURNSTILE_ENABLED === 'true';


export default {
    name: "VForm",
    components: {
        InsertImage,
        SubmitButton,
        Turnstile,
    },

    props: {
        defaultForm: {
            type: Object,
            required: true,
        },
        participation: {
            type: Object,
            required: true,
        },
        participationStatus: {
            type: Object,
            required: true,
        },
        submitRoute: {
            type: String,
            required: true,
        },
        errors: {
            type: Object,
            required: false,
            default: undefined
        },
        type: {
            type: String,
            required: true,
            default: undefined
        },
        useTurnstile: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            form: useForm({...this.defaultForm, cfResponse: null}),
            permissions: [],
            locales: [
                {name: 'Português', id: 'pt'},
                {name: 'Inglês', id: 'en'},
            ],
            pErrors: {},
            siteKey: SITE_KEY,
            turnstileEnabled: TURNSTILE_ENABLED,
        }
    },
    created() {
        this.pErrors = this.errors
        this.form.type = this.type
    },

    beforeMount() {
        try {
            this.form.locale = JSON.parse(localStorage.getItem('full-locale') || [])
        } catch (e) {
            localStorage.removeItem('full-locale')
        }
    },

    mounted() {
        //Proof of concept to set the default value of the multiselect from the id
        if (this.$refs['is_active']) {
            this.$refs['is_active'].options.forEach((option) => {
                if (option.id === this.form.is_active) {
                    this.form.is_active = option
                }
            })
        }

    },

    methods: {
        submit() {
            this.pErrors = {};

            if (this.form.locale) {
                localStorage.setItem('locale', this.form.locale.id)
                localStorage.setItem('full-locale', JSON.stringify(this.form.locale))
            }

            console.debug('submitting form', this.form)

            this.$refs.cc_frente.errorToShow = null;
            this.$refs.code_photo.errorToShow = null;
            this.$refs.bill_photo.errorToShow = null;

            let toCheck = [
                null,
                'Recusado',
                'null',
                '',
            ]

            if (toCheck.includes(this.participationStatus.cc_frente) && !this.form.CCFrente.image) {
                this.pErrors.cc_frente = 'Documento de identificação é obrigatório';
                this.$refs.cc_frente.errorToShow = 'Documento de identificação é obrigatório';
            }

            if (toCheck.includes(this.participationStatus.code_photo) && !this.form.CodePhoto.image) {
                this.pErrors.code_photo = 'Código de participação é obrigatório';
                this.$refs.code_photo.errorToShow = 'Código de participação é obrigatório';
            }

            if (toCheck.includes(this.participationStatus.bill_photo) && !this.form.BillPhoto.image) {
                this.pErrors.bill_photo = 'Fatura é obrigatória';
                this.$refs.bill_photo.errorToShow = 'Fatura é obrigatória';
            }

            if (Object.keys(this.pErrors).length > 0) {
                return;
            }

            this.form.post(this.submitRoute, {
                preserveState: true,
                onSuccess: () => {

                    /*window.location.reload()*/
                    this.$refs.submitButton?.toggleLoading(false)

                },
                onError: (e) => {
                    toastError('Ocorreu um erro ao submeter os ficheiros... Por favor tente mais tarde.')
                    this.pErrors = e
                    this.$refs.submitButton?.toggleLoading(false)
                },
                onFinish: () => {
                    this.$refs.submitButton?.toggleLoading(false)
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
