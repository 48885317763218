<template>
	<fake-modal-layout>
		<meta-head title="Política de Cookies" />

		<template #link>
			<a href="https://ganhaecodiesel.pt" class="fw-bold">
				<i class="mdi mdi-chevron-left pr-1" />
				Voltar a ganhaecodiesel.pt
			</a>
		</template>
		<div class="container">
			<div class="col-12  p-1 py-md-4 px-md-4 doc-pages">
				<div class="row">
					<div class="col-12 pt-4">
						<h4 class="fw-bold text-center">
							Política de Cookies
						</h4>
						<section>
							<h5>1. COOKIES, O QUE SÃO?</h5>
							<p>
								Os cookies são ficheiros de software que as páginas web ou aplicações instalam nos browsers ou nos dispositivos
								dos utilizadores enquanto estes navegam nas diferentes páginas ou aplicações e que são utilizados para
								armazenar informações sobre as visitas dos utilizadores. A utilização de cookies permite a otimização da
								navegação, através da adaptação das informações e dos serviços oferecidos aos interesses dos utilizadores, de
								modo a personalizar, adaptar e melhorar a experiência de navegação dos utilizadores de cada vez que visitam a
								página web.
							</p>

							<p>Como a maioria das páginas web, esta página recorre a cookies para:</p>

							<ol>
								<li>Certificar-se de que as páginas estão a funcionar corretamente;</li>
								<li>Armazenar as preferências do utilizador, assim como o idioma selecionado e o tamanho da fonte;</li>
								<li>Descobrir os detalhes da experiência de navegação do utilizador;</li>
								<li>
									Recolher informação estatística anónima, como por exemplo, as páginas que o utilizador visita e quanto tempo
									permanece em cada página.
								</li>
							</ol>
						</section>

						<section>
							<h5>2. TIPO, FINALIDADE E COMO FUNCIONAM</h5>
							<p>
								Os cookies, dependendo do período de tempo que o utilizador permanece na página, podem ser classificados como
								(i) de sessão ou (ii) cookies permanentes. O primeiro tipo de cookies expira quando o utilizador fecha o
								browser. O segundo tipo de cookies expira quando o seu objetivo foi concluído (por exemplo, identificar o
								utilizador na página web) ou quando são desligados manualmente.
							</p>

							<p>Além disso, dependendo da sua finalidade, os cookies podem ser classificados como:</p>

							<dl>
								<dt><strong>Estritamente necessários (registo):</strong></dt>
								<dd>
									Essenciais para a página web funcionar corretamente. Normalmente são gerados quando os utilizadores acedem à
									página web ou fazem login, sendo utilizados para a identificação dos utilizadores na página web.
								</dd>

								<dt><strong>Funcionais:</strong></dt>
								<dd>
									Utilizados para melhorar a experiência de navegação e otimizar a página web, como por exemplo, armazenar as
									configurações do serviço e as compras feitas num carrinho de compras.
								</dd>
								<!-- Add more list items as needed -->
							</dl>
						</section>

						<section>
							<h5>3. DESATIVAR A UTILIZAÇÃO DE COOKIES</h5>
							<p>
								Todos os browsers permitem que o utilizador aceite, recuse ou apague os cookies, nomeadamente através da
								seleção das configurações apropriadas no seu browser. O utilizador pode configurar os cookies no menu “Opções”
								ou “Preferências” do seu browser.
							</p>

							<p>
								No entanto, é importante destacar que a desativação dos cookies pode impedir que alguns serviços da web
								funcionem corretamente, afetando, total ou parcialmente, a navegação na página web.
							</p>

							<p>
								Os utilizadores podem desativar a utilização dos cookies nesta página web a qualquer momento ao modificar as
								configurações do browser, por exemplo:
							</p>

							<ul>
								<li>
									<a href="http://support.google.com/chrome/bin/answer.py?hl=pt&answer=95647" target="_blank">Google
										Chrome</a>
								</li>
								<li>
									<a
										href="http://windows.microsoft.com/pt-pt/windows7/how-to-manage-cookies-in-internet-explorer-9"
										target="_blank"
									>Internet Explorer</a>
								</li>
								<li>
									<a href="https://support.mozilla.org/pt-PT/kb/Activar%20e%20Desactivar%20cookies" target="_blank">Mozilla
										Firefox</a>
								</li>
								<li><a href="http://support.apple.com/kb/ph5042" target="_blank">Apple Safari</a></li>
							</ul>

							<p>
								Outras ferramentas de terceiros, disponíveis online, permitem que os utilizadores detetem os cookies de todas
								as páginas web que visitam e façam a gestão da sua desativação, por exemplo, <a
									href="http://www.ghostery.com/privacy-statement"
									target="_blank"
								>Ghostery</a>.
							</p>
						</section>

						<section>
							<h5>4. O QUE ACONTECE QUANDO OS COOKIES ESTÃO DESATIVADOS?</h5>
							<p>
								Algumas funções e serviços podem parar de funcionar ou comportar-se de forma inesperada, como por exemplo, a
								identificação do utilizador em determinadas páginas ou receber informações que têm em consideração a
								localização do utilizador, entre outros.
							</p>

							<p>
								Se desativar os cookies desta página web, é provável que não tenha acesso a determinadas áreas ou que a
								qualidade da sua experiência de navegação seja consideravelmente inferior.
							</p>
						</section>

						<section>
							<h5>5. OS COOKIES UTILIZADOS NESTA PÁGINA</h5>
							<p>
								O website da PRIO utiliza os seguintes cookies:
							</p>

							<ul>
								<li>Cookies necessários</li>
								<li>Cookies funcionais</li>
								<li>Cookies publicitários</li>
								<li>Cookies de geolocalização</li>
								<li>Cookies analíticos</li>
								<li>Cookies de personalização</li>
							</ul>

							<p>Poderás consultar mais informação acerca destes cookies no ponto número 2 da presente Política de Cookies.</p>
						</section>

						<section>
							<h5>6. GARANTIAS ADICIONAIS E REVOGAÇÃO DA ACEITAÇÃO</h5>
							<p>
								Como garantia adicional, a gravação dos cookies nesta página web pode estar sujeita a que o utilizador aceite
								os cookies durante a sua visita à página web e à instalação ou atualização do browser utilizado. Esta aceitação
								pode ser revogada a qualquer momento nas configurações do conteúdo e privacidade, tal como definido acima no
								ponto 3 da presente política ou utilizando a hiperligação na parte inferior desta página.
							</p>
						</section>

						<section>
							<h5>7. ATUALIZAÇÃO DA POLÍTICA DE COOKIES</h5>
							<p>
								A PRIO pode modificar esta política de cookies em conformidade com os requisitos legais ou regulamentares ou
								adaptar esta política a novas instruções decretadas por lei. Quando existam modificações significativas a
								esta política de cookies, os utilizadores da página web serão informados.
							</p>
						</section>

						<section>
							<h5>8. ENCARREGADO DA PROTEÇÃO DE DADOS</h5>
							<p>
								António Veiga <br>
								Encarregado de Proteção de Dados <br>
								Telefone: +351 234 390 010 <br>
								E-mail: dpo@prioenergy.com <br>
								Morada: TGL – Terminal de Granéis Líquidos <br>
								Porto de Aveiro – Lote B <br>
								3834 – 908 Gafanha da Nazaré <br>
								Ílhavo, Portugal
							</p>
						</section>
					</div>
					<!--            <div class="col-12 d-flex justify-content-center" >
                    <div class="col-auto d-flex text-center justify-content-center" style="width:200px; border-bottom: 1px solid black;padding-bottom: 30px">
                        Assinatura
                    </div>
            </div>-->
				</div>
			</div>
		</div>
	</fake-modal-layout>
</template>

<script>
import FakeModalLayout from "../../Shared/FakeModalLayout.vue";
import { Head as MetaHead } from "@inertiajs/vue3";

export default {
    name: "CookiesPolicy",

    components: {
        FakeModalLayout,
        MetaHead
    },
    methods: {
        printPage() {
            window.print();
        },
    },
}
</script>
