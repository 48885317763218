<template>
	<fake-modal-layout :url-back="route('contests.myParticipations')">
		<MetaHead title="Envio de comprovativos" />
		<div class="col-12  p-1 py-md-4 px-md-4 ">
			<div class="row">
				<div class="col-12 pt-4">
					<div
						class=" col-12 col-md-auto d-flex pb-2  justify-content-between"
					>
						<div class="select-card-code-info-label">
							Código de participação
						</div>
						<div class="select-card-code-info-value fw-bold">
							{{ participation.code }}
						</div>
					</div>
					<div class="col-12  d-flex pb-2 each-validation-wrapper Recusado">
						<div class="row d-flex col-12">
							<h4 class="fw-bold col-auto mb-0">
								Envio de comprovativos
							</h4>
							<div
								v-if="defaultForm.blocked === 'blocked'"
								style="margin-left: auto;"
								class="d-flex col-auto badge badge-pill each-validation-wrapper-badge justify-self-end"
							>
								Bloqueado
							</div>
						</div>
					</div>
					<p v-if="defaultForm.blocked === 'blocked'">
						Estamos a validar os seus dados. Submissão de comprovativos bloqueada.
						Por favor tente mais tarde
					</p>
					<p>
						Envia-nos os seguintes documentos comprovativos: Documento de identificação, cópia do talão do
						concurso e cópia da fatura para poderes levantar o teu prémio. Após a validação dos documentos
						entraremos em contacto contigo.
					</p>
				</div>
				<div class="col-12 ">
					<div class="row">
						<v-form
							:default-form="defaultForm"
							:participation="participation"
							:participation-status="participationStatus"
							:submit-route="route('contests.myParticipations.updatefiles', [participation.uuid, participationStatus.uuid])"
							:errors="errors"
							type="edit"
						/>
					</div>
				</div>
			</div>
		</div>
	</fake-modal-layout>
</template>

<script>
import FakeModalLayout from "../../Shared/FakeModalLayout.vue";
import VForm from "./ParticipationStatusForm.vue"
import {Head as MetaHead} from "@inertiajs/vue3";

export default {
    name: "ParticipationStatusFiles",

    components: {
        VForm,
        FakeModalLayout,
        MetaHead,
    },

    props: {
        defaultForm: {
            type: Object,
            required: true,
        },
        participation: {
            type: Object,
            required: true,
        },
        participationStatus: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: false,
            default: () => {
            }
        }
    },
}
</script>
<script setup>


//define layout
</script>
<style>


</style>
