<template>
	<div
		class="col-12  FakeModalLayout"

		style="height: 100%; overflow-x: hidden"
	>
		<div class="topbar col-12 ">
			<div class="row justify-content-end">
				<div class="col-auto pt-2">
					<slot name="link">
						<Link
							:href="urlBack"
							class="fw-bold"
						>
							<i class="mdi mdi-chevron-left pr-1" /> Voltar
						</Link>
					</slot>
				</div>
			</div>
		</div>
		<div class="row ">
			<div class="main-container">
				<div class="container">
					<div class="col-12 default-bland-back">
						<div class="row align-items-center justify-content-center min-vh-100">
							<div style=" max-width: 100%" class="col-auto col-md-12">
								<div class="" />
								<div class="col-12   br-10 rounded-2">
									<slot />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
    name: "FakeModalLayout",
    props: {
        urlBack: {
            type: String,
            default: 'https://ganhaecodiesel.pt'
        }
    },
    watch: {
        '$page.props.toast_message': function (newVal) {
            if (newVal) {
                //With every request I send a _timestamp, so I know when to show the toast even if the message didn't change
                const toast_message = newVal.substring(0, newVal.lastIndexOf('_'))
                switch (this.$page.props.toast_type) {
                    case 'success':
                        toast.success(toast_message)
                        break;
                    case 'error':
                        toast.error(toast_message)
                        break;
                    case 'warning':
                        toast.warning(toast_message)
                        break;
                    default:
                        toast.info(toast_message)
                        break;
                }
            }
        },
        '$page.props.errors': function (newVal) {
            if (newVal) {
                try {
                    for (const [key, value] of Object.entries(newVal)) {
                        this.$refs[key].classList?.add('is-invalid')
                        toast.error(value)
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        },
    },
    mounted() {
        if(this.$page.props.toast_message){
            const toast_message = this.$page.props.toast_message.substring(0, this.$page.props.toast_message.lastIndexOf('_'));
            this.displayMessage(toast_message, this.$page.props.toast_type);
        }
    },
}
</script>

<style scoped>
.main-container {
    background-color: #f5f5f5;
}

.myNavbar {
    grid-column: 1;
    grid-row: 2;
    padding: 20px;
    overflow: hidden;


}

@media only screen and (min-width: 992px) {
    .main-container.main-containerOpen {
        padding-left: 300px;
    }
}

.myNavbar.contestentNavbarOpen {
    position: absolute;
    min-height: calc(100vh - 60px);
    min-width: 300px;
    z-index: 999;

}

.myNavbar .eachLink {
    display: block;
    padding: 10px;
    transition: all 0.2s;
    border-radius: 10px;
    font-weight: 600;
}

.myNavbar .eachLink .eachLinkInside {
    display: flex;
    align-items: center;
}

.myNavbar .eachLink .eachLinkInside .mdi {
    line-height: 0;
    font-size: 1.3rem;
    margin-right: 10px;
}

</style>
