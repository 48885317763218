<template>
	<fake-modal-layout>
		<meta-head title="Termos e Condições" />
		<template #link>
			<a href="https://ganhaecodiesel.pt" class="fw-bold">
				<i class="mdi mdi-chevron-left pr-1" />
				Voltar a ganhaecodiesel.pt
			</a>
		</template>
		<div class="container">
			<div class="row" />
			<div class="col-12  p-1 py-md-4 px-md-4 ">
				<div class="row">
					<div class="col-12 pt-4">
						<h4 class="fw-bold text-center">
							CONCURSO n.º 308/2023 <br> “VOLTA AO MUNDO EM ECO DIESEL”, <br> autorizado pela
							Câmara Municipal de Ílhavo.
						</h4>
						<section>
							<h5>1. Objeto e duração</h5>
							<p>
								1.1. O CONCURSO “Volta ao mundo em ECO Diesel” (doravante, abreviadamente, “CONCURSO”)
								é promovido pela PRIO Energy S.A, (doravante, abreviadamente, “PRIO”), com sede no TGL –
								Terminal
								de Granéis Líquidos, Porto de Aveiro – Lote B, 3834 – 908 Gafanha da Nazaré e tem como
								finalidade a
								atribuição de prémios em “PRIO ECO Diesel” nos postos de abastecimento da marca PRIO
								aderentes.
							</p>
							<p>
								1.2. Nos termos do presente regulamento, a PRIO, de 3 a 31 de janeiro, atribuirá aos
								vencedores do
								CONCURSO, dez prémios diários de € 50, quatro prémios semanais de € 500 e um grande
								prémio final de
								€ 5.000 (adiante, “GRANDE PRÉMIO”), todos em PRIO ECO Diesel.
							</p>
							<p>
								1.3. O GRANDE PRÉMIO, no valor de 5.000,00€ (cinco mil euros) em cartão para aquisição
								de PRIO
								ECO Diesel, foi definido considerando os quilómetros necessários para realizar uma volta
								ao mundo
								(40.000km), tendo por base as médias de consumo do carro mais vendido em Portugal na
								última década –
								o Renault Clio.
							</p>
							<p>
								1.4. O presente regulamento disciplina a forma de inscrição no CONCURSO e de seleção do
								vencedor,
								bem como a forma de atribuição e entrega dos prémios.
							</p>
							<p>1.5. A gestão do CONCURSO está a cargo da SOCIPRIME - FIELD MERCHANDISING S.A.</p>
							<p>1.6. O CONCURSO vigorará entre os dias 3 e 31 de janeiro de 2024.</p>
						</section>
						<section>
							<h5>2. Prémios</h5>
							<p>
								2.1. O CONCURSO atribuirá um valor global líquido de € 21.500,00 (vinte e um mil e
								quinhentos
								euros) em PRIO ECO Diesel, repartidos por 295 (duzentos e noventa e cinco) prémios,
								distribuídos da
								seguinte forma:
							</p>
							<ul>
								<li>
									<p>
										a) 10 prémios por dia, no valor de 50,00€ (cinquenta euros) cada, para aquisição
										de combustível
										PRIO ECO Diesel, perfazendo um total de 290 (duzentos e noventa) prémios,
										repartidos por 29
										dias e sorteios;
									</p>
								</li>
								<li>
									<p>
										b) 4 prémios semanais, no valor 500,00€ (quinhentos euros) cada, em cartão,
										para aquisição de
										combustível PRIO ECO Diesel, repartidos por 4 semanas consecutivas;
									</p>
								</li>
								<li>
									<p>
										c) 1 GRANDE PRÉMIO, no valor de 5.000,00€ (cinco mil euros) em cartão, para aquisição de
										combustível
										PRIO ECO Diesel.
									</p>
								</li>
							</ul>
							<p>
								2.2. Os prémios referidos na al. a) do número anterior serão entregues através de 290
								vouchers, em
								formato impresso.
							</p>
							<p>
								2.3. Os prémios referidos nas alíneas a) e b) do número 2.1., serão entregues através de
								um cartão
								disponibilizado pela PRIO para o efeito.
							</p>
							<p>
								2.4. Os prémios não poderão ser convertidos em dinheiro e são, única e exclusivamente,
								para aquisição
								de combustível PRIO ECO Diesel, nos postos aderentes, e nos períodos que venham a ser
								determinados
								pela PRIO para o efeito.
							</p>
							<p>
								2.5. A PRIO reserva-se ao direito de, se assim o entender, entregar o prémio referido na
								al. c) do ponto
								2.1. deste regulamento, presencialmente.
							</p>
							<p>
								2.6. Na sequência da entrega do prémio referida no número anterior, o participante
								concorda ceder,
								com caráter exclusivo à PRIO, todos os seus direitos sobre as fotografias, sejam eles
								direitos de imagem,
								direitos de autor, ou quaisquer outros, para que a PRIO possa utilizar as fotografias,
								entre outros, para
								divulgação da entrega do prémio, não ficando a PRIO obrigada a qualquer contrapartida, a
								título de
								remuneração, compensação ou outro.
							</p>
							<!-- Add more paragraphs as needed -->
						</section>
						<section>
							<h5>3. Condições e elegibilidade</h5>
							<p>
								3.1. Sem prejuízo do disposto no número seguinte, o CONCURSO destina-se a todas as pessoas
								singulares que reúnam, cumulativamente, as seguintes condições:
							</p>
							<ul>
								<li>
									<p>
										a) tenham idade igual ou superior a 18 anos;
									</p>
								</li>
								<li>
									<p>
										b) sejam residentes em Portugal Continental;
									</p>
								</li>
								<li>
									<p>
										c) abasteçam, de uma única vez, entre os dias 3 e 31 de janeiro de 2024, valor igual ou superior a
										€ 30,00 (trinta euros) em combustível “PRIO ECO Diesel”, nos postos PRIO aderentes à
										campanha;
									</p>
								</li>
								<li>
									<p>
										d) não paguem o abastecimento referido no número anterior através de qualquer tipo de cartão
										frota, terminais de pagamento automático (OPT’s) ou APP PRIO.GO.
									</p>
								</li>
							</ul>
							<p>
								3.2. Não serão admitidos ao CONCURSO, sócios, administradores, empregados e respetivos cônjuges
								e familiares da entidade gestora do CONCURSO (SOCIPRIME - FIELD MERCHANDISING S.A.), bem
								assim como trabalhadores da entidade promotora (PRIO Energy S.A.), que estejam diretamente
								envolvidos
								nesta campanha.
							</p>
							<p>
								3.3. A PRIO reserva-se o direito de excluir, sem necessidade de aviso prévio, qualquer participante
								que:
							</p>
							<ul>
								<li>
									<p>
										a) ao abrigo do presente CONCURSO forneça informação falsa, imprecisa ou incompleta;
									</p>
								</li>
								<li>
									<p>
										b) incumprimento do disposto nos números 6.2. e 6.3. do presente regulamento;
									</p>
								</li>
								<li>
									<p>
										c) ao abrigo do n.º 7.5. do presente regulamento;
									</p>
								</li>
								<li>
									<p>
										d) de qualquer outra forma viole o presente regulamento;
									</p>
								</li>
							</ul>
							<p>
								3.4. Sem prejuízo do respetivo procedimento criminal, toda e qualquer atuação ilícita e ilegal para
								obter
								qualquer vantagem competitiva no decorrer deste CONCURSO será considerada fraudulenta, perdendo, o
								concorrente, o direito a todas as participações efetuadas, bem como qualquer prémio que lhe tenha
								sido
								atribuído.
							</p>
							<p>
								3.5. Desde que cumprido o disposto no n.º 3.1. supra, cada participante poderá concorrer com mais do
								que um código único de participação (adiante, “CUP”), sendo cada um destes considerado
								individualmente
								nos termos do presente regulamento, nomeadamente para efeitos do n.º 5.8. infra.
							</p>
							<!-- Add more paragraphs as needed -->
						</section>
						<section>
							<h5>4. Procedimento</h5>
							<p>
								4.1. Dentro do período referido no n.º 4.7. do presente regulamento, todos os talões com abastecimentos
								únicos de valor igual ou superior a €30 em PRIO ECO Diesel, conterão um CUP, composto por 13 dígitos,
								que permitirá a participação no CONCURSO.
							</p>
							<p>
								4.2. A participação no CONCURSO está condicionada à criação de uma conta no endereço
								https://www.ganhaecodiesel.pt, onde o cliente terá, obrigatoriamente, de inserir o seu nome completo,
								endereço de correio eletrónico, bem como aceitar os termos e condições e a política de privacidade.
							</p>
							<p>
								4.3. Após o procedimento referido número anterior, o cliente receberá um e-mail para o endereço de
								correio eletrónico indicado, com um link para verificação da sua conta.
							</p>
							<p>
								4.4. Só após a suprarreferida verificação de conta, é que o cliente poderá submeter o(s) seu(s) CUP(s),
								acompanhado(s) pelo(s) número(s) de talão de compra/fatura.
							</p>
							<p>
								4.5. Não será considerada a participação de nenhum cliente que não tenha cumprido, integralmente, os
								procedimentos dispostos nos números anteriores.
							</p>
							<p>
								4.6. Cada CUP corresponde a um único número de talão e só poderá ser utilizado uma única vez.
							</p>
							<p>
								4.7. A emissão dos CUP’s ocorrerá, única e exclusivamente entre as 00:00:01 horas do dia 3 de janeiro
								de 2024 e as 23:59:59 horas de 31 de janeiro de 2024.
							</p>
							<!-- Add more paragraphs as needed -->
						</section>
						<section>
							<h5>5. Sorteio</h5>
							<p>
								5.1. Todos os sorteios realizar-se-ão automaticamente, por via eletrónica, através do endereço referido
								no n.º 4.2. do presente regulamento.
							</p>
							<p>
								5.2. Os vencedores dos prémios referidos na al. a) do n.º 2.1. do presente regulamento, serão apurados
								semanalmente, através da seleção 10 (dez) premiados e 20 (vinte) suplentes diários, considerando-se, para
								tal, única e exclusivamente, os códigos válidos e registados nos seguintes dias:
							</p>
							<ul>
								<li><p>a) 3, 4, 5, 6, 7, 8, 9 e 10 de janeiro – o sorteio a realizar-se-á a 11/01/2024;</p></li>
								<li><p>b) 11, 12, 13, 14, 15, 16 e 17 de janeiro – o sorteio a realizar-se-á a 18/01/2024;</p></li>
								<li><p>c) 18, 19, 20, 21, 22, 23 e 24 de janeiro – o sorteio a realizar-se-á a 25/01/2024;</p></li>
								<li><p>d) 25, 26, 27, 28, 29, 30 e 31 de janeiro – o sorteio a realizar-se-á a 01/02/2024;</p></li>
							</ul>
							<p>
								5.3. Os vencedores dos prémios referidos na al. b) do n.º 2.1. do presente regulamento, serão apurados
								semanalmente, através da seleção de 1 (um) premiado e 3 (três) suplentes por semana, nos seguintes termos:
							</p>
							<ul>
								<li>
									<p>
										a) Semana 1 – o sorteio a realizar-se-á a 11/01/2024, considerando as participações registadas entre
										as 00:00h do dia 03/01/2024 e as 23:59:59h do dia 10/01/2024;
									</p>
								</li>
								<li>
									<p>
										b) Semana 2 - o sorteio a realizar-se-á a 18/01/2024, considerando as participações registadas entre
										as 00:00h do dia 11/01/2024 a as 23:59:59h do dia 17/01/2024;
									</p>
								</li>
								<li>
									<p>
										c) Semana 3 o sorteio a realizar-se-á a 25/01/2024 considerando as participações registadas entre
										as 00:00h do dia 18/01/2024 a 23:59:59 do dia 24/01/2024;
									</p>
								</li>
								<li>
									<p>
										d) Semana 4 - Sorteio a realizar-se a 01/2/2024 considerando as participações registadas entre as
										00:00h do dia 25/01/2024 a 23:59:59 do dia 31/01/2024,
									</p>
								</li>
							</ul>
							<p>
								5.4. O vencedor do GRANDE PRÉMIO referido na al. c) do n.º 2.1. do presente regulamento, será
								apurado no dia 8 de fevereiro de 2024, considerando todas as participações válidas registadas entre o dia 3
								de janeiro de 2024 e 1 de fevereiro de 2024.
							</p>
							<p>
								5.5. A PRIO, não poderá, em caso algum, ser responsabilizada em caso de perda, roubo ou danificação
								dos respetivos vouchers ou cartões.
							</p>
							<p>
								5.6. Serão sorteados 20 suplentes por cada 10 prémios diários referidos na al. a) do n.º 2.1. do presente
								regulamento; 2 suplentes por cada um dos prémios identificados na al. b) do n.º 2.1. do presente
								regulamento; e 2 suplentes para o GRANDE PRÉMIO.
							</p>
							<p>
								5.7. Os suplentes integrarão sempre uma lista unitária, ordenada pelo sistema, por cada sorteio.
							</p>
							<p>
								5.8. Cada CUP válido e corretamente registado, habilita o participante ao sorteio diário correspondente
								ao dia em que efetuou o registo, ao sorteio semanal correspondente à mesma semana e ao GRANDE
								PRÉMIO.
							</p>

							<!-- Add more paragraphs as needed -->
						</section>
						<section>
							<h5>6. Contacto e atribuição dos Prémios</h5>
							<p>
								6.1. Os concorrentes sorteados e, caso se aplique, os suplentes chamados em substituição daqueles,
								serão contactados por e-mail ou SMS.
							</p>
							<p>
								6.2. Após esse contacto, os sorteados estão obrigados a, no prazo máximo de 2 (dois) dias úteis, validar
								a sua participação pelo envio, através da área reservada utilizada para a candidatura, dos seguintes
								documentos:
							</p>
							<ul>
								<li><p>Cópia do Documento de Identificação Civil, ou documento equiparado (frente e verso);</p></li>
								<li><p>Cópia completa do talão de compra/fatura.</p></li>
								<li><p>Cópia do CUP anexo ao talão de compra.</p></li>
							</ul>
							<p>
								6.3. Todos os documentos remetidos devem estar totalmente legíveis, nomeadamente o CUP, sob pena
								de exclusão do participante sorteado.
							</p>
							<p>
								6.4. Após a validação da documentação enviada, os vencedores serão contactados, telefonicamente,
								para o contacto inserido na participação, no prazo máximo de 7 (sete) dias úteis, a contar do envio dos
								documentos referidos no n.º 6.2.
							</p>
							<p>
								6.5. Os suplentes são contactados pela ordem definida pelo sistema.
							</p>
							<p>
								6.6. Não serão informados ou contactados os participantes que, tendo sido sorteados como suplentes,
								não venham a ser elegíveis para receber os respetivos prémios.
							</p>
							<p>
								6.7. Posteriormente ao envio e receção dos cartões PRIO ECO Diesel, os vencedores receberão o
								respetivo PIN para ativar o seu cartão.
							</p>
							<p>
								6.8. Os vouchers e cartões de pagamento terão uma validade temporal limitada podendo ser utilizados,
								única e exclusivamente, para efetuar pagamentos de combustível PRIO ECO Diesel nos postos de
								abastecimento PRIO aderentes, salvo indisponibilidade do produto.
							</p>
							<p>
								6.9. Os postos aderentes podem ser consultados através do endereço <a href="https://www.prio.pt/pt/postos-aderentes_358.html?p=140" target="_blank">https://www.prio.pt/pt/postos-aderentes_358.html?p=140.</a>
							</p>

							<!-- Add more paragraphs as needed -->
						</section>
						<section>
							<h5>7. Disposições Gerais</h5>
							<p>
								7.1. O direito ao prémio é pessoal e intransmissível.
							</p>
							<p>
								7.2. O valor líquido dos prémios correspondente, no total, a 295 (duzentos e noventa e cinco) prémios
								em combustível PRIO ECO Diesel, perfazendo o valor líquido total de 21 500,00 € (vinte e um mil e
								quinhentos euros) sendo, após a aplicação do Imposto do Selo no montante total de 17 590,91 € (dezassete
								mil, quinhentos e noventa euros e noventa e um cêntimos) (35% + 10%, nos termos do 11.2 e 11.2.2 da
								Tabela Geral do Imposto do Selo), o valor ilíquido do total de prémios de 39 090,91 € (trinta e nove mil,
								noventa euros e noventa e um cêntimos).
							</p>
							<p>
								7.3. As importâncias devidas a título do Imposto de Selo constituem responsabilidade da entidade
								promotora.
							</p>
							<p>
								7.4. O CONCURSO “Volta ao mundo em ECO Diesel” será publicitado no website
								https://www.ganhaecodiesel.pt, nos meios televisivos, redes sociais, bem como em todos os postos PRIO,
								obrigando-se a PRIO a expor aí claramente todas as condições respeitantes ao CONCURSO, bem como a
								disponibilizar o regulamento deste CONCURSO, em cumprimento do disposto no artigo 11.º do Decretolei n.º 330/90 de 23/10.
							</p>
							<p>
								7.5. A participação no presente CONCURSO implica, por parte do concorrente, a aceitação, integral e
								sem reservas, das presentes condições, na sua integralidade, pelo que qualquer manifestação no sentido da
								sua não aceitação implicará a exclusão do participante no CONCURSO.
							</p>
							<p>
								7.6. A PRIO reserva-se o direito de alterar, interromper ou terminar o presente CONCURSO antes do
								decurso do prazo previsto no presente regulamento, sem incorrer em qualquer tipo de responsabilidade
								perante os participantes.
							</p>
							<p>
								7.7. Em caso algum as entidades envolvidas serão responsáveis pelos danos ou prejuízos resultantes
								da atribuição, aceitação, gozo, utilização e/ou rejeição dos prémios atribuídos no âmbito do presente
								CONCURSO.
							</p>
							<p>
								7.8. Todas as dúvidas sobre a interpretação dos termos e condições e os casos omissos relativos a este
								CONCURSO serão analisadas e decididas pelo seu promotor.
							</p>
							<p>
								7.9. Para questões relacionadas com o presente CONCURSO, os participantes poderão contactar a
								Entidade Promotora através dos seguintes contactos:
							</p>
							<p>
								Email:<br>
								apoiocliente@PRIO.pt<br>
								Telefone:<br>
								234 390 010 das 9:00h às 18:00h.<br>
							</p>

							<!-- Add more paragraphs as needed -->
						</section>
						<section>
							<h5>8. Proteção de Dados Pessoais</h5>
							<p>
								A leitura deste Regulamento não dispensa a consulta dos termos e condições e política de privacidade da
								PRIO, que poderá ser consultada através do endereço <a href="https://www.prio.pt/pt/" target="_blank">https://www.prio.pt/pt/</a>.
							</p>
							<p>
								Ílhavo, 11 de dezembro de 2023
							</p>
							<!-- Add more paragraphs as needed -->
						</section>
					</div>
					<!--            <div class="col-12 d-flex justify-content-center" >
                    <div class="col-auto d-flex text-center justify-content-center" style="width:200px; border-bottom: 1px solid black;padding-bottom: 30px">
                        Assinatura
                    </div>
            </div>-->
				</div>
			</div>
		</div>
	</fake-modal-layout>
</template>
<script>
import FakeModalLayout from "../../Shared/FakeModalLayout.vue";
import {Head as MetaHead} from "@inertiajs/vue3";

export default {
    name: "TermsAndConditions",

    components: {
        FakeModalLayout,
        MetaHead
    },
    methods: {
        printPage() {
            window.print();
        },
        // ... (other methods)
    },
}
</script>
<style>
section{
    padding-top: 25px;
}
</style>
