<template>
    <div class="card border-0">
        <div class="card-body" :class="{'p-0': noPadding}">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "Card",
    props: {
        noPadding: {
            type: Boolean,
            required: false,
            default: false,
        }
    }
}
</script>

<style scoped>

</style>
