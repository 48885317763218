<template>
	<form @submit.prevent="submit">
        <login-input
            v-model="loginForm.name"
            label="Nome"
            name="name"
            placeholder=""
            :error="errors.name"
            type="text"
        />

		<login-input
			v-model="loginForm.email"
			label="Email"
			name="email"
			placeholder=""
			:error="errors.email"
            class="mt-4"
		/>

		<login-input
			v-model="loginForm.password"
			label="Password"
			name="password"
			placeholder=""
			type="password"
			:error="errors.password"
			class="mt-4"
		/>
        <div class="col-12 pt-3">
            <div class="form-check">
                <input
                    id="flexCheckTermsDefault"
                    v-model="loginForm.terms"
                    class="form-check-input"
                    type="checkbox"
                    value="true"
                >
                <label class="form-check-label" for="flexCheckTermsDefault">
                    Aceito os termos e condições *
                </label>
            </div>
        </div>
        <div class="col-12 pt-3">
            <div class="form-check">
                <input
                    id="flexCheckPrivacyDefault"
                    v-model="loginForm.privacy"
                    class="form-check-input"
                    type="checkbox"
                    value="true"
                >
                <label class="form-check-label" for="flexCheckPrivacyDefault">
                    Aceito a política de privacidade *
                </label>
            </div>
        </div>
        <div class="col-12 pt-3">
            <div class="form-check">
                <input
                    id="flexCheckPriocommsDefault"
                    v-model="loginForm.priocomms"
                    class="form-check-input"
                    type="checkbox"
                    value="true"
                >
                <label class="form-check-label" for="flexCheckPriocommsDefault">
                    Aceito receber comunicações da Prio *
                </label>
            </div>
        </div>

<!--        Aceitação dos termos e condições;
        Aceitação da Política de privacidade;
        Aceita receber comunicações da Prio;-->


		<div class="col-12 pt-3">
			<button
				type="submit"
				:disabled="loginForm.processing"
				class="btn btn-primary w-100"
			>
				Registar
			</button>
		</div>

	</form>
</template>

<script>
    import LoginLayout from "../../Shared/LoginLayout.vue";
    import LoginInput from "../../Components/LoginInput.vue";
    import { useForm } from '@inertiajs/vue3';

    export default {
        name: "Register",

        components: {
            'login-input': LoginInput
        },
        layout: LoginLayout,

        props:{
            errors: Object,
        },

        setup(){
            const loginForm = useForm({
                name: '',
                email: '',
                password: '',
                terms: false,
                privacy: false,
                priocomms: false,
                remember: false
            });

            return {loginForm}
        },

        mounted() {

        },

        methods:{
            submit(){
                this.loginForm.post('/user-register')
            }
        }
    }
</script>
