<template>
    <form @submit.prevent="submit">
        <login-input
            label="Email"
            name="email"
            placeholder=""
            v-model="form.email"
            :error="errors.email">
        </login-input>

        <div class="col-12 pt-3">
            <button type="submit" :disabled="form.processing" class="btn btn-primary w-100">
                Enviar
            </button>
        </div>
    </form>
</template>

<script>
    import LoginLayout from "../../Shared/LoginLayout.vue";
    import LoginInput from "../../Components/LoginInput.vue";
    import {useForm} from "@inertiajs/vue3";

    export default {
        name: "ForgotPassword",
        layout: LoginLayout,

        components: {LoginInput},

        props:{
            errors: Object,
            status: String,
        },

        setup(){
            const form = useForm({
                email: '',
            });

            return {form}
        },

        methods: {
            submit(){
                this.form.post(route('password.email'), {
                    preserveScroll: true,
                    onSuccess: () => {
                        toastSuccess('Verifique o seu email e siga as instruções para redefinir a sua password.');
                    }
                });
            }
        }
    }
</script>
