<template>
	<PageTitle title="Concorrente" />
	<template v-if="$page.props.auth.isSuperAdmin">
		<div class="mb-4">
			<a class="btn btn-warning" :href="route('devtools.changeUserAuth', {user_id: defaultForm.id})">
				<i class="mdi mdi-account-key" /> Fazer login na conta do concorrente
			</a>
			<a
				class="btn btn-danger text-white ms-3"
				href="javascript:void(0)"
				@click="inactive"
			>
				<i class="mdi mdi-account-remove" />
				Inativar concorrente
			</a>
		</div>
	</template>
	<div class="col-12">
		<div class="row">
			<div class="p-3 inventoryLeftCard col-12 col-md-6 col-lg-4 mr-4">
				<p>
					Nome:
					<template v-if="defaultForm.name && defaultForm.name !== ''">
						{{ defaultForm.name }}
					</template>
					<template v-else>
						<span class="text-danger">Não definido</span>
					</template>
				</p>
				<p>
					Email:
					<template v-if="defaultForm.email && defaultForm.email !== ''">
						{{ defaultForm.email }}
					</template>
					<template v-else>
						<span class="text-danger">Não definido</span>
					</template>
				</p>
				<p>
					Telefone:
					<template v-if="defaultForm.phone && defaultForm.phone !== ''">
						{{ defaultForm.phone }}
					</template>
					<template v-else>
						<span class="text-danger">Não definido</span>
					</template>
				</p>
				<p>
					Autorizou SMS:
					<template v-if="defaultForm.priocomms">
						Sim
					</template>
					<template v-else>
						<span class="text-danger">Não</span>
					</template>
				</p>
			</div>
			<div class="p-3 inventoryLeftCard col-12 col-md-6 col-lg-4 " style="margin-left: auto">
				<div v-if="defaultForm.disqualified == null" class="col-12">
					<p>Deseja desqualificar este concorrente?</p>
					<p>
						Caso o concorrente tenha participações premiadas, vamos promover um suplente em cada um desses
						sorteios.
					</p>
					<div class="pt-4">
						<label for="reason" class="col-12">Motivo</label>
						<input
							id="reason"
							v-model="defaultForm.disqualified_reason"
							type="text"
							class="col-12"
						>
					</div>
					<button
						class="btn btn-danger mt-4"
						style="color:#ffffff;"
						@click="disqualify"
					>
						Desqualificar
					</button>
				</div>
				<div v-else class="col-12">
					<p class="text-danger">
						Este concorrente foi desqualificado.
					</p>
					<p class="text-danger">
						Motivo: {{ defaultForm.disqualified_reason }}
					</p>
					<p class="text-danger">
						Admin: {{ defaultForm.disqualified_by }}
					</p>
					<p class="text-danger">
						Em: {{ defaultForm.disqualified }}
					</p>
				</div>
			</div>
		</div>
	</div>
	<div class="col-12">
		<div class="row">
			<DefaultDataTable
				ref="main-datatable"
				:url="route('notifications.search')"
				removesearch="true"
				:columns="dtColumns"
				:params="{contestent_id: defaultForm.id}"
			/>
		</div>
	</div>
</template>
<script setup>
</script>
<script>
import Layout from "../../Shared/Layout.vue";
import PageTitle from "../../Components/etc/PageTitle.vue";
import DefaultDataTable from "../../Components/DefaultDataTable.vue";



export default {
    name: "Edit",
    components: {
        DefaultDataTable,
        PageTitle,
    },
    layout: Layout,
    props: {
        defaultForm: {
            type: Object,
            required: true,
        },

        errors: {
            type: Object,
            required: false,
            default: () => {
            }
        }
    },
    data() {
        return {

            dtColumns : [
                {data: 'Titulo', dataTitle: 'Titulo', name: 'data.title', searchable: false, orderable: false, minWidth: '50px'},
                {data: 'Mensagem', dataTitle: 'Mensagem', name: 'data.message', searchable: false, orderable: false, minWidth: '50px'},
                {data: 'Lido por', dataTitle: 'Lido por', name: 'amount_read', searchable: false, orderable: false, minWidth: '50px'},
                {data: 'Enviado a', dataTitle: 'Enviado a', name: 'created_at', searchable: false, orderable: false, minWidth: '50px'},
                // {data: 'Descrição', dataTitle: 'Descrição', name: 'description', searchable: true, orderable: true, minWidth: '50px'},
            ],

        }
    },
    mounted() {
        console.log(this.defaultForm);

    },
    methods: {
        disqualify() {
            if (confirm('Tem a certeza que deseja desqualificar este concorrente?')) {
                this.$inertia.post(this.route('contestents.disqualify', this.defaultForm.id), this.defaultForm);
            }
        },
        inactive(){
            if (confirm('Tem a certeza que deseja inativar este concorrente?')) {
                this.$inertia.post(this.route('contestents.inactivate', this.defaultForm.id));
            }
        }
    }
}
</script>

<style scoped>

</style>
