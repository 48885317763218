<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "BreadCumbs",
    props: {
        links: {
            type: Array,
            required: true,
        },
        padding: {
            type: String,
            required: false,
            default: 'pb-4'
        }
    },
})

</script>

<template>
	<div :class="`col-12 ${padding}`">
		<div class="row">
			<template v-for="(link, index) in links" :key="index">
				<span class="each-breadcumb col-auto pr-1">
					<i v-if="index !== 0" class=" mdi mdi-chevron-double-right pr-1" />
					<template v-if="link.link">
						<Link :href="link.link">
							{{ link.name }}
						</Link>
					</template>
					<span v-else>
						{{ link.name }}
					</span>
				</span>
			</template>
		</div>
	</div>
</template>

<style scoped>

</style>
