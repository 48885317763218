<template>
	<MetaHead title="Valida o teu cupão" />
	<div class="col-12  p-1  py-md-4 px-md-4 ">
		<div class="">
			<div class="col-12  text-center">
				<p class="fw-bold colorFontPrimary">
					Valida o teu cupão
				</p>
			</div>
			<div class="col-12 px-2">
				<div v-for="participation in participations" class="col-12 ">
					<div class="row justify-content-center">
						<div
							class="col-12 col-md-4 col-lg-4  br-10  default-box-prio-radius px-0 py-2 my-2 mx-md-2 select-card-code select-card-code-page-fixer"
						>
							<div class="select-card-code-title select-card-code-px">
								Cupão a concurso
							</div>
							<div
								class="select-card-code-info d-flex pb-2 select-card-code-px justify-content-between"
							>
								<div class="select-card-code-info-label">
									Código
								</div>
								<div class="select-card-code-info-value fw-bold">
									{{ participation.code }}
								</div>
							</div>
							<div
								class="select-card-code-info  d-flex pb-2 select-card-code-px justify-content-between"
							>
								<div class="select-card-code-info-label">
									Fatura
								</div>
								<div class="select-card-code-info-value fw-bold">
									{{ participation.invoice_number }}
								</div>
							</div>
							<div class="select-card-code-info-date d-flex pb-2 select-card-code-px ">
								Submetido em {{ printDateAndHour(participation.created_at) }}
							</div>
							<!--                                Código: {{ participation.code }} <br>
                                                            Factura: {{ participation.invoice_number }} <br>-->
							<div v-for="contest in participation.contest_all_in_date_range">
								<div v-if="participation.participation_status.length > 0">
									<div v-for="(estados, index) in participation.participation_status" :key="index">
										<div v-if="estados.contest_id === contest.id">
											<div v-if="estados.status_label_contestent === 'Premiado'" class="">
												<div
													v-if="estados.status_label_contestent === 'Premiado'
														&& estados.status_label_administration === 'Premiado'
														&& (
															(estados.cc_frente == 'Por validar' || estados.cc_frente === 'Validado')
															&& (estados.cc_back === 'Por validar' || estados.cc_back === 'Validado')
															&& (estados.bill_photo === 'Por validar' || estados.bill_photo === 'Validado')
															&& (estados.code_photo === 'Por validar' || estados.code_photo === 'Validado')
															&& (estados.signed_contract === 'Por validar' || estados.signed_contract === 'Validado')
														)"
													:class="'jj-'+index"
												>
													<div class="text-center select-card-code-status-wrap">
														<div class="select-card-code-status-wrap-title pb-2">
															Parabéns!!!<br>
															<span class="select-card-code-status-wrap-title-rule">
																<span
																	v-for="(estados2, index2) in participation.participation_status"
																	:key="index2"
																>
																	<span
																		v-if="estados2.status_label_contestent === 'Premiado'"
																	>
																		<span
																			v-for="contest in participation.contest_all_in_date_range"
																		>
																			<span
																				v-if="estados2.contest_id === contest.id"
																			>
																				<span v-if="index2 > 0">, </span>
																				<span v-if="index2 === 0">(</span>
																				{{ contest.name }}
																				<span
																					v-if="index2 === (participation.participation_status.length - 1)"
																				>)</span>
																			</span>
																		</span>
																	</span>
																</span>
															</span>
														</div>
														<div class="select-card-code-status-wrap-text pb-2">
															O teu cupão encontra-se em processo de validação. Fica
															atento à tua caixa de email.
														</div>
													</div>
												</div>
												<div
													v-else-if="estados.status_label_contestent === 'Premiado'
														&& estados.status_label_administration !== 'Aprovado'
														&& (
															(estados.cc_frente === 'Recusado')
															|| (estados.cc_back === 'Recusado')
															|| (estados.bill_photo === 'Recusado')
															|| (estados.code_photo === 'Recusado')
															|| (estados.signed_contract === 'Recusado')
														)
													"
													:class="'jj-'+index"
												>
													<div
														class="text-center select-card-code-status-wrap select-card-code-status-wrap-refused"
													>
														<div class="select-card-code-status-wrap-title pb-2">
															Recusado!<br>
															<span class="select-card-code-status-wrap-title-rule">
																<span
																	v-for="(estados2, index2) in participation.participation_status"
																	:key="index2"
																>
																	<span
																		v-if="estados2.status_label_contestent === 'Premiado'"
																	>
																		<span
																			v-for="contest in participation.contest_all_in_date_range"
																		>
																			<span
																				v-if="estados2.contest_id === contest.id"
																			>
																				<span v-if="index2 > 0">, </span>
																				<span v-if="index2 === 0">(</span>
																				{{ contest.name }}
																				<span
																					v-if="index2 === (participation.participation_status.length - 1)"
																				>)</span>
																			</span>
																		</span>
																	</span>
																</span>
															</span>
														</div>
														<div class="select-card-code-status-wrap-text pb-2">
															Lamentamos. Apesar de sorteado, os documentos foram
															recusados por não cumprir com as regras do concurso.
														</div>
													</div>
												</div>
												<div
													v-else-if="estados.status_label_contestent === 'Desqualificado'
														&& estados.status_label_administration === 'Desqualificado'

													"
													:class="'jj-'+index"
												>
													<div
														class="text-center select-card-code-status-wrap select-card-code-status-wrap-refused"
													>
														<div class="select-card-code-status-wrap-title pb-2">
															Desqualificado!<br>
															<span class="select-card-code-status-wrap-title-rule">
																<span
																	v-for="(estados2, index2) in participation.participation_status"
																	:key="index2"
																>
																	<span
																		v-if="estados2.status_label_contestent === 'Premiado'"
																	>
																		<span
																			v-for="contest in participation.contest_all_in_date_range"
																		>
																			<span
																				v-if="estados2.contest_id === contest.id"
																			>
																				<span v-if="index2 > 0">, </span>
																				<span v-if="index2 === 0">(</span>
																				{{ contest.name }}<span
																					v-if="index2 === (participation.participation_status.length - 1)"
																				>)</span>
																			</span>
																		</span>
																	</span>
																</span>
															</span>
														</div>
														<div class="select-card-code-status-wrap-text pb-2">
															Lamentamos. Apesar de sorteado, o teu cupão não foi aceite
															como válido por não cumprir com as regras do concurso.
														</div>
													</div>
												</div>
												<div
													v-else-if="estados.status_label_contestent === 'Premiado'
														&& estados.status_label_administration !== 'Aprovado'
														&& (
															(estados.cc_frente === null || estados.cc_frente === 'Por validar')
															|| (estados.cc_back === null || estados.cc_back === 'Por validar')
															|| (estados.bill_photo === null || estados.bill_photo === 'Por validar')
															|| (estados.code_photo === null || estados.code_photo === 'Por validar')
															|| (estados.signed_contract === null || estados.signed_contract === 'Por validar')
														)
													"
													:class="'jj-'+index"
												>
													<div class="text-center select-card-code-status-wrap">
														<div class="select-card-code-status-wrap-title pb-2">
															Parabéns!!!<br>
															<span class="select-card-code-status-wrap-title-rule">
																<span
																	v-for="(estados2, index2) in participation.participation_status"
																	:key="index2"
																>
																	<span
																		v-if="estados2.status_label_contestent === 'Premiado'"
																	>
																		<span
																			v-for="contest in participation.contest_all_in_date_range"
																		>
																			<span
																				v-if="estados2.contest_id === contest.id"
																			>
																				<span v-if="index2 > 0">, </span>
																				<span v-if="index2 === 0">(</span>
																				{{ contest.name }}
																				<span
																					v-if="index2 === (participation.participation_status.length - 1)"
																				>)</span>
																			</span>
																		</span>
																	</span>
																</span>
															</span>
														</div>
														<div class="select-card-code-status-wrap-text pb-2">
															Este cupão foi um dos selecionados para o prémio!
														</div>
													</div>
												</div>

												<div
													v-else-if="estados.status_label_administration === 'Aprovado'"
													:class="'jj-'+index"
												>
													<div
														class="text-center select-card-code-status-wrap select-card-code-status-wrap-approved"
													>
														<div class="select-card-code-status-wrap-title pb-2">
															Parabéns!!!<br>
															<span class="select-card-code-status-wrap-title-rule">
																<span
																	v-for="(estados2, index2) in participation.participation_status"
																	:key="index2"
																>
																	<span
																		v-if="estados2.status_label_contestent === 'Premiado'"
																	>
																		<span
																			v-for="contest in participation.contest_all_in_date_range"
																		>
																			<span
																				v-if="estados2.contest_id === contest.id"
																			>
																				<span v-if="index2 > 0">, </span>
																				<span v-if="index2 === 0">(</span>
																				{{ contest.name }}
																				<span
																					v-if="index2 === (participation.participation_status.length - 1)"
																				>)</span>
																			</span>
																		</span>
																	</span>
																</span>
															</span>
														</div>
														<div class="select-card-code-status-wrap-text pb-2">
															O teu cupão foi corretamente validado. Segue as instruções
															que enviamos por email para levantar o prémio.
														</div>
													</div>
												</div>
												<div
													v-else-if="estados.status_label_administration === 'Recusado'"
													:class="'jj-'+index"
												>
													<div
														class="text-center select-card-code-status-wrap select-card-code-status-wrap-approved"
													>
														<div class="select-card-code-status-wrap-title pb-2">
															Recusado!<br>
															<span class="select-card-code-status-wrap-title-rule">
																<span
																	v-for="(estados2, index2) in participation.participation_status"
																	:key="index2"
																>
																	<span
																		v-if="estados2.status_label_contestent === 'Premiado'"
																	>
																		<span
																			v-for="contest in participation.contest_all_in_date_range"
																		>
																			<span
																				v-if="estados2.contest_id === contest.id"
																			>
																				<span v-if="index2 > 0">, </span>
																				<span v-if="index2 === 0">(</span>
																				{{ contest.name }}
																				<span
																					v-if="index2 === (participation.participation_status.length - 1)"
																				>)</span>
																			</span>
																		</span>
																	</span>
																</span>
															</span>
														</div>
														<div class="select-card-code-status-wrap-text pb-2">
															Lamentamos. Apesar de sorteado, o teu cupão não foi aceite
															como válido por não cumprir com as regras do concurso.
														</div>
													</div>
												</div>
												<div class="select-card-code-status-wrap-validate pt-2">
													<div class="select-card-code-status-wrap-validate-title pb-2">
														Como validar o cupão
													</div>
													<div class="select-card-code-status-wrap-validate-text px-2 pb-4">
														<div class="d-flex justify-content-start">
															<div class="enumeration">
																1
															</div>
															<div class="">
																Lê a declaração de aceitação, vais precisar aceitar a
																mesma
																para poder receber o prémio<br>
																<a
																	data-bs-toggle="modal"
																	data-bs-target="#declaracaoModal"
																	class="link-blue"
																>
																	<i class="mdi mdi-note-text-outline" /> Declaração de
																	aceitação
																</a>
															</div>
														</div>
													</div>
													<div class="select-card-code-status-wrap-validate-text px-2 pb-4">
														<div class="d-flex  justify-content-start">
															<div class="enumeration">
																2
															</div>
															<div class="">
																Tira fotos ou digitaliza o talão, a fatura, o teu cartão
																de cidadão ou passaporte
															</div>
														</div>
													</div>
													<div class="select-card-code-status-wrap-validate-text px-2 pb-4">
														<div class="d-flex  justify-content-start">
															<div class="enumeration ">
																3
															</div>
															<div class="">
																Se já tens tudo preparado, clica no link:<br>
																<Link
																	class="text-decoration-underline colorFontPrimary link-blue"
																	:href="route('contests.myParticipations.files', [participation.uuid, estados.uuid])"
																>
																	ENVIAR COMPROVATIVOS.
																</Link>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div v-else class="">
												{{ estados.status_label_contestent }} <br>
											</div>
										</div>
									</div>
								</div>

								<!--                                    <div v-else>
                                                                        em espera <br>
                                                                    </div>-->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		id="declaracaoModal"
		class="modal fade"
		tabindex="-1"
		aria-labelledby="declaracaoModal"
		aria-hidden="true"
	>
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						Declaração de aceitação
					</h5>
					<div
						style="width: 20px; height: 20px"
						class="btn-close cursor-pointer"
						data-bs-dismiss="modal"
						aria-label="Close"
					/>
				</div>


				<div class="modal-body">
					<div class="col-12">
						Declaro aceitar o prémio referente ao concurso “Volta ao mundo em ECO Diesel”, nº 308/2023,
						autorizado pela Câmara Municipal de Ílhavo, o qual é entregue pela PRIO Energy S.A, com sede no
						TGL – Terminal de Granéis Líquidos, Porto de Aveiro – Lote B, 3834 – 908 Gafanha da Nazaré. O
						mesmo será enviado via portal para o endereço que indiquei.
					</div>
				</div>

				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-primary btn-primary-yellow w-100 fw-semibold default-box-prio-radius"
						data-bs-dismiss="modal"
					>
						Fechar
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContestentsLayout from "../../Shared/ContestentsLayout.vue";
import axios from "axios";
import {Head as MetaHead} from "@inertiajs/vue3";
import {DateTime} from "luxon";

export default {
    name: "ParticipationStatusValidate",

    components: {
        MetaHead,
    },

    layout: ContestentsLayout,


    props: {
        participation: {
            type: Object,
            required: true,
        },

        participationStatus: {
            type: Object,
            required: true,
        },


    },
    data() {
        return {
            participations: [],
            statusClass: 'default',

        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        printDateAndHour(date) {
            console.log(date);
            // const options = {year: 'numeric', month: 'numeric', day: 'numeric'};
            // const options2 = {hour: 'numeric', minute: 'numeric'};
            // return new Date(date).toLocaleDateString(undefined, options) + ' ás ' + new Date(date).toLocaleTimeString(undefined, options2) + 'h';
            return DateTime.fromISO(date).toFormat("dd/MM/yyyy 'às' HH:mm");
        },
        getData() {
            axios.get(route('contests.myParticipation.search'), {params: {participation: this.participation.uuid}})
                .then((response) => {
                    this.participations = response.data;
                    /*console.log(this.participations);*/
                })
        },
    },
}
</script>
