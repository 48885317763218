<template>
	<MetaHead title="As tuas participações" />
	<div class="col-12  p-1 py-md-4 px-md-4 ">
		<div class="row">
			<div class="col-12  text-center">
				<p class="fw-bold colorFontPrimary ll">
					As tuas participações
					<span>({{ participations ? participations.length : 0 }})</span>
				</p>
				<div class="col-12 px-2">
					<div v-if="participations && participations.length > 0" class="col-12 ">
						<div class="row justify-content-center">
							<div
								v-for="participation in participationsMerged"
								:key="participation.code"
								class="col-12  br-10 px-0  my-2 mx-md-2 d-flex justify-content-center"
							>
								<div class="col-12 col-md-4">
									<div class="default-box-prio-radius px-0 py-2 select-card-code">
										<div class="select-card-code-title select-card-code-px">
											Cupão a concurso
										</div>
										<div
											class="select-card-code-info d-flex pb-2 select-card-code-px justify-content-between"
										>
											<div class="select-card-code-info-label">
												Código
											</div>
											<div class="select-card-code-info-value fw-bold">
												{{ participation.code }}
											</div>
										</div>
										<div
											class="select-card-code-info d-flex pb-2 select-card-code-px justify-content-between"
										>
											<div class="select-card-code-info-label">
												Fatura
											</div>
											<div class="select-card-code-info-value fw-bold">
												{{ participation.invoice_number }}
											</div>
										</div>
										<div class="select-card-code-info-date d-flex pb-2 select-card-code-px ">
											Submetido em {{ printDateAndHour(participation.created_at) }}
										</div>

										<div v-if="participation.participation_status.length > 0">
											<div
												v-for="(estados, index) in participation.participation_status"
												:key="index"
											>
												<div
													v-if="estados.status_label_contestent === 'Premiado'
														&& estados.status_label_administration === 'Premiado'
														&& (
															(estados.cc_frente === 'Por validar' || estados.cc_frente === 'Validado')
															&& (estados.cc_back === 'Por validar' || estados.cc_back === 'Validado')
															&& (estados.bill_photo === 'Por validar' || estados.bill_photo === 'Validado')
															&& (estados.code_photo === 'Por validar' || estados.code_photo === 'Validado')
															&& (estados.morada_validate === 'Por validar' || estados.morada_validate === 'Validado')
														)"
													:class="'jj-'+index"
												>
													<div class="text-center select-card-code-status-wrap">
														<div class="select-card-code-status-wrap-title pb-2">
															Parabéns!!!
															<p class="fs-6 py-2 mb-1">
																Este cupão foi sorteado!
															</p>
															<div class="select-card-code-status-wrap-title-rule pt-3">
																<template
																	v-for="(estados2, index2) in participation.participation_status"
																	:key="index2"
																>
																	<template
																		v-if="estados2.status_label_contestent === 'Premiado'"
																	>
																		<template
																			v-for="contest in participation.contest_all_in_date_range"
																		>
																			<div
																				v-if="estados2.contest_id === contest.id"
																				class="thedivthatdoesstuff pt-3 pb-3 "
																			>
																				{{
																					contest.name
																				}} <br> {{ contest.description }}
																				<span v-if="index2 > 0"><br></span>
																			</div>
																		</template>
																	</template>
																</template>
															</div>
														</div>
														<div>
															<p class="pt-4 mb-1 fs-1 text-black" style="line-height:37px;">
																<i class="mdi mdi-note-search-outline" />
															</p>
															<p class="text-black fs-6 fw-bold">
																Estamos a validar o teu cupão
															</p>
														</div>
													</div>
													<div
														class="select-card-code-status-wrap-after select-card-code-status-wrap-after-validating pt-2"
													>
														<p>
															<i class="mdi mdi-text-box-search-outline font-24" /> Cupão
															em validação...
														</p>
													</div>
												</div>
												<div
													v-else-if="estados.status_label_contestent === 'Premiado'
														&& estados.status_label_administration !== 'Aprovado'
														&& (
															(estados.cc_frente === 'Recusado')
															|| (estados.cc_back === 'Recusado')
															|| (estados.bill_photo === 'Recusado')
															|| (estados.code_photo === 'Recusado')
															|| (estados.morada_validate === 'Recusado')
														)
													"
													:class="'jj-'+index"
												>
													<div
														class="text-center select-card-code-status-wrap select-card-code-status-wrap-refused"
													>
														<div class="select-card-code-status-wrap-title pb-2">
															Recusado!<br>
															<div class="select-card-code-status-wrap-title-rule pt-3">
																<span
																	v-for="(estados2, index2) in participation.participation_status"
																	:key="index2"
																>
																	<template
																		v-if="estados2.status_label_contestent === 'Premiado'"
																	>
																		<template
																			v-for="contest in participation.contest_all_in_date_range"
																		>
																			<div
																				v-if="estados2.contest_id === contest.id"
																				class="thedivthatdoesstuff pt-3 pb-3 "
																			>
																				{{
																					contest.name
																				}} <br> {{ contest.description }}
																				<span v-if="index2 > 0"><br></span>
																			</div>
																		</template>
																	</template>
																</span>
																<div
																	class="select-card-code-status-wrap-text pb-2 pt-2"
																>
																	Lamentamos. Apesar de sorteado, os documentos foram
																	recusados por não cumprir com as regras do concurso.
																</div>
															</div>
														</div>

														<!--                                                            <a :href="route('contests.myParticipations.files', [participation.id, estados.id])">-->
														<Link
															:href="route('contests.myParticipations.validate', [participation.uuid, estados.uuid])"
														>
															<!--                                                            {{ estados.status_label_contestent }}-->
															Clica aqui para validar este cupão
														</Link>
													</div>
													<div
														class="select-card-code-status-wrap-after select-card-code-status-wrap-after-refused pt-2"
													>
														<p>
															<i class="mdi mdi-shield-alert-outline font-24" /> Cupão
															Recusado
														</p>
													</div>
												</div>
												<div
													v-else-if="estados.status_label_contestent === 'Desqualificado'
														&& estados.status_label_administration === 'Desqualificado'

													"
													:class="'jj-'+index"
												>
													<div
														class="text-center select-card-code-status-wrap select-card-code-status-wrap-refused"
													>
														<div class="select-card-code-status-wrap-title pb-2">
															Desqualificado!<br>
															<span class="select-card-code-status-wrap-title-rule">
																<span
																	v-for="(estados2, index2) in participation.participation_status"
																	:key="index2"
																>
																	<span
																		v-if="estados2.status_label_contestent === 'Premiado'"
																	>
																		<span
																			v-for="contest in participation.contest_all_in_date_range"
																		>
																			<span
																				v-if="estados2.contest_id === contest.id"
																			>
																				{{
																					contest.name
																				}} <br> {{ contest.description }}
																				<span v-if="index2 > 0"><br></span>
																			</span>
																		</span>
																	</span>
																</span>
															</span>
														</div>
														<div class="select-card-code-status-wrap-text pb-2 pt-2">
															Lamentamos. Apesar de sorteado, o teu cupão não foi aceite
															como válido por não cumprir com as regras do concurso.
														</div>
													</div>
													<div
														class="select-card-code-status-wrap-after select-card-code-status-wrap-after-refused pt-2"
													>
														<p>
															<i class="mdi mdi-shield-remove-outline font-24" /> Cupão
															Recusado
														</p>
													</div>
												</div>
												<div
													v-else-if="estados.status_label_contestent === 'Premiado'
														&& estados.status_label_administration !== 'Aprovado'
														&& (
															(estados.cc_frente === null || estados.cc_frente === 'Por validar')
															|| (estados.cc_back === null || estados.cc_back === 'Por validar')
															|| (estados.bill_photo === null || estados.bill_photo === 'Por validar')
															|| (estados.code_photo === null || estados.code_photo === 'Por validar')
															|| (estados.morada_validate === null || estados.morada_validate === 'Por validar')
														)
													"
													:class="'jj-'+index"
												>
													<div class="text-center select-card-code-status-wrap">
														<div class="select-card-code-status-wrap-title ">
															Parabéns!!!

															<p class="fs-6 py-2 mb-1">
																Este cupão foi sorteado!
															</p>
															<span class="select-card-code-status-wrap-title-rule">
																<span
																	v-for="(estados2, index2) in participation.participation_status"
																	:key="index2"
																>
																	<span
																		v-if="estados2.status_label_contestent === 'Premiado'"
																	>
																		<template
																			v-for="contest in participation.contest_all_in_date_range"
																		>
																			<div
																				v-if="estados2.contest_id === contest.id"
																				class="thedivthatdoesstuff pt-3 pb-3 "
																			>
																				{{
																					contest.name
																				}}
																				<br>
																				{{ contest.description }}
																				<p
																					v-if="estados.cc_frente === null"
																					style="font-size:11pt; line-height:23px; font-weight: 500; color:#000;"
																					class="mb-1 pt-3"
																				>
																					Aguardamos o envio dos comprovativos. <br>
																					<!--                                                                                    Comentado para nao afectar os premiados fora de horas-->
																					<!--																					Data limite: {{
																						contest.limit_date ?? null
																					}}-->
																				</p>
																				<span v-if="index2 > 0"><br></span>
																			</div>
																		</template>
																	</span>
																</span>
															</span>
															<div v-if="estados.cc_frente !== null">
																<p class="pt-5 fs-1 text-black mb-1">
																	<i class="mdi mdi-note-search-outline" />
																</p>
																<p class="text-black fs-6">
																	Estamos a validar o teu cupão
																</p>
															</div>
															<div v-if="estados.cc_frente === null" class="pt-2">
																<Link
																	v-if="estados.cc_frente === null"
																	:href="route('contests.myParticipations.validate', [participation.uuid, estados.uuid])"
																>
																	Clica aqui para validar este cupão
																</Link>
															</div>
														</div>
													</div>
													<div class="select-card-code-status-wrap-after">
														<div v-if="estados.cc_frente === null" class="select-card-code-status-wrap-after select-card-code-status-wrap-after-refused pt-2">
															<p>
																<i class="mdi mdi-shield-alert-outline font-24" />
																Documentos em falta
															</p>
														</div>
														<template v-else>
															<div>
																<span>
																	Participação em validação
																</span>
															</div>
															<div>
																<span class="text-decoration-underline">
																	<Link
																		:href="route('contests.myParticipations.files', [participation.uuid, estados.uuid])"
																	>
																		Ver estado da validação
																	</Link>
																</span>
															</div>
														</template>
													</div>
												</div>

												<div
													v-else-if="estados.status_label_administration === 'Aprovado' || estados.status_label_administration === 'Vencedor'"
													:class="'jj-'+index"
												>
													<div
														class="text-center select-card-code-status-wrap select-card-code-status-wrap-approved"
													>
														<div class="select-card-code-status-wrap-title pb-2">
															Parabéns!!!<br>
															<template class="select-card-code-status-wrap-title-rule">
																<template
																	v-for="(estados2, index2) in participation.participation_status"
																	:key="index2"
																>
																	<template
																		v-if="estados2.status_label_contestent === 'Premiado'"
																	>
																		<template
																			v-for="contest in participation.contest_all_in_date_range"
																		>
																			<div
																				v-if="estados2.contest_id === contest.id"
																				class="thedivthatdoesstuff pt-3 pb-3 "
																			>
																				{{ contest.name }} <br>
																				{{ contest.description }}
																			</div>
																		</template>
																	</template>
																</template>
															</template>
														</div>
														<div class="select-card-code-status-wrap-text pb-2 pt-2">
															O teu cupão foi corretamente validado. Segue as instruções
															que enviamos por email para levantar o prémio.
														</div>
													</div>
													<div
														class="select-card-code-status-wrap-after select-card-code-status-wrap-after-approved pt-2"
													>
														<p>
															<i class="mdi mdi-image-edit-outline font-24" /> Cupão
															Validado
														</p>
													</div>
												</div>
												<div
													v-else-if="estados.status_label_administration === 'Recusado'"
													:class="'jj-'+index"
												>
													<div
														class="text-center select-card-code-status-wrap select-card-code-status-wrap-approved"
													>
														<div class="select-card-code-status-wrap-title pb-2">
															Recusado!<br>
															<span class="select-card-code-status-wrap-title-rule">
																<span
																	v-for="(estados2, index2) in participation.participation_status"
																	:key="index2"
																>
																	<span
																		v-if="estados2.status_label_contestent === 'Premiado'"
																	>
																		<span
																			v-for="contest in participation.contest_all_in_date_range"
																		>
																			<span
																				v-if="estados2.contest_id === contest.id"
																			>
																				<span v-if="index2 > 0">, </span>
																				<span v-if="index2 === 0">(</span>
																				{{ contest.description }}
																				<span
																					v-if="index2 === (participation.participation_status.length - 1)"
																				>)</span>
																			</span>
																		</span>
																	</span>
																</span>
															</span>
														</div>
														<div class="select-card-code-status-wrap-text pb-2 pt-2">
															Lamentamos. Apesar de sorteado, o teu cupão não foi aceite
															como válido por não cumprir com as regras do concurso.
														</div>
													</div>
													<div
														class="select-card-code-status-wrap-after select-card-code-status-wrap-after-refused pt-2"
													>
														<p>
															<i class="mdi mdi-shield-remove-outline font-24" /> Cupão
															Recusado
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-else class="col-12 ">
						<div class="row justify-content-center">
							<div class="col-12 col-md-6 col-lg-3  br-10 px-0  my-2 mx-md-2 ">
								<div class="default-box-prio-radius px-4 py-4 select-card-code">
									<p class="select-card-code-empty">
										Ainda não submeteste nenhum cupão
									</p>
									<a
										:href="route('contests.createParticipation')"
										class="select-card-code-empty color-confirm"
									>
										Clica aqui para submeter
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContestentsLayout from "../../Shared/ContestentsLayout.vue";
import axios from "axios";
import {Head} from "@inertiajs/vue3";
import {DateTime} from "luxon";
import {sortBy} from "lodash";

export default {
    name: "MyParticipations",

    components: {
        MetaHead: Head,
    },

    layout: ContestentsLayout,

    data() {
        return {
            participations: [],
            participationsMerged: [],
        }
    },

    mounted() {
        this.getData();
    },

    methods: {
        getData() {
            axios.get(route('contests.myParticipation.search'))
                .then((response) => {
                    this.participations = response.data;

                    this.hammerData();
                })
        },
        printDateAndHour(date) {
            return DateTime.fromISO(date).toFormat("dd/MM/yyyy 'às' HH:mm");
        },
        hammerData() {
            if (!this.participations) {
                this.$sentry.captureMessage('participations is null');
                return;
            }

            const winnersArray = sortBy(this.participations.filter((participation) =>
                participation.participation_status.some(
                    (status) => status.status_label_contestent === 'Premiado'
                )
            ), (participation) => {
                return -new Date(participation.created_at);
            });

            // Array to store other participations
            const disqualifiedParticipationsArray = sortBy(this.participations.filter(
                (participation) =>
                    participation.participation_status.length > 0 &&
                    participation.participation_status.some(
                        (status) => status.status_label_contestent === 'Desqualificado'
                    )
            ), (participation) => {
                return -new Date(participation.created_at);
            });

            // Array to store other participations
            const otherParticipationsArray = sortBy(this.participations.filter(
                (participation) =>
                    (
                        //other
                        participation.participation_status.length === 0 ||
                        participation.participation_status.every(
                            (status) => status.status_label_contestent !== 'Premiado' && status.status_label_contestent !== 'Desqualificado'
                        )
                    ) || (
                        //non winners
                        participation.participation_status.length > 0 &&
                        !participation.participation_status.some(
                            (status) => status.status_label_contestent === 'Premiado'
                        ) &&
                        !participation.participation_status.some(
                            (status) => status.status_label_contestent === 'Desqualificado'
                        )
                    )
            ), (participation) => {
                return -new Date(participation.created_at);
            });

            console.debug('winnersArray', winnersArray);
            console.debug('disqualifiedParticipationsArray', disqualifiedParticipationsArray);
            console.debug('otherParticipationsArray', otherParticipationsArray);

            this.participationsMerged = winnersArray.concat(disqualifiedParticipationsArray, otherParticipationsArray);
            // /*console.log(disqualifiedParticipationsArray);*/
        },
        statusDocuments(status) {
            console.debug('status', status);
        }
    }
}
</script>
