<template>
	<form @submit.prevent="submit">
		<login-input
			v-model="loginForm.email"
			label="Email"
			name="email"
			placeholder=""
			:error="errors.email"
		/>

		<login-input
			v-model="loginForm.password"
			label="Password"
			name="password"
			placeholder=""
			type="password"
			:error="errors.password"
			class="mt-4"
		/>

		<div class="col-12 pt-3">
			<div class="form-check">
				<input
					id="flexCheckDefault"
					v-model="loginForm.remember"
					class="form-check-input"
					type="checkbox"
					value="true"
				>
				<label class="form-check-label" for="flexCheckDefault">
					Lembrar-me
				</label>
			</div>
		</div>

		<div class="col-12 pt-3">
			<button
				type="submit"
				:disabled="loginForm.processing"
				class="btn btn-primary w-100"
			>
				Login
			</button>
		</div>

		<div class="col-12 pt-3" style="text-align: right;">
			<Link :href="route('password.email')" class="link-primary">
				Esqueci-me da password
			</Link>
		</div>
		<!--        <turnstile :siteKey="siteKey"></turnstile>-->
	</form>
</template>

<script>
    import LoginLayout from "../../Shared/LoginLayout.vue";
    import LoginInput from "../../Components/LoginInput.vue";
    import { useForm } from '@inertiajs/vue3';
    // import Turnstile from '@any-design/turnstile';
    import {ref} from "vue";

    export default {
        name: "Login",

        components: {
            'login-input': LoginInput,
            // Turnstile,
        },
        layout: LoginLayout,

        props:{
            errors: Object,
        },

        setup(){
            const loginForm = useForm({
                email: '',
                password: '',
                remember: false
            });

            const siteKey = ref(import.meta.env.VITE_TURNSTILE_SITE_KEY);

            return {loginForm, siteKey}
        },

        mounted() {

        },

        methods:{
            submit(){
                this.loginForm.post('/login')
            }
        }
    }
</script>
