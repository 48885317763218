<template>
	<PageTitle title="Concursos" />
	<DefaultDataTable
		ref="table"
		:columns="columns"
		:url="route('professionalcontests.search')"
		:filters="filters"
		:length-prop="25"
	>
		<!--		<template #button>
			<div class="d-flex">
				<Link
					v-if="$page.props.auth.isSuperAdmin"
					class="
					btn
					btn-outline-primary
					d-flex
					align-items-center"
					:href="route('professionalcontests.create')"
				>
					<i style="font-size: 20px; line-height: 1px;" class="mdi mdi-plus pe-1" />
					Novo sorteio
				</Link>
			</div>
		</template>-->
		<template #button>
			<div v-if="$page.props.auth.isSuperAdmin" class="pe-3">
				<button
					type="button"
					class="btn btn-outline-primary d-flex align-items-center"
					@click="isFileUploadModalOpen = true"
				>
					<i style="font-size: 20px; line-height: 1px;" class="mdi mdi-upload pe-1" /> Importar Participações
				</button>
			</div>
		</template>
	</DefaultDataTable>
	<upload-file-modal
		title="Importar .csv"
		:is-open="isFileUploadModalOpen"
		:sorteios="sorteios"
		@close="isFileUploadModalOpen = false"
	/>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import UploadFileModal from "../../Components/UploadFileModal.vue";

export default {
    components:{UploadFileModal},
    layout: Layout,
    data(){
        return{
            isFileUploadModalOpen: false,
            sorteios: this.$page.props.sorteios,
        }
    },
    methods: {

    },

}
</script>
<script setup>
import DefaultDataTable from "../../Components/DefaultDataTable.vue";
import PageTitle from "../../Components/etc/PageTitle.vue";

defineProps({
    filters: {
        type: Object,
        default: () => ({})
    }
});

const columns = [
    { data: 'ID', dataTitle: 'ID', name: 'id', searchable: true, orderable: true },
    { data: 'Nome', dataTitle: 'Nome', name: 'contest_type', searchable: true, orderable: true, link: 'professionalcontests.show'},
    { data: 'Início - Fim', dataTitle: 'Início - Fim', name: 'dates', searchable: true, orderable: true },
    { data: 'Estado', dataTitle: 'Estado', name: 'status', searchable: true, orderable: true },
    { data: 'Data do sorteio', dataTitle: 'Data do sorteio', name: 'ran_date', searchable: true, orderable: true },

]
//define layout
</script>

<style scoped>

</style>
