<template>
	<MetaHead title="Participa no concurso" />
	<div class="col-12   py-md-4 px-md-4 ">
		<div class="row">
			<div class="col-12  text-center">
				<p class="fw-bold colorFontPrimary">
					Já foste, o prazo para submissão de participações terminou.
				</p>

				<p>
					<inertia-link :href="route('contests.myParticipations')">
						Consulte aqui suas participações
					</inertia-link>
				</p>
			</div>
		</div>
	</div>
</template>

<script setup>
import { Head as MetaHead, Link as InertiaLink } from "@inertiajs/vue3";

</script>

<script>
import ContestentsLayout from "../../Shared/ContestentsLayout.vue";

export default {
    name: "Fineshed",
    layout: ContestentsLayout,
}
</script>
