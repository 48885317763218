<template>
	<fake-modal-layout>
		<meta-head title="Política de Privacidade" />
		<template #link>
			<a href="https://ganhaecodiesel.pt" class="fw-bold">
				<i class="mdi mdi-chevron-left pr-1" />
				Voltar a ganhaecodiesel.pt
			</a>
		</template>
		<div class="container">
			<div class="row" />
			<div class="col-12  p-1 py-md-4 px-md-4 doc-pages">
				<div class="row">
					<div class="col-12 pt-4">
						<h4 class="fw-bold text-center">
							Política de Privacidade
						</h4>
						<section>
							<h5>1. ÂMBITO E OBJETIVO</h5>
							<p>
								A PRIO está comprometida com a proteção e confidencialidade dos dados pessoais de todos os seus clientes.
								Esta política de privacidade descreve como é que a PRIO recolhe e utiliza os dados pessoais durante e depois da relação contratual ou de prestação de serviços, de acordo com o Regulamento Geral de Proteção de Dados. Esta Política aplica-se a todos os clientes da PRIO.
								A PRIO é o responsável pelo tratamento dos dados. Isto significa que é responsável por decidir como é que guarda e utiliza os dados pessoais. É exigido por lei que notifiquemos todos os clientes da informação contida nesta Política de Privacidade.
								Esta Política não faz parte de qualquer contrato de trabalho ou de qualquer contrato de prestação de serviços.
								É importante que leia esta Política, juntamente com qualquer outra política disponibilizada pela PRIO para situações específicas.
							</p>
						</section>

						<!-- Section 2: PRINCÍPIOS DA PROTEÇÃO DE DADOS -->
						<section>
							<h5>2. PRINCÍPIOS DA PROTEÇÃO DE DADOS</h5>
							<p>
								Todos os dados pessoais que a PRIO armazena e trata:
							</p>
							<ul>
								<li>São objeto de um tratamento lícito, leal e transparente.</li>
								<li>São recolhidos para finalidades determinadas, explícitas e legítimas, não podendo ser tratados posteriormente de uma forma incompatível com essa finalidade.</li>
								<li>São relevantes para as finalidades que foram comunicadas e limitados a essa finalidade.</li>
								<li>São exatos e atualizados.</li>
								<li>São mantidos apenas durante o tempo necessário à finalidade que foi comunicada.</li>
							</ul>
						<!-- Add more paragraphs as needed -->
						</section>

						<!-- Section 3: QUE TIPO DE INFORMAÇÃO É GUARDADA SOBRE O CLIENTE -->
						<section>
							<h5>3. QUE TIPO DE INFORMAÇÃO É GUARDADA SOBRE O CLIENTE</h5>
							<p>
								Dados pessoais ou informação pessoal, é qualquer informação sobre uma pessoa singular que a identifique. Dados em que a identidade tenha sido anonimizada não são considerados dados pessoais.
								Existem "categorias especiais" mais sensíveis que requerem um nível superior de proteção. A PRIO recolhe, armazena e trata as seguintes categorias de informação pessoal:
							</p>
						<!-- Add more paragraphs and lists as needed -->
						</section>

						<!-- Section 4: COMO É QUE A INFORMAÇÃO PESSOAL É RECOLHIDA? -->
						<section>
							<h5>4. COMO É QUE A INFORMAÇÃO PESSOAL É RECOLHIDA?</h5>
							<p>
								A PRIO recolhe informação pessoal dos clientes através de entrega em suporte papel, e-mail ou aplicações informáticas. Durante o período de duração do contrato ou de prestação de serviços, será recolhida informação pessoal adicional relacionada com a atividade do cliente.
							</p>
						<!-- Add more paragraphs as needed -->
						</section>

						<!-- Section 5: COMO É QUE É UTILIZADA A INFORMAÇÃO PESSOAL? -->
						<section>
							<h5>5. COMO É QUE É UTILIZADA A INFORMAÇÃO PESSOAL?</h5>
							<p>
								A PRIO apenas trata a informação pessoal quando o tratamento é permitido por lei. Normalmente, os dados são usados para as seguintes circunstâncias:
							</p>
							<ul>
								<li>Para o cumprimento do contrato de prestação de serviços.</li>
								<li>Para o cumprimento de uma obrigação legal.</li>
								<li>Quando for necessário para os interesses legítimos da PRIO (ou de terceiros) e os direitos e liberdades fundamentais do cliente não se sobreponham.</li>
							</ul>
							<p>
								Adicionalmente e em circunstâncias raras os dados poderão ainda ser utilizados para:
							</p>
							<ol>
								<li>Proteção dos interesses vitais do cliente ou outra pessoa singular.</li>
								<li>Prossecução do interesse público.</li>
							</ol>
						<!-- Add more paragraphs as needed -->
						</section>

						<!-- Section 6: FINALIDADES PARA OS QUAIS SÃO USADOS OS SEUS DADOS PESSOAIS -->
						<section>
							<h5>6. FINALIDADES PARA OS QUAIS SÃO USADOS OS SEUS DADOS PESSOAIS</h5>
							<p>
								Existem várias situações onde a PRIO utiliza os dados pessoais, tais como: (lista não exaustiva)
							</p>
							<ul>
								<li>Celebração de contratos.</li>
								<li>Comunicar dados de acidentes à seguradora.</li>
								<li>Registo e utilização de WebSite.</li>
								<li>Registo e utilização de Aplicação Móvel.</li>
								<li>Tratamento de dados para efeitos de marketing.</li>
								<li>Tratamento de dados para efeitos de satisfação de clientes.</li>
								<li>Tratamento de reclamações.</li>
								<li>Tratamento de erros na utilização do website.</li>
							</ul>
							<p>
								No caso de o cliente não fornecer a informação necessária, a PRIO não poderá executar as suas obrigações perante o cliente, tais como a prestação de um serviço ou conceder certo benefício devido. Da mesma forma podem ficar comprometidas o cumprimento de outras obrigações com fonte legal.
								A PRIO manterá os dados dos seus clientes ou potenciais clientes enquanto as finalidades para os quais foram tratados se mantiverem válidas ou seja exercido o direito de oposição.
							</p>
						<!-- Add more paragraphs as needed -->
						</section>

						<!-- Continue with other sections -->

						<!-- Section 7: UTILIZAÇÃO DOS DADOS PARA OUTRAS FINALIDADES -->
						<section>
							<h5>7. UTILIZAÇÃO DOS DADOS PARA OUTRAS FINALIDADES</h5>
							<p>
								Os dados apenas serão utilizados para as finalidades que permitiram a sua recolha. Contudo, em casos pontuais, poderão ser utilizados para outros fins, desde que essa finalidade ainda se enquadre nos limites do tratamento inicial e estejam garantidas as condições de segurança necessárias. Sempre que tal circunstância se verifique a empresa notificará o cliente expondo todos os motivos e base legal para o novo tratamento.
							</p>
						<!-- Add more paragraphs as needed -->
						</section>

						<!-- Section 8: UTILIZAÇÃO DE DADOS SENSÍVEIS -->
						<section>
							<h5>8. UTILIZAÇÃO DE DADOS SENSÍVEIS</h5>
							<p>
								São considerados dados sensíveis dados pessoais que revelem a origem racial ou étnica, as opiniões políticas, as convicções religiosas ou filosóficas, ou a filiação sindical, bem como o tratamento de dados genéticos, dados biométricos para identificar uma pessoa de forma inequívoca, dados relativos à saúde ou dados relativos à vida sexual ou orientação sexual de uma pessoa.
							</p>
							<p>
								Estes tipos de dados só podem ser tratados em casos específicos previstos na lei, entre eles:
							</p>
							<ul>
								<li>Se o titular dos dados tiver dado o seu consentimento explícito para o tratamento.</li>
								<li>Se o tratamento for necessário para efeitos do cumprimento de obrigações e do exercício de direitos específicos do responsável pelo tratamento ou do titular dos dados em matéria de legislação laboral, de segurança social e de proteção social.</li>
								<li>Se o tratamento for necessário para proteger os interesses vitais do titular dos dados ou de outra pessoa singular, no caso de o titular dos dados estar física ou legalmente incapacitado de dar o seu consentimento.</li>
								<li>Se o tratamento se referir a dados pessoais que tenham sido manifestamente tornados públicos pelo seu titular.</li>
								<li>Se o tratamento for necessário à declaração, ao exercício ou à defesa de um direito num processo judicial ou sempre que os tribunais atuem no exercício da sua função jurisdicional.</li>
							</ul>
							<p>
								No caso da relação entre a PRIO e o cliente, não está previsto o tratamento de dados sensíveis.
							</p>
						<!-- Add more paragraphs as needed -->
						</section>

						<!-- Continue with other sections -->

						<!-- Section 9: OS SEUS DIREITOS E DEVERES COMO CLIENTE -->
						<section>
							<h5>9. OS SEUS DIREITOS E DEVERES COMO CLIENTE</h5>

							<!-- Subsection 9.1 -->
							<h6>9.1. Dever de manter o responsável pelo tratamento informado de alterações</h6>
							<p>
								É muito importante que a informação pessoal que a PRIO retenha sobre os clientes esteja correta e atualizada. Por favor, mantenha a PRIO informada das alterações que existam na sua informação pessoal durante a sua relação contratual ou de prestação de serviços, com a PRIO.
							</p>

							<!-- Subsection 9.2 -->
							<h6>9.2. Direito de Acesso</h6>
							<p>
								Tem o direito de aceder à sua informação pessoal. Isto permite que receba uma cópia de toda a informação pessoal que a PRIO guarda sobre si e de verificar se a mesma é tratada de forma lícita.
							</p>

							<!-- Subsection 9.3 -->
							<h6>9.3. Direito de Correção</h6>
							<p>
								Tem o direito à correção da informação pessoal que a PRIO guarda sobre si. Isto permite que possa corrigir qualquer informação incorreta ou incompleta.
							</p>

							<!-- Subsection 9.4 -->
							<h6>9.4. Direito ao Apagamento</h6>
							<p>
								Tem o direito ao apagamento dos seus dados pessoais. Isto permite que possa pedir para excluir ou remover a sua informação pessoal caso não haja uma razão aceitável para que continue a ser processada. Também tem o direito de pedir para excluir ou remover as suas informações pessoais quando tenha exercido o seu direito de oposição ao processamento.
							</p>

							<!-- Subsection 9.5 -->
							<h6>9.5. Direito de oposição ao processamento</h6>
							<p>
								Tem o direito a opor-se ao processamento dos seus dados pessoais quando os mesmos forem processados com fundamento no interesse legítimo. Caso exista uma situação específica sobre si também se pode opor ao processamento.
							</p>

							<!-- Subsection 9.6 -->
							<h6>9.6. Direito de requerer a restrição de processamento</h6>
							<p>
								Tem o direito de requerer a suspensão do tratamento dos seus dados pessoais, por exemplo, se quiser que seja verificada a sua precisão ou fundamento para processamento.
							</p>

							<!-- Subsection 9.7 -->
							<h6>9.7. Direito de transferência</h6>
							<p>
								Tem o direito de requerer a transferência da sua informação pessoal para outra parte.
							</p>

							<!-- Subsection 9.8 -->
							<h6>9.8. Direito à retirada do consentimento</h6>
							<p>
								Todos os titulares de dados têm direito a presentar reclamação a uma autoridade de controlo se considerar que o tratamento de dados pessoais que lhe diga respeito viola o regulamento geral de proteção de dados. Todavia poderá o titular dos dados em primeira instância contatar o encarregado de proteção de dados da PRIO para fazer exercer os seus direitos.
							</p>
						</section>

						<section>
							<h5>10. TRANSFERÊNCIA DE DADOS</h5>
							<p>
								A transferência de dados pessoais de clientes para subcontratantes da PRIO só ocorrerá nos casos de cedência ocasional de dados e na medida que seja proporcional, necessária e adequada aos objetivos a atingir.
								Apenas serão selecionados subcontratantes que apresentem garantias suficientes de execução de medidas adequadas a cumprir as exigências impostas pelo RGPD. Estes prestadores de serviços não estão autorizados a utilizar os dados dos clientes da PRIO para fins próprios, apenas realizam os tratamentos expressamente identificados pela PRIO e nos termos estabelecidos por este.
								Todos os funcionários do prestador de serviço estão vinculados por um dever de confidencialidade.
							</p>
						</section>

						<section>
							<h5>11. MEDIDAS DE SEGURANÇA DOS DADOS PESSOAIS</h5>
							<p>
								A fim de garantir o devido nível de segurança dos dados pessoais dos seus clientes, a PRIO encontra-se numa fase de implementação de medidas que vão de encontro às disposições previstas pelo RGPD e pela ISO27001.
								Tal medida tem como objetivo evitar a perda acidental de dados, o uso ou acesso aos dados por elementos não autorizados, a sua alteração ou a sua divulgação.
								Para tal o acesso aos dados é limitado aos colaboradores e prestadores de serviço cujas funções implicam necessariamente o acesso aos dados. O tratamento por estes será feito nos termos estipulados pela PRIO e estão sujeitos a deveres de confidencialidade.
								A PRIO desenvolve igualmente procedimentos para resolver qualquer suspeita de quebra de segurança e irá notificar os seus clientes no caso de qualquer fuga, nos termos imposto pelo RGPD.
							</p>
						</section>

						<section>
							<h5>12. TAXAS PARA O EXERCÍCIO DO SEU DIREITO</h5>
							<p>
								Não vai ter de pagar qualquer taxa para aceder à sua informação pessoal (ou para exercer qualquer outro direito), contudo, a PRIO poderá cobrar uma taxa mínima se os seus pedidos forem, claramente, infundados ou excessivos.
								Em alternativa, a PRIO pode, nessas circunstâncias, recusar cumprir com o pedido.
							</p>
						</section>
						<section>
							<h5>13. O QUE PODEMOS PRECISAR DO CLIENTE</h5>
							<p>
								A PRIO poderá requerer informação específica sobre o cliente, de forma a confirmar a sua identidade e garantir o seu direito de acesso aos dados pessoais. Esta é uma medida de segurança para garantir que a sua informação pessoal não é divulgada a nenhuma pessoa que não tenha autorização para aceder à mesma
							</p>
						</section>
						<section>
							<h5>14. MUDANÇA NA POLÍTICA DE PRIVACIDADE</h5>
							<p>
								A PRIO reserva o direito de atualizar esta Política de Privacidade a qualquer altura. Todos os clientes serão informados de futuras mudanças substanciais.
							</p>
						</section>
						<section>
							<h5>15. ENCARREGADO DA PROTEÇÃO DE DADOS</h5>
							<p>
								António Veiga<br>
								Encarregado de Proteção de Dados
								<br>
								<br>

								Telefone: +351 234 390 010<br>
								E-mail: dpo@prioenergy.com<br>
								Morada: TGL – Terminal de Granéis Líquidos<br>
								Porto de Aveiro – Lote B<br>
								3834 – 908 Gafanha da <br>
								Ílhavo, Portugal <br>
							</p>
						</section>
					</div>
				<!--            <div class="col-12 d-flex justify-content-center" >
                    <div class="col-auto d-flex text-center justify-content-center" style="width:200px; border-bottom: 1px solid black;padding-bottom: 30px">
                        Assinatura
                    </div>
            </div>-->
				</div>
			</div>
		</div>
	</fake-modal-layout>
</template>

<script>
import FakeModalLayout from "../../Shared/FakeModalLayout.vue";
import { Head as MetaHead } from "@inertiajs/vue3";

export default {
    name: "PrivacyPolicy",
    components: {
        FakeModalLayout,
        MetaHead
    },
    methods: {
        printPage() {
            window.print();
        },
    }
}
</script>
