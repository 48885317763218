<template>
	<PageTitle title="Participações" />

	<DefaultDataTable
		ref="table"
		:columns="columns"
		:url="route('admin.participation.search')"
		:filters="filters"
		:length-prop="25"
	>
		<template #button>
			<div class="d-flex" />
		</template>
	</DefaultDataTable>
</template>
<!--		-->
<script>
import Layout from "../../Shared/Layout.vue";
import {DateTime} from "luxon";

export default {
    layout: Layout,
    props: {
        statuses: {
            type: Array,
            default: () => []
        },
        sorteios: {
            type: Object,
            default: () => []
        },
        filters: {
            type: Object,
            default: () => ({})
        }

    },
    mounted(){
        this.filters[0].options = this.statuses;
        this.filters[1].options = this.sorteios;
    },
    data() {
        return {
            columns: [
                { data: 'ID', dataTitle: 'ID', name: 'id', searchable: true, orderable: true },
                { data: 'Participação/Factura', dataTitle: 'Participação/Factura', name: 'codes', searchable: false, orderable: false, html:true, link:'main_link'},
                { data: 'Nome', dataTitle: 'Nome', name: 'contestent.name', searchable: true, orderable: true},
                { data: 'Participação', dataTitle: 'Participação', name: 'code', searchable: true, orderable: true, hidden:true },
                { data: 'Factura', dataTitle: 'Factura', name: 'invoice_number', searchable: true, orderable: true, hidden:true },
                { data: 'Data do Registo', dataTitle: 'Data do Registo', name: 'created_at', searchable: true, orderable: true, render: (data) => {
                        return data ? DateTime.fromISO(data).toFormat('d/MM/y HH:mm') : '';
                    }
                },
                { data: 'Sorteio', dataTitle: 'Sorteio', name: 'contest_name', searchable: false, orderable: false, },
                { data: 'Estado da validação', dataTitle: 'Estado da validação', name: 'contesttstaus', searchable: false, orderable: false},
                { data: 'Validação', dataTitle: 'Validação', name: 'validation', searchable: false, orderable: false},
                { data: 'Data da notificação', dataTitle: 'Data da notificação', name: 'notifieddate', searchable: false, orderable: false},
                { data: 'Data de envio prémio', dataTitle: 'Data de envio prémio', name: 'cttdate', searchable: true, orderable: true},
                { data: 'Código Correios', dataTitle: 'Código Correios', name: 'cttcode', searchable: true, orderable: true},

            ],
            filters: [
                {
                    label: 'Estados',
                    name: 'tags',
                    options: [],
                    internalSearch: false,
                    isMultiple: true,
                    wrapperClass: 'col-12 col-md-3 col-lg-4',
                },
                {
                    label: 'Sorteios',
                    name: 'sorteio',
                    track: 'id',
                    multiselectLabel: 'name',
                    options: [],
                    internalSearch: false,
                    isMultiple: false,
                    /*dynamicParams: {
                        name: 'name',
                        value: 'id'
                    },*/
                    wrapperClass: 'col-12 col-md-3 col-lg-4',
                }
            ]

        }
    },
    computed: {


    },
    methods: {

    },

}
</script>
<script setup>
import DefaultDataTable from "../../Components/DefaultDataTable.vue";
import PageTitle from "../../Components/etc/PageTitle.vue";
import {DateTime} from "luxon";



//define layout
</script>

<style scoped>

</style>
