<template>
	<div class="container">
		<div class="row" />
		<div class="col-12  p-1 py-md-4 px-md-4 ">
			<div class="row">
				<div class="col-12 pt-4">
					<h4 class="fw-bold text-center">
						Upsss!!! Assim não vamos muito longe.
					</h4>
					<h5 class="fw-bold text-center colorFontPrimary pt-2">
						Essa página não existe
					</h5>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BlankLayout from "../Shared/BlankLayout.vue";
export default {
    name: "404",

    components: {

    },
    layout: BlankLayout,
    props: {

    },
    methods: {
        printPage() {
            window.print();
        },
        // ... (other methods)
    },
}
</script>
<script setup>


//define layout
</script>
<style>


</style>
