<template>
	<fake-modal-layout>
		<meta-head title="Declaração de aceitação" />
		<template #link>
			<a href="https://ganhaecodiesel.pt" class="fw-bold">
				<i class="mdi mdi-chevron-left pr-1" />
				Voltar a ganhaecodiesel.pt
			</a>
		</template>
		<div class="container">
			<div class="row">
				<div
					class=" col-12  d-flex pb-2  justify-content-end"
				>
					<div class="select-card-code-info-value fw-bold" @click="printPage">
						<i class="mdi mdi-printer-outline font-24" /> Imprimir
					</div>
				</div>
			</div>
			<div class="col-12  p-1 py-md-4 px-md-4 ">
				<div class="row">
					<div class="col-12 pt-4">
						<h4 class="fw-bold text-center">
							Declaração de aceitação
						</h4>
						<p>
							Declaro aceitar o prémio referente ao concurso “Volta ao mundo em ECO Diesel”, nº 308/2023,
							autorizado pela Câmara Municipal de Ílhavo, o qual é entregue pela PRIO Energy S.A, com sede
							no TGL – Terminal de Granéis Líquidos, Porto de Aveiro – Lote B, 3834 – 908 Gafanha da
							Nazaré. O mesmo será enviado via portal para o endereço que indiquei.
						</p>
					</div>
				</div>
			</div>
		</div>
	</fake-modal-layout>
</template>

<script>
import FakeModalLayout from "../../Shared/FakeModalLayout.vue";
import {Head as MetaHead} from "@inertiajs/vue3";

export default {
    name: "AcceptanceDeclaration",

    components: {
        FakeModalLayout,
        MetaHead
    },
    methods: {
        printPage() {
            window.print();
        },
    },
}
</script>
<script setup>


//define layout
</script>
<style>


</style>
