<template>
	<MetaHead title="Minha conta" />

	<div class="col-12   py-md-4 px-md-4 ">
		<div class="row d-flex justify-content-center">
			<div class="col-12  text-center">
				<p class="fw-bold">
					Ajuda-nos a conhecer-te melhor. <br>
					Indica o teu nome e o teu número de telemóvel para seres o primeiro a receber as boas notícias.
				</p>
			</div>
			<div class="col-12 col-lg-6 ">
				<div
					class="col-12 default-box-prio-primary default-box-prio-radius pb-4 px-4 pt-2"
					:class="statusClass"
				>
					<form @submit.prevent="submit">
						<div class="col-12 pt-3 ">
							<div class="">
								<login-input
									v-model="accountForm.name"
									label="Nome"
									name="name"
									type="text"
									placeholder=""
									:required="true"
									:error="errors.name"
									class="mt-4"
								/>
							</div>
							<span v-if="modifiedErrors && modifiedErrors.name" class="text-danger d-block pt-2"> <i
								class="mdi mdi-alert-circle"
							/> {{ modifiedErrors.name }} </span>
						</div>
						<div class="col-12 pt-3 ">
							<!--                @on-change="handleOnChange"
                                @on-complete="handleOnComplete"-->
							<div class="">
								<login-input
									v-model="accountForm.phone"
									label="Telemóvel"
									name="phone"
									type="number"
									placeholder=""
									:opcional="true"
									:error="errors.phone"
									class="mt-4"
								/>
							</div>
							<span v-if="modifiedErrors && modifiedErrors.phone" class="text-danger d-block pt-2"> <i
								class="mdi mdi-alert-circle"
							/> {{ modifiedErrors.phone }} </span>
						</div>
						<div class="col-12 pt-3 ">
							<span
								v-if="modifiedErrors && modifiedErrors.invoice_number"
								class="text-danger d-block pt-2"
							> <i
								class="mdi mdi-alert-circle"
							/> {{ modifiedErrors.invoice_number }}
							</span>
							<div class="col-12 pt-3">
								<div class="form-check">
									<input
										id="flexCheckPriocommsDefault"
										v-model="accountForm.priocomms"
										class="form-check-input"
										type="checkbox"
										:value="accountForm.priocomms"
									>
									<label class="form-check-label" for="flexCheckPriocommsDefault">
										Aceito receber notificações desta campanha por SMS
									</label>
								</div>
							</div>
							<div class="col-12 pt-3">
								<div class="form-check">
									<input
										id="flexCheckEliminateAccount"
										v-model="accountForm.eliminate"
										class="form-check-input"
										type="checkbox"
										:value="accountForm.eliminate"
									>
									<label class="form-check-label" for="flexCheckEliminateAccount">
										Desejo eliminar a minha conta
									</label>
								</div>
							</div>
							<div v-if="accountForm.eliminate" class="col-12 pt-3">
								<p>
									Tem a certeza que deseja eliminar a conta? <br>
									<span class="fw-bold">Eliminar a conta é uma decisão irreversível e implica a perda de todos os dados associados à mesma.</span>
									<br><br>
									Por favor confirme a sua decisão, escrevendo o sequinte código no campo abaixo: <br>
									<span class="fw-bold">IDF5D98F5</span>
								</p>
								<div class="">
									<login-input
										v-model="accountForm.eliminateText"
										label="Campo de confirmação"
										name="eliminateText"
										type="text"
										placeholder=""
										opcional="false"
										class="mt-4"
										@change="confirmElimination"
									/>
								</div>
							</div>
						</div>

						<div class="col-12 pt-3 mb-3 mt-3">
							<submit-button
								ref="submitButton"
								class="btn btn-primary w-100 fw-bold halfrounded-corners-left"
								label="Gravar"
							/>
						</div>
						<template v-if="turnstileEnabled">
							<turnstile
								v-model="accountForm.cfResponse"
								:site-key="siteKey"
								appearance="interaction-only"
								:placeholder="false"
							/>
						</template>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContestentsLayout from "../../Shared/ContestentsLayout.vue";
import LoginInput from "../../Components/LoginInput.vue";
import SubmitButton from "../../Components/SubmitButton.vue";
import Turnstile from "@any-design/turnstile";
import {Head as MetaHead, useForm} from "@inertiajs/vue3";

const SITE_KEY = import.meta.env.VITE_TURNSTILE_SITE_KEY;
const TURNSTILE_ENABLED = import.meta.env.VITE_TURNSTILE_ENABLED === 'true';

export default {
    name: "AccountForm",

    components: {
        'login-input': LoginInput,
        'submit-button': SubmitButton,
        Turnstile,
        MetaHead,
    },
    layout: ContestentsLayout,

    props: {
        errors: {
            type: Object,
            default: () => ({}),
            required: false,
        },
    },
    data() {
        const _this = this;
        return {
            statusClass: 'default',
            modifiedErrors: {},
            accountForm: useForm(Object.assign({}, _this.$page.props.auth, {
                password_confirmed: null,
                priocomms: false,
                eliminate: false,
                confirmeliminate: false,
                eliminateText: '',
                cfResponse: null,
            })),
            siteKey: SITE_KEY,
            turnstileEnabled: TURNSTILE_ENABLED,
        }
    },

    mounted() {
        if (this.$page.props.auth.priocomms === 1) {
            this.accountForm.priocomms = true;
        } else {
            this.accountForm.priocomms = false;
        }

        // Find all input elements with a specific class (e.g., "my-input-class")
        const inputElements = document.querySelectorAll('.otp-input-wrapper .otp-input');

        // Iterate through the found input elements
        inputElements.forEach((inputElement) => {
            // Remove the specified attributes from each input element
            inputElement.removeAttribute('min');
            inputElement.removeAttribute('max');
            inputElement.removeAttribute('maxlength');
            inputElement.removeAttribute('pattern');
        });
        console.log(this.accountForm, this.$page.props.auth.priocomms);
    },

    methods: {
        confirmElimination() {
            if (this.accountForm.eliminateText === 'IDF5D98F5') {
                this.accountForm.confirmeliminate = true;
            } else {
                this.accountForm.confirmeliminate = false;
            }
        },
        submit() {
            this.accountForm.post(route('contestent.perfil.update'), {
                preserveState: true,
                onSuccess: () => {
                    //window.location.reload()
                    this.$refs.submitButton?.toggleLoading(false)
                    this.statusClass = 'default'
                    console.log('success');
                },
                onError: (e) => {
                    this.pErrors = e
                    this.$refs.submitButton?.toggleLoading(false)
                    this.statusClass = 'invalid'
                    this.modifiedErrors = this.pErrors
                    console.log('no');
                },
                onFinish: () => {
                    this.$refs.submitButton?.toggleLoading(false)
                    console.log('finish');
                }
            })
        },
    }
}
</script>
<style>

.otp-input-wrapper {
    text-align: center;
    font-weight: 600;
}

.otp-input-wrapper-code .v-otp-input-group {
    width: 250px;
}

.otp-input {
    width: 16px !important;
    height: 50px;
    padding: 2px;
    margin: 0 0px;
    font-size: 20px;
    border-radius: 0px;
    border: 0px solid rgba(0, 0, 0, 0.1);
    text-align: center;
}

.v-otp-input-group-separator {
    width: 12px !important;
    height: 50px;
    font-size: 30px;
    padding-left: 6px !important;
    padding-right: 13px !important;
}

.otp-input:focus {
    border: 0px solid rgba(0, 0, 0, 0.1);
    outline: none;
    box-shadow: none;

}

.v-otp-input-group {
    /*border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    background-color: #ffffff;*/
}

/* Background colour of an input field with value */
.otp-input.is-complete {
    background-color: rgba(13, 17, 22, 0);
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.otp-input-wrapper > div {
    justify-content: space-evenly;
}

input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
}
</style>
