<template>
	<PageTitle title="Criar Utilizador" />

	<Form
		:default-form="defaultForm"
		:submit-route="route('users.store')"
		:errors="errors"
		type="create"
	/>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import Form from "./Form.vue"
import PageTitle from "../../Components/etc/PageTitle.vue";
export default {
    name: "Create",
    components: {
        PageTitle,
        Form,
    },
    layout: Layout,
    props: {
        defaultForm: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
}
</script>

<style scoped>

</style>
