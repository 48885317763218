<template>
	<div v-if="show || modelValue">
		<transition name="modal">
			<div class="modal-mask" tabindex="-1">
				<div class="row justify-content-center align-items-center">
					<div :class="`col-lg-${size}`">
						<div class="col-12 py-4 px-2 br-5 bg-white">
							<div class="modal-header pb-4 px-3">
								<div>
									<h5 class="modal-title" style="font-size: 18px; font-weight: 700;">
										{{ title }}
									</h5>
									<span v-if="subtitle" class="d-block font-12"> {{ subtitle }} </span>
								</div>

								<a
									class="cursor-pointer"
									style="font-size: 20px;"
									@click="close"
								>
									<i class="mdi mdi-close" />
								</a>
							</div>

							<div class="modal-body px-3" style="max-height: 60vh; overflow-y: auto;">
								<slot />
							</div>

							<div v-if="showCloseButton || withSave" :class="`modal-footer px-3 pt-4 justify-content-${footerButtonsJustify}`">
								<a
									v-if="showCloseButton"
									class="btn btn-secondary"
									@click="close"
								> Fechar </a>
								<a
									v-if="withSave"
									class="btn btn-primary"
									:class="{'ms-3': showCloseButton}"
									@click="save"
								> {{ saveLabel ?? 'Guardar' }} </a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
    name: "Modal",
    props:{
        title:{
            type: String,
            required: false,
            default: '',
        },

        subtitle:{
            type: String,
            required: false,
            default: undefined,
        },

        show: undefined,

        modelValue: undefined,

        withSave:{
            type: Boolean,
            required: false,
            default: true,
        },

        saveLabel: {
            type: String,
            required: false,
            default: undefined
        },

        footerButtonsJustify:{
            type: String,
            required: false,
            default: 'end',
        },

        showCloseButton: {
            type: Boolean,
            required: false,
            default: true,
        },

        //size in columns
        size: {
            type: Number,
            required: false,
            default: 5,
        },
    },
    emits: ['save', 'update:show', 'update:modelValue', 'close'],

    methods:{
        save(){
            this.$emit('save');
        },

        close(){
            this.$emit('update:show', false);
            this.$emit('update:modelValue', 0);
            this.$emit('close'); //above wasn't working soo...
            // In case you come across this, the above wasn't working because the prop wasn't being called with the v-model before it
            //The emits array is mostly for optimisation and to help the IDE
        },
    }
}
</script>

<style scoped>

</style>
