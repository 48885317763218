<template>
    <PageTitle title="Serviços"/>

    <p>
        Nesta página podem ser definidos as séries e armazéns para cada serviço<br/>
        Na primeira coluna da tabela podem ser vistos todos os serviços do Mews<br/>
        Nas restantes colunas podem ser definidos as séries e armazéns para cada serviço
    </p>

	<div>
		<table v-if="form" class="table table-vertical-center">
			<thead>
				<tr>
					<th>Serviço</th>
					<th>Serie</th>
					<th>Armazem</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(service) in services">
					<td>
						<span>{{ service.name }}</span>
					</td>
					<td>
						<multiselect
							v-model="form[service.id].document_set"
							:options="document_sets"
							track-by="document_set_id"
							label="name"
							@select="handleSelect(service.id)"
						/>
					</td>
					<td>
						<multiselect
							v-model="form[service.id].warehouse"
							:options="warehouses"
							track-by="warehouse_id"
							label="title"
							@select="handleSelect(service.id)"
						/>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script lang="ts">
import Layout from "../Shared/Layout.vue";

export default {
    layout: Layout,
}
</script>
<script setup lang="ts">
import {defineComponent, ref} from "vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import PageTitle from "../Components/etc/PageTitle.vue";

// define multiselect as component
defineComponent({
    components: {
        Multiselect,
    },
})

const services = ref({})
const document_sets = ref({})
const warehouses = ref({})
const form = ref([])

const getData = async () => {
    axios.get(route('api.services.index'))
        .then((response) => {
            services.value = response.data['services']
            document_sets.value = response.data['document_sets']
            warehouses.value = response.data['warehouses']

            //Initialize form with empty values
            //Unless we have a match in the database
            response.data['services'].forEach((service) => {
                response.data['matches'].forEach((match) => {
                    if(service.id === match.id){
                        form.value[match.id] = {
                            document_set: findDocumentSet(match.document_set_id),
                            warehouse: findWarehouse(match.warehouse_id),
                        }
                    }
                })
                if (!form.value[service.id]){
                    form.value[service.id] = {
                        document_set: null,
                        warehouse: null,
                    }
                }
            })
        })
}
const findDocumentSet = (id) => {
    return document_sets.value.find((documentSet) => {
        return documentSet.document_set_id === id
    })
}
const findWarehouse = (id) => {
    return warehouses.value.find((warehouse) => {
        return warehouse.warehouse_id === id
    })
}
const findService = (id) => {
    return services.value.find((service) => {
        return service.id === id
    })
}
const handleSelect = (index) => {
    console.log(form.value, index)
    if(form.value[index].document_set && form.value[index].warehouse){
        axios.post(route('services.save', findService(index).id), {
            document_set_id: form.value[index].document_set.document_set_id,
            warehouse_id: form.value[index].warehouse.warehouse_id,
        })
    }
}


getData()
</script>

<style scoped>

</style>
