<template>
	<MetaHead title="Códigos sorteados" />
	<div class="col-12  p-1 py-md-4 px-md-4 ">
		<div class="row">
			<div class="col-12  text-center">
				<p class="fw-bold colorFontPrimary">
					Códigos sorteados
				</p>
				<div class="text-center " style="font-size: 14px; font-weight:300;">
					Verifica se o teu código foi um dos sorteados esta semana
				</div>
				<div class="col-12 d-flex justify-content-center ">
					<div class="d-flex col-12 col-sm-4 col-md-3 weird-input">
						<login-input
							v-model="code"
							name="code"
							type="text"
							placeholder="Qual é o teu código?"
							class="col-12 mt-3"
						/>
					</div>
				</div>
				<div class="col-12 d-flex pt-4 justify-content-center">
					<table border="0" class="col-auto def-table">
						<thead>
							<tr>
								<th>Sorteio</th>
								<th>Data</th>
								<th>Código</th>
							</tr>
						</thead>
						<tbody>
							<!-- Add your table rows and data here -->
							<template v-for="participation in winnerParticipations">
								<tr
									v-for="estado in participation.participation_status"
									:key="estado.id"
									style="margin-bottom: 1px;"
								>
									<td style="border:1px solid #000">
										{{ estado.contest.name }}
									</td>
									<td style="border:1px solid #000">
										<template v-if="estado.contest.rule === 'Diário'">
											{{ printDate(estado.contest.start_date) }}
										</template>
										<template v-if="estado.contest.rule !== 'Diário'">
											{{ printDate(estado.contest.start_date) }} -
											{{ printDate(estado.contest.end_date) }}
										</template>
									</td>
									<td style="border:1px solid #000">
										{{ participation.code }}
									</td>
								</tr>
							</template>
							<template v-if="winnerParticipations.length === 0">
								<tr>
									<td colspan="3" class="text-center">
										Não existem códigos sorteados
									</td>
								</tr>
							</template>


							<!-- Add more rows as needed -->
						</tbody>
					</table>
				</div>
				<div class="col-12 d-flex pt-4 justify-content-center">
					<nav aria-label="Page navigation">
						<ul class="pagination">
							<li class="page-item" :class="{ disabled: currentPage === 1 }">
								<a
									class="page-link"
									aria-label="Previous"
									@click="changePage(currentPage - 1)"
								>
									<span aria-hidden="true">&laquo;</span>
								</a>
							</li>
							<li
								v-for="page in total_pages"
								:key="page"
								class="page-item"
								:class="{ active: currentPage === page }"
							>
								<a class="page-link" @click="changePage(page)">{{ page }}</a>
							</li>
							<li class="page-item" :class="{ disabled: currentPage === total_pages }">
								<a
									class="page-link"
									aria-label="Next"
									@click="changePage(currentPage + 1)"
								>
									<span aria-hidden="true">&raquo;</span>
								</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContestentsLayout from "../../Shared/ContestentsLayout.vue";
import axios from "axios";
import LoginInput from "../../Components/LoginInput.vue";
import {Head} from "@inertiajs/vue3";

export default {
    name: "CodigosSorteados",
    components: {
        'login-input': LoginInput,
        MetaHead: Head,
    },

    layout: ContestentsLayout,
    props: {
        errors: Object,
        user: Object,
    },
    data() {
        return {
            sorteios: [],
            code: null,
            winnerParticipations: [],
            currentPage: 1,
            perPage: 5, // You can adjust this value as needed
            total_pages: 0,

        }
    },
    watch: {
        code: function (val) {
            this.getData();
        }
    },

    mounted() {
        this.getData();
    },

    methods: {
        getData() {
            console.debug('getData')
            let that = { code: this.code, page: this.currentPage, perPage: this.perPage };

            axios.get(route('codigosSorteadosSearch', that))
                .then((response) => {
                    this.sorteios = response.data;
                    this.total_pages = response.data.last_page;
                    this.hammerData();
                    console.log(this.winnerParticipations);
                });
        },
        formattedDate(date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};
            return new Date(date).toLocaleDateString(undefined, options);
        },
        printDate(date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric'};
            return new Date(date).toLocaleDateString(undefined, options);
        },
        hammerData() {
            this.winnerParticipations = this.sorteios.data;
            // this.winnerParticipations = this.sorteios.filter((participation) =>
            //     participation.participation_status.some(
            //         (status) => status.status_label_contestent === 'Premiado'
            //     )
            // );
        },
        changePage(page) {
            if (page >= 1 && page <= this.total_pages && page !== this.currentPage) {
                this.currentPage = page;
                this.getData();
            }
        },
    }
}
</script>
<script setup>


</script>
<style>


</style>
